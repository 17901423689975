import { notification } from "antd";
import axios from "axios";
import { useFormik } from "formik";
import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import * as Yup from "yup";
import ErrorMessage from "components/error-message/ErrorMessage";
import { ApiUrl } from "services/api";
import { SUCCESS_MESSAGE } from "utils/constants/contants";
import "./dashboard-profile.style.css";

function ChangePassword() {
  const { authState } = useSelector(
    (state) => ({ authState: state.auth }),
    shallowEqual
  );

  const ChangePasswordSchema = Yup.object().shape({
    currentPassword: Yup.string()
      .required("Current password is required")
      .min(6, "Current password must be greater than 6 characters"),
    newPassword: Yup.string()
      .required("New password is required")
      .min(6, "New password must be greater than 6 characters")
      .notOneOf(
        [Yup.ref("currentPassword"), null],
        "New Password cannot be the same as the Current Password"
      ),
    confirmNewPassword: Yup.string()
      .required("Confirm new password is required")
      .min(6, "Confirm new password must be greater than 6 characters")
      .oneOf(
        [Yup.ref("newPassword"), null],
        "New password and confirm password must match"
      ),
  });

  const SubmitHandler = async (values) => {
    try {
      const { currentPassword, newPassword } = values;
      const updatePasswordApi = ApiUrl.User.updatePassword;
      const response = await axios[updatePasswordApi.method](
        updatePasswordApi.url(),
        { currentPassword, newPassword },
        {
          headers: {
            authorization: `Bearer ${
              authState.firebaseToken || "Token not found changepassword"
            }`,
          },
        }
      );
      if (response.status === 200) {
        notification.success({
          description: SUCCESS_MESSAGE.UPDATE_PASSWORD_SUCCESS,
        });
      } else {
        // message.error(ERROR_MESSAGE.UPDATE_PASSWORD_ERROR);
      }
    } catch (error) {
      // message.error(error.message || ERROR_MESSAGE.UPDATE_PASSWORD_ERROR);
    }
  };

  //formik Handler
  const formik = useFormik({
    initialValues: {
      currentPassword: "",
      newPassword: "",
      confirmNewPassword: "",
    },
    validationSchema: ChangePasswordSchema,
    onSubmit: SubmitHandler,
  });

  return (
    <>
      <div
        // onClick={(event) => resetfieldError(event) }
        className="dashboard_profile_right_div_item_div"
        id="passwordSection"
      >
        <h5> Change your Password</h5>
        <hr />

        <form onSubmit={formik.handleSubmit}>
          {/******************  Current password **************/}
          <div className="d-flex dashboard_profile_right_div_input_box_div">
            <span> Current Password </span>
            <div className="w-100 pt-4" style={{ marginRight: "20px" }}>
              <input
                type="password"
                name="currentPassword"
                autoComplete="off"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.currentPassword}
                className={`form-control ${
                  formik.errors.currentPassword &&
                  formik.touched.currentPassword
                    ? "input_error"
                    : ""
                }`}
                placeholder="Enter current password"
              />
              {formik.errors.currentPassword &&
                formik.touched.currentPassword && (
                  <ErrorMessage message={formik.errors.currentPassword} />
                )}
            </div>
          </div>
          {/******************  New password **************/}
          <div className="d-flex dashboard_profile_right_div_input_box_div">
            <span> New Password </span>
            <div className="w-100 pt-4" style={{ marginRight: "20px" }}>
              <input
                type="password"
                name="newPassword"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.newPassword}
                autoComplete="off"
                className={`form-control ${
                  formik.errors.newPassword && formik.touched.newPassword
                    ? "input_error"
                    : ""
                }`}
                placeholder="Enter new password"
              />
              {formik.errors.newPassword && formik.touched.newPassword && (
                <ErrorMessage message={formik.errors.newPassword} />
              )}
            </div>
          </div>
          {/******************  Confirm New password **************/}
          <div className="d-flex dashboard_profile_right_div_input_box_div">
            <span> Confirm New Password </span>
            <div className="w-100 pt-4" style={{ marginRight: "20px" }}>
              <input
                type="password"
                name="confirmNewPassword"
                autoComplete="off"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.confirmNewPassword}
                className={`form-control ${
                  formik.errors.confirmNewPassword &&
                  formik.touched.confirmNewPassword
                    ? "input_error"
                    : ""
                }`}
                placeholder="Confirm your new password"
              />
              {formik.errors.confirmNewPassword &&
                formik.touched.confirmNewPassword && (
                  <ErrorMessage message={formik.errors.confirmNewPassword} />
                )}
            </div>
          </div>

          <div className="dashboard_profile_right_div_item_btn_div">
            <button type="submit" className="btn btn-theme">
              Save Changes{" "}
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

export default ChangePassword;
