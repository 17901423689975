import { Modal, Upload } from "antd";
import ServiceLanguageDrawer from "app/dashboard/common/service-language/service-language.drawer";
import SpecialityDrawer from "app/dashboard/speciality/speciality.drawer";
import { UploadButton } from "components/button/Buttons";
import ErrorMessage from "components/error-message/ErrorMessage";
import RichEditor from "components/rich-text-Editor/RichEditor";
import { useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import Select from "react-select";
import { createQueryParams, getBase64 } from "utils/helpers/helper-functions";
import { LanguageSegment } from "components/language-segment/LanguageSegment";

function BasicInfo({ formik, view }) {
  const { Segment, language } = LanguageSegment();

  const { country, city, serviceLanguage, speciality } = useSelector(
    (state) => ({
      country: state.country?.records,
      city: state.city?.records,
      serviceLanguage: state.serviceLanguage?.records,
      speciality: state.speciality?.records,
    }),
    shallowEqual
  );

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [serviceLanguageDrawer, setServiceLanguageDrawer] = useState(false);
  const [specialityDrawer, setSpecialityDrawer] = useState(false);

  const getPagination = () => {
    const limit = 100000;
    let offset = 0;
    const text = "";
    const relations = [];
    const query = createQueryParams(limit, offset, text, relations);
    return { limit, offset, text, relations, query };
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const serviceLanguages = serviceLanguage?.map(
    ({ id: value, name_en }, index) => ({
      value,
      index,
      label: name_en,
    })
  );

  const specialitiess = speciality?.map(({ id: value, name_en }, index) => ({
    value,
    label: name_en,
    index,
  }));

  return (
    <>
      <ServiceLanguageDrawer
        setDrawer={setServiceLanguageDrawer}
        drawer={serviceLanguageDrawer}
        getPagination={getPagination}
      />

      <SpecialityDrawer
        setDrawer={setSpecialityDrawer}
        drawer={specialityDrawer}
        getPagination={getPagination}
      />

      <div className="language-segmented drawer_area_main_div mx-3 px-4 my-2">
        <div className=" my-auto mx-2">
  
        </div>
        {Segment}
      </div>

      <form onSubmit={formik.handleSubmit}>
        <div className="content container-fluid mt-2 mx-0 px-3">
          <div className="row">
            <div className="col-lg-7">
              {/****** Basic Info *****/}
              <div className="card mb-3 mb-lg-5">
                <div className="card-header card-header-content-between">
                  <h5>Basic Info</h5>
                </div>
                <div className="card-body">
                  {/************************/}
                  {language === "en" && (
                    <>
                      {" "}
                      <span className="drawer_span">
                        First Name
                        <span className="form-label-secondary"> (English)</span>
                      </span>
                      <div>
                        <input
                          disabled={view}
                          type="text"
                          placeholder=" First Name in (English)"
                          name="firstName_en"
                          className={`form-control drawer_input ${
                            formik.errors.firstName_en &&
                            formik.touched.firstName_en
                              ? "input_error"
                              : ""
                          }`}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.firstName_en}
                        />
                        {formik.errors.firstName_en &&
                          formik.touched.firstName_en && (
                            <ErrorMessage
                              message={formik.errors.firstName_en}
                            />
                          )}
                      </div>{" "}
                    </>
                  )}
                  {language === "ar" && (
                    <>
                      {" "}
                      <span className="drawer_span">
                        First Name
                        <span className="form-label-secondary"> (Arabic)</span>
                      </span>
                      <input
                        disabled={view}
                        type="text"
                        placeholder=" First Name in (Arabic)"
                        name="firstName_ar"
                        className={`my-2 form-control drawer_input ${
                          formik.errors.firstName_ar &&
                          formik.touched.firstName_ar
                            ? "input_error"
                            : ""
                        }`}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.firstName_ar}
                      />
                      {formik.errors.firstName_ar &&
                        formik.touched.firstName_ar && (
                          <ErrorMessage message={formik.errors.firstName_ar} />
                        )}{" "}
                    </>
                  )}
                  {language === "ru" && (
                    <>
                      {" "}
                      <span className="drawer_span">
                        First Name
                        <span className="form-label-secondary"> (Russian)</span>
                      </span>
                      <input
                        disabled={view}
                        type="text"
                        placeholder="First Name in (Russian)"
                        name="firstName_ru"
                        className={`my-1 form-control drawer_input ${
                          formik.errors.firstName_ru && formik.touched.name
                            ? "input_error"
                            : ""
                        }`}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.firstName_ru}
                      />
                      {formik.errors.firstName_ru &&
                        formik.touched.firstName_ru && (
                          <ErrorMessage message={formik.errors.firstName_ru} />
                        )}
                    </>
                  )}
                  {language === "de" && (
                    <>
                      {" "}
                      <span className="drawer_span">
                        First Name
                        <span className="form-label-secondary"> (German)</span>
                      </span>
                      <input
                        disabled={view}
                        type="text"
                        placeholder="First Name in (German)"
                        name="firstName_de"
                        className={`my-1 form-control drawer_input ${
                          formik.errors.firstName_de && formik.touched.name
                            ? "input_error"
                            : ""
                        }`}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.firstName_de}
                      />
                      {formik.errors.firstName_de &&
                        formik.touched.firstName_de && (
                          <ErrorMessage message={formik.errors.firstName_de} />
                        )}{" "}
                    </>
                  )}
                   {language === "uk" && (
                    <>
                      {" "}
                      <span className="drawer_span">
                        First Name
                        <span className="form-label-secondary"> (Ukrainian)</span>
                      </span>
                      <input
                        disabled={view}
                        type="text"
                        placeholder="First Name in (Ukrainian)"
                        name="firstName_uk"
                        className={`my-1 form-control drawer_input ${
                          formik.errors.firstName_uk && formik.touched.name
                            ? "input_error"
                            : ""
                        }`}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.firstName_uk}
                      />
                      {formik.errors.firstName_uk &&
                        formik.touched.firstName_uk && (
                          <ErrorMessage message={formik.errors.firstName_uk} />
                        )}{" "}
                    </>
                  )}
                  {/************************/}
                  {language === "en" && (
                    <>
                      {" "}
                      <span className="drawer_span">
                        Last Name
                        <span className="form-label-secondary"> (English)</span>
                      </span>
                      <div>
                        <input
                          disabled={view}
                          type="text"
                          placeholder="Last Name in English"
                          name="lastName_en"
                          className={`form-control drawer_input ${
                            formik.errors.lastName_en &&
                            formik.touched.lastName_en
                              ? "input_error"
                              : ""
                          }`}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.lastName_en}
                        />
                        {formik.errors.lastName_en &&
                          formik.touched.lastName_en && (
                            <ErrorMessage message={formik.errors.lastName_en} />
                          )}
                      </div>{" "}
                    </>
                  )}
                  {language === "ar" && (
                    <>
                      {" "}
                      <span className="drawer_span">
                        Last Name
                        <span className="form-label-secondary"> (Arabic)</span>
                      </span>
                      <input
                        disabled={view}
                        type="text"
                        placeholder="Last Name in Arabic"
                        name="lastName_ar"
                        className={`my-2 form-control drawer_input ${
                          formik.errors.lastName_ar &&
                          formik.touched.lastName_ar
                            ? "input_error"
                            : ""
                        }`}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.lastName_ar}
                      />
                      {formik.errors.lastName_ar &&
                        formik.touched.lastName_ar && (
                          <ErrorMessage message={formik.errors.lastName_ar} />
                        )}{" "}
                    </>
                  )}
                  {language === "ru" && (
                    <>
                      {" "}
                      <span className="drawer_span">
                        Last Name
                        <span className="form-label-secondary"> (Russian)</span>
                      </span>
                      <input
                        disabled={view}
                        type="text"
                        placeholder="Last Name in Russian"
                        name="lastName_ru"
                        className={`my-1 form-control drawer_input ${
                          formik.errors.lastName_ru && formik.touched.name
                            ? "input_error"
                            : ""
                        }`}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.lastName_ru}
                      />
                      {formik.errors.lastName_ru &&
                        formik.touched.lastName_ru && (
                          <ErrorMessage message={formik.errors.lastName_ru} />
                        )}
                    </>
                  )}
                  {language === "de" && (
                    <>
                      {" "}
                      <span className="drawer_span">
                        Last Name
                        <span className="form-label-secondary"> (German)</span>
                      </span>
                      <input
                        disabled={view}
                        type="text"
                        placeholder="Last Name in German"
                        name="lastName_de"
                        className={`my-1 form-control drawer_input ${
                          formik.errors.lastName_de && formik.touched.name
                            ? "input_error"
                            : ""
                        }`}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.lastName_de}
                      />
                      {formik.errors.lastName_de &&
                        formik.touched.lastName_de && (
                          <ErrorMessage message={formik.errors.lastName_de} />
                        )}{" "}
                    </>
                  )}
                   {language === "uk" && (
                    <>
                      {" "}
                      <span className="drawer_span">
                        Last Name
                        <span className="form-label-secondary"> (Ukrainian)</span>
                      </span>
                      <input
                        disabled={view}
                        type="text"
                        placeholder="Last Name in Ukrainian"
                        name="lastName_uk"
                        className={`my-1 form-control drawer_input ${
                          formik.errors.lastName_uk && formik.touched.name
                            ? "input_error"
                            : ""
                        }`}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.lastName_uk}
                      />
                      {formik.errors.lastName_uk &&
                        formik.touched.lastName_uk && (
                          <ErrorMessage message={formik.errors.lastName_uk} />
                        )}{" "}
                    </>
                  )}

                  {/************************/}
                  <span className="drawer_span">
                    Experiane{" "}
                    <span className="form-label-secondary">
                      {" "}
                      (No. of years)
                    </span>
                  </span>
                  <div>
                    <input
                      disabled={view}
                      type="number"
                      placeholder="Experiane"
                      name="experiance"
                      className={`form-control drawer_input ${
                        formik.errors.experiance && formik.touched.experiance
                          ? "input_error"
                          : ""
                      }`}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.experiance}
                    />
                    {formik.errors.experiance && formik.touched.experiance && (
                      <ErrorMessage message={formik.errors.experiance} />
                    )}
                  </div>

                  {/*************Service Language ***********/}

                  <span className="drawer_span">Service Language</span>
                  <div className="d-flex">
                    <Select
                      isDisabled={view}
                      isMulti
                      name="serviceLanguages"
                      value={formik.values.serviceLanguageIds}
                      options={serviceLanguages}
                      onChange={(e) => {
                        formik.setFieldValue("serviceLanguageIds", e);
                      }}
                      className="drawer_input form-control w-100 basic-multi-select multiSelectBox"
                      classNamePrefix="select"
                    />
                    <div
                      className="col-sm-auto d-flex align-items-center  "
                      style={{ marginLeft: "0.6rem" }}
                    >
                      <span
                        onClick={() =>
                          setServiceLanguageDrawer(!serviceLanguageDrawer)
                        }
                        className=" btn btn-primary btn-theme"
                        data-bs-toggle="modal"
                        data-bs-target="#newProjectModal"
                      >
                        <i className="bi-plus me-1"></i> Add
                      </span>
                    </div>
                  </div>

                  {/*************Service Language ***********/}
                </div>
              </div>
              {/****** Address *****/}
              <div className="card mb-3 mb-lg-5 ">
                <div className="card-header card-header-content-between">
                  <h5>Address</h5>
                </div>

                <div className="card-body">
                  <div className="col">
                    {language === "en" && (
                      <>
                        {" "}
                        <span className="drawer_span">
                          Address{" "}
                          <span className="form-label-secondary">
                            (English)
                          </span>
                        </span>
                        <input
                          disabled={view}
                          type="text"
                          placeholder="Addressin English"
                          name="address_en"
                          className={`form-control drawer_input ${
                            formik.errors.address_en &&
                            formik.touched.address_en
                              ? "input_error"
                              : ""
                          }`}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.address_en}
                        />
                        {formik.errors.address_en &&
                          formik.touched.address_en && (
                            <ErrorMessage message={formik.errors.address_en} />
                          )}
                      </>
                    )}

                    {/************************/}
                    {language === "ar" && (
                      <>
                        {" "}
                        <span className="drawer_span">
                          Address{" "}
                          <span className="form-label-secondary">(Arabic)</span>
                        </span>
                        <input
                          disabled={view}
                          type="text"
                          placeholder="Address in Arabic"
                          name="address_ar"
                          className={`my-2 form-control drawer_input ${
                            formik.errors.address_ar &&
                            formik.touched.address_ar
                              ? "input_error"
                              : ""
                          }`}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.address_ar}
                        />
                        {formik.errors.address_ar &&
                          formik.touched.address_ar && (
                            <ErrorMessage message={formik.errors.address_ar} />
                          )}
                      </>
                    )}

                    {/************************/}
                    {language === "ru" && (
                      <>
                        {" "}
                        <span className="drawer_span">
                          Address{" "}
                          <span className="form-label-secondary">
                            (Russian)
                          </span>
                        </span>
                        <input
                          disabled={view}
                          type="text"
                          placeholder="Address in Russian"
                          name="address_ru"
                          className={`my-1 form-control drawer_input ${
                            formik.errors.address_ru &&
                            formik.touched.address_ru
                              ? "input_error"
                              : ""
                          }`}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.address_ru}
                        />
                        {formik.errors.address_ru &&
                          formik.touched.address_ru && (
                            <ErrorMessage message={formik.errors.address_ru} />
                          )}
                      </>
                    )}

                    {/************************/}
                    {language === "de" && (
                      <>
                        {" "}
                        <span className="drawer_span">
                          Address{" "}
                          <span className="form-label-secondary">(German)</span>
                        </span>
                        <input
                          disabled={view}
                          type="text"
                          placeholder="Address in German"
                          name="address_de"
                          className={`my-1 form-control drawer_input ${
                            formik.errors.address_de &&
                            formik.touched.address_de
                              ? "input_error"
                              : ""
                          }`}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.address_de}
                        />
                        {formik.errors.address_de &&
                          formik.touched.address_de && (
                            <ErrorMessage message={formik.errors.address_de} />
                          )}
                      </>
                    )}

                     {/************************/}
                     {language === "uk" && (
                      <>
                        {" "}
                        <span className="drawer_span">
                          Address{" "}
                          <span className="form-label-secondary">(Ukrainian)</span>
                        </span>
                        <input
                          disabled={view}
                          type="text"
                          placeholder="Address in Ukrainian"
                          name="address_uk"
                          className={`my-1 form-control drawer_input ${
                            formik.errors.address_uk &&
                            formik.touched.address_uk
                              ? "input_error"
                              : ""
                          }`}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.address_uk}
                        />
                        {formik.errors.address_uk &&
                          formik.touched.address_uk && (
                            <ErrorMessage message={formik.errors.address_uk} />
                          )}
                      </>
                    )}

                    <span className="drawer_span">Zip Code</span>
                    <input
                      disabled={view}
                      type="text"
                      placeholder="Zip Code"
                      name="zipCode"
                      className={`form-control drawer_input ${
                        formik.errors.zipCode && formik.touched.zipCode
                          ? "input_error"
                          : ""
                      }`}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.zipCode}
                    />
                    {formik.errors.zipCode && formik.touched.zipCode && (
                      <ErrorMessage message={formik.errors.zipCode} />
                    )}
                  </div>
                  <div className="col ">
                    <span className="drawer_span">Country</span>
                    <select
                      disabled={view}
                      className="form-control drawer_input "
                      name="countryId"
                      onChange={(e) => {
                        formik.handleChange(e);
                        formik.setFieldValue("cityId", "");
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.countryId}
                    >
                      <option value={null}> Select Country</option>
                      {country?.map((entity, index) => (
                        <option value={entity.id} key={index}>
                          {entity.name_en}
                        </option>
                      ))}
                    </select>
                    {formik.errors.countryId && formik.touched.countryId && (
                      <ErrorMessage message={formik.errors.countryId} />
                    )}
                  </div>
                  <div className="col  ">
                    <span className="drawer_span">City</span>
                    <select
                      disabled={view}
                      defaultValue={formik.values.countryId}
                      className={`form-control drawer_input ${
                        formik.errors.cityId && formik.touched.cityId
                          ? "input_error"
                          : ""
                      }`}
                      name="cityId"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.cityId}
                    >
                      <option value={""}> Select City</option>
                      {city?.map(
                        (entity, index) =>
                          formik.values.countryId === entity.countryId && (
                            <option value={entity.id} key={index}>
                              {entity.name_en}
                            </option>
                          )
                      )}
                    </select>
                    {formik.errors.cityId && formik.touched.cityId && (
                      <ErrorMessage message={formik.errors.cityId} />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              {/****** Images *****/}
              <div className="card mb-3 mb-lg-5">
                <div className="card-header card-header-content-between">
                  <h5> Profile Image</h5>
                </div>

                <div className="card-body">
                  <div
                    id="attachFilesNewProjectLabel"
                    className="js-dropzone dz-dropzone dz-dropzone-card dz-clickable"
                  >
                    <div className="dz-message">
                      <div className="d-flex align-items-center   h-100 text-center">
                        <Upload
                          disabled={view}
                          listType="picture-card"
                          fileList={formik.values.image}
                          onPreview={handlePreview}
                          onChange={({ fileList: images }) => {
                            formik.setFieldValue("image", images);
                          }}
                        >
                          {formik.values.image.length >= 1 ? null : (
                            <UploadButton />
                          )}
                        </Upload>
                      </div>
                      <Modal
                        open={previewOpen}
                        title={previewTitle}
                        footer={null}
                        onCancel={() => setPreviewOpen(false)}
                      >
                        <img
                          alt="example"
                          style={{
                            width: "100%",
                          }}
                          src={previewImage}
                        />
                      </Modal>
                    </div>
                  </div>
                </div>
              </div>
              {/****** Status *****/}
              <div className="card mb-3 mb-lg-5 ">
                <div className="card-header card-header-content-between">
                  <h5>Status</h5>
                </div>

                <div className="card-body container-fluid">
                  <div className="row">
                    <div className="col-6  ">
                      <div className="form-check form-switch">
                        <input
                          disabled={view}
                          name="isActive"
                          type="checkbox"
                          role="switch"
                          checked={formik.values.isActive}
                          className={`form-check-input drawer_input ${
                            formik.errors.isActive && formik.touched.isActive
                              ? "input_error"
                              : ""
                          }`}
                          onChange={(e) => {
                            formik.setFieldValue("isActive", e.target.checked);
                          }}
                          onBlur={formik.handleBlur}
                          value={formik.values.isActive}
                        />
                        <label
                          className="form-check-label"
                          for="flexSwitchCheckDefault"
                        >
                          Is Active?
                        </label>
                      </div>
                      {formik.errors.isActive && formik.touched.isActive && (
                        <ErrorMessage message={formik.errors.isActive} />
                      )}
                    </div>
                    <div className="col-6   ">
                      <div className="form-check form-switch">
                        <input
                          disabled={view}
                          name="isFeatured"
                          type="checkbox"
                          role="switch"
                          checked={formik.values.isFeatured}
                          className={` drawer_input form-check-input drawer_input ${
                            formik.errors.isFeatured &&
                            formik.touched.isFeatured
                              ? "input_error"
                              : ""
                          }`}
                          onChange={(e) => {
                            formik.setFieldValue(
                              "isFeatured",
                              e.target.checked
                            );
                          }}
                          onBlur={formik.handleBlur}
                          value={formik.values.isFeatured}
                        />
                        <label
                          className="form-check-label"
                          for="flexSwitchCheckDefault"
                        >
                          Is Featured?
                        </label>
                      </div>
                      {formik.errors.isFeatured &&
                        formik.touched.isFeatured && (
                          <ErrorMessage message={formik.errors.isFeatured} />
                        )}
                    </div>
                  </div>
                </div>
              </div>

              {/****** About *****/}
              <div className="card mb-3 mb-lg-5">
                <div className="card-header card-header-content-between">
                  <h5>Social Media</h5>
                </div>

                <div className="card-body">
                  <div className="list-group list-group-flush list-group-no-gutters">
                    {/* Row */}
                    <div className="list-group-item">
                      <div className="d-flex align-items-center">
                        <div className="flex-shrink-0">
                          {/* <img src={about_year_of_foundation_icon} alt="Flag" /> */}
                          <i className="bi bi-facebook h1 text-primary"></i>
                        </div>

                        <div className="flex-grow-1 ms-2">
                          <div className="row align-items-center">
                            <div className="col">
                              <input
                                disabled={view}
                                placeholder="Facebook Profile"
                                name="facebook"
                                className={`form-control drawer_input  ${
                                  formik.errors.facebook &&
                                  formik.touched.facebook
                                    ? "input_error"
                                    : ""
                                }`}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.facebook}
                              ></input>
                              {/* {formik.errors.facebook &&
                                formik.touched.facebook && (
                                  <ErrorMessage
                                    message={formik.errors.facebook}
                                  />
                                )} */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Row */}
                    <div className="list-group-item">
                      <div className="d-flex align-items-center">
                        <div className="flex-shrink-0">
                          {/* <img src={about_year_of_foundation_icon} alt="Flag" /> */}
                          <i className="bi bi-whatsapp h1 text-success"></i>
                        </div>

                        <div className="flex-grow-1 ms-2">
                          <div className="row align-items-center">
                            <div className="col">
                              <input
                                disabled={view}
                                placeholder="Whatsapp contact"
                                name="whatsapp"
                                className={`form-control drawer_input  ${
                                  formik.errors.whatsapp &&
                                  formik.touched.whatsapp
                                    ? "input_error"
                                    : ""
                                }`}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.whatsapp}
                              ></input>
                              {/* {formik.errors.whatsapp &&
                                formik.touched.whatsapp && (
                                  <ErrorMessage
                                    message={formik.errors.whatsapp}
                                  />
                                )} */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Row */}
                    <div className="list-group-item">
                      <div className="d-flex align-items-center">
                        <div className="flex-shrink-0">
                          {/* <img src={about_year_of_foundation_icon} alt="Flag" /> */}
                          <i className="bi bi-envelope-fill h1 text-secondary"></i>
                        </div>

                        <div className="flex-grow-1 ms-2">
                          <div className="row align-items-center">
                            <div className="col">
                              <input
                                disabled={view}
                                placeholder="Email address"
                                name="email"
                                className={`form-control drawer_input  ${
                                  formik.errors.email && formik.touched.email
                                    ? "input_error"
                                    : ""
                                }`}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.email}
                              ></input>
                              {/* {formik.errors.email && formik.touched.email && (
                                <ErrorMessage message={formik.errors.email} />
                              )} */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Row */}
                    <div className="list-group-item">
                      <div className="d-flex align-items-center">
                        <div className="flex-shrink-0">
                          {/* <img src={about_year_of_foundation_icon} alt="Flag" /> */}
                          <i className="bi bi-telephone-fill h1 text-tertiary"></i>
                        </div>

                        <div className="flex-grow-1 ms-2">
                          <div className="row align-items-center">
                            <div className="col">
                              <input
                                disabled={view}
                                placeholder="Phone number"
                                name="phone"
                                className={`form-control drawer_input  ${
                                  formik.errors.phone && formik.touched.phone
                                    ? "input_error"
                                    : ""
                                }`}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.phone}
                              ></input>
                              {/* {formik.errors.phone && formik.touched.phone && (
                                <ErrorMessage message={formik.errors.phone} />
                              )} */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/****** Departments *****/}
              {/* <div className="card mb-3 mb-lg-5 ">
                <div className="card-header card-header-content-between">
                  <h5>Departments</h5>
                </div>

                <div className="card-body container-fluid">
                  <span className="pb-2">Departments</span>
                  <div className="d-flex">
                    <Select
                      isDisabled={view}
                      isMulti
                      name="Doctors"
                      value={formik.values.departmentIds}
                      options={facilityDepartments}
                      onChange={(e) => {
                        formik.setFieldValue("departmentIds", e);
                      }}
                      className="drawer_input form-control w-100 basic-multi-select multiSelectBox"
                      classNamePrefix="select"
                    />
                    <div
                      className="col-sm-auto d-flex align-items-center "
                      style={{ marginLeft: "0.6rem" }}
                    >
                      <a
                        onClick={() =>
                          setFacilityDepartmentDrawer(!facilityDepartmentDrawer)
                        }
                        className="btn btn-primary btn-theme"
                        href="javascript:;"
                        data-bs-toggle="modal"
                        data-bs-target="#newProjectModal"
                      >
                        <i className="bi-plus me-1"></i> Add
                      </a>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
            <div className="col-12">
              {/****** Specilities *****/}
              <div className="card mb-3 mb-lg-5 ">
                <div className="card-header card-header-content-between">
                  <h5>Specilities</h5>
                </div>

                <div className="card-body container-fluid">
                  <span className="pb-2">Specilities</span>
                  <div className="d-flex">
                    <Select
                      isDisabled={view}
                      isMulti
                      name="Specilities"
                      value={formik.values.specialityIds}
                      options={specialitiess}
                      onChange={(e) => {
                        formik.setFieldValue("specialityIds", e);
                      }}
                      className="drawer_input form-control w-100 basic-multi-select multiSelectBox"
                      classNamePrefix="select"
                    />
                    <div
                      className="col-sm-auto d-flex align-items-center "
                      style={{ marginLeft: "0.6rem" }}
                    >
                      <span
                        onClick={() => setSpecialityDrawer(!specialityDrawer)}
                        className="btn btn-primary btn-theme"
                        data-bs-toggle="modal"
                        data-bs-target="#newProjectModal"
                      >
                        <i className="bi-plus me-1"></i> Add
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              {/****** Overview *****/}
              <div className="card mb-3 mb-lg-5">
                <div className="card-header card-header-content-between">
                  <h5>Overview</h5>
                </div>

                <div className="card-body">
                  <div className=" ">
                    {/* Overview  */}

                    {language === "en" && (
                      <>
                        {" "}
                        <div className="my-3">
                          <span className="drawer_span">
                            Overview{" "}
                            <span className="form-label-secondary">
                              (English)
                            </span>
                          </span>
                          <RichEditor
                            disabled={view}
                            className="input_error"
                            value={formik.values.overview_en}
                            formik={formik}
                            setValue={(value) =>
                              formik.setFieldValue("overview_en", value)
                            }
                          />
                          {formik.errors.overview_en &&
                            formik.touched.overview_en && (
                              <ErrorMessage
                                message={formik.errors.overview_en}
                              />
                            )}
                        </div>
                      </>
                    )}

                    {/* Overview  */}
                    {language === "ar" && (
                      <>
                        {" "}
                        <div className="my-3">
                          <span className="drawer_span">
                            Overview{" "}
                            <span className="form-label-secondary">
                              (Arabic)
                            </span>
                          </span>
                          <RichEditor
                            disabled={view}
                            value={formik.values.overview_ar}
                            formik={formik}
                            setValue={(value) =>
                              formik.setFieldValue("overview_ar", value)
                            }
                          />
                        </div>
                      </>
                    )}

                    {/* Overview  */}
                    {language === "de" && (
                      <>
                        {" "}
                        <div className="my-3">
                          <span className="drawer_span">
                            Overview{" "}
                            <span className="form-label-secondary">
                              (German)
                            </span>
                          </span>
                          <RichEditor
                            disabled={view}
                            value={formik.values.overview_de}
                            formik={formik}
                            setValue={(value) =>
                              formik.setFieldValue("overview_de", value)
                            }
                          />
                        </div>
                      </>
                    )}

                    {/* Overview  */}
                    {language === "ru" && (
                      <>
                        {" "}
                        <div className="my-3">
                          <span className="drawer_span">
                            Overview{" "}
                            <span className="form-label-secondary">
                              (Russian)
                            </span>
                          </span>
                          <RichEditor
                            disabled={view}
                            value={formik.values.overview_ru}
                            formik={formik}
                            setValue={(value) =>
                              formik.setFieldValue("overview_ru", value)
                            }
                          />
                        </div>
                      </>
                    )}

                     {/* Overview  */}
                     {language === "uk" && (
                      <>
                        {" "}
                        <div className="my-3">
                          <span className="drawer_span">
                            Overview{" "}
                            <span className="form-label-secondary">
                              (Ukrainian)
                            </span>
                          </span>
                          <RichEditor
                            disabled={view}
                            value={formik.values.overview_uk}
                            formik={formik}
                            setValue={(value) =>
                              formik.setFieldValue("overview_uk", value)
                            }
                          />
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default BasicInfo;
