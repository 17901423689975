import * as requestFromServer from "./Crud";
import { statisticsSlice } from "./Reducer";
const { actions } = statisticsSlice;

export const loading = (payload) => (dispatch) => {
  dispatch(actions.setLoading(payload));
};

export const getAll = () => (dispatch) => {
  dispatch(actions.setLoading({ getAll: true }));
  return requestFromServer
    .getAll()
    .then((response) => {
      dispatch(actions.getAll({ statistics: response?.data || {} }));
    })
    .catch((error) => {})
    .finally(() => {
      dispatch(actions.setLoading({ getAll: false }));
    });
};

// export const create =
//   (data, setDrawer, resetForm, pagination) => (dispatch) => {
//     dispatch(actions.setLoading({ create: true }));
//     return requestFromServer
//       .create(data)
//       .then(({ data }) => {
//         if (data) {
//           notification.success({ description: CRUD_COMMON_MESSAGE.CREATE });
//           dispatch(getAll(pagination));
//           setDrawer(false);
//           resetForm();
//         }
//       })
//       .catch((error) => {})
//       .finally(() => {
//         dispatch(actions.setLoading({ create: false }));
//       });
//   };

// export const remove = (id, setDeleteRecord, pagination) => (dispatch) => {
//   dispatch(actions.setLoading({ remove: true }));
//   return requestFromServer
//     .remove(id)
//     .then(({ data }) => {
//       if (data) {
//         notification.success({description:CRUD_COMMON_MESSAGE.REMOVE});
//         dispatch(getAll(pagination));
//         setDeleteRecord(null);
//       }
//     })
//     .catch((error) => {})
//     .finally(() => {
//       dispatch(actions.setLoading({ remove: false }));
//     });
// };

// export const update =
//   (id, data, setDrawer, resetForm, pagination) => (dispatch) => {
//     dispatch(actions.setLoading({ update: true }));
//     return requestFromServer
//       .update(id, data)
//       .then(({ data }) => {
//         if (data) {
//           notification.success({ description: CRUD_COMMON_MESSAGE.UPDATE });
//           dispatch(getAll(pagination));
//           setDrawer(false);
//           resetForm();
//         }
//       })
//       .catch((error) => {})
//       .finally(() => {
//         dispatch(actions.setLoading({ update: false }));
//       });
//   };
