import { Steps } from "antd";

const RequestStatusModal = ({
  status: payload,
}) => {
  return (
    <>
      <div className="container-fluid ">
        <h1 className="text-center mt-2 mb-3">Request Logs</h1>
        {
          <>
            <Steps
              progressDot
              current={payload?.requestLog?.length || 0}
              direction="vertical"
              items={
                payload?.requestLog.map((log) => {
                  return {
                    title: log?.updatedStatus,
                    description: (
                      <>
                        {log?.user && (
                          <div className="col">
                            <span className="badge bg-soft-primary text-primary p-2">
                              {log?.user?.email}
                            </span>
                          </div>
                        )}
                        <p>{new Date(log?.createdAt).toLocaleString()}</p>
                        <p className="mt-0">{log?.message}</p>
                      </>
                    ),
                  };
                })
              }
            />
          </>
        }
      </div>
    </>
  );
};

export default RequestStatusModal;
