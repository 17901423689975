import axios from "axios";
import { ApiUrl } from "services/api";

const { Settings: Api } = ApiUrl;

export const getAll = async () =>
  axios[Api.getAll.method](Api.getAll.url());

export const update = async (id, data) =>
  axios[Api.update.method](Api.update.url(id), data);
