import { Drawer, Space, Spin } from "antd";
import ErrorMessage from "components/error-message/ErrorMessage";
import RichEditor from "components/rich-text-Editor/RichEditor";
import { useFormik } from "formik";
import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import * as actions from "redux/common/facility-department/Actions";
import { LanguageSegment } from "components/language-segment/LanguageSegment";

function FacilityDepartmentDrawer(props) {
  const {
    setDrawer,
    drawer,
    getPagination,
    editRecord,
    setEditRecord,
    view,
    setView,
    onEdit,
    onCreate // Accept the callback function
  } = props;

  const {
    name_en = null,
    name_ar = null,
    name_ru = null,
    name_de = null,
    name_uk = null,
    detail_en = "",
    detail_ar = "",
    detail_de = "",
    detail_ru = "",
    detail_uk = "",

    id = null,
  } = editRecord || {};

  const { loading } = useSelector(
    (state) => ({
      loading: state.facilityDepartment?.loading,
    }),
    shallowEqual
  );

  const { Segment, language } = LanguageSegment();
  console.log("editRecord depa us:",editRecord)
  const isEditing = Boolean(editRecord);
  const isLoading = isEditing ? loading.update : loading.create;
  const title = `${view ? "View" : isEditing ? " Edit " : " Add "}  Facility Department`;
  const dispatch = useDispatch();
  const pagination = getPagination();

  const setEditDefaultValues = () => formik.setValues({ ...initialValues });
  const resetForm = () => formik.resetForm();
  const closeDrawer = () => setDrawer(false);

  const onDrawerChange = (e) => {
    if (!e) {
      view && setView(false);
      editRecord && setEditRecord(null);
      resetForm();
      dispatch(
        actions.loading({ update: false, create: false, remove: false })
      );
    }
  };

  useEffect(() => {
    drawer && isEditing && setEditDefaultValues();
  }, 
  // eslint-disable-next-line 
  [drawer]);

  // Formik Handler
  const initialValues = {
    name_en: name_en || "",
    name_ar: name_ar || "",
    name_ru: name_ru || "",
    name_de: name_de || "",
    name_uk: name_uk || "",
    detail_en: detail_en || "",
    detail_ar: detail_ar || "",
    detail_ru: detail_ru || "",
    detail_de: detail_de || "",
    detail_uk: detail_uk || "",
  };

  const validationSchema = Yup.object().shape({
    detail_en: Yup.string().required("Detail (English) is required"),
    detail_ar: Yup.string(),
    detail_de: Yup.string(),
    detail_ru: Yup.string(),
    detail_uk: Yup.string(),
  });

  const onSubmit = async (values) => {
    dispatch(actions.loading(isEditing ? { update: true } : { create: true }));

    const action = isEditing
      ? actions.update(id, values, setDrawer, resetForm, pagination)
      : actions.create(values, setDrawer, resetForm, pagination);

    const response = await dispatch(action);

    if (isEditing) {
      onEdit(values, id);
    } else {
      onCreate(response);
    }
  };

  const formik = useFormik({ initialValues, validationSchema, onSubmit });

  return (
    <>
      <Drawer
        afterOpenChange={onDrawerChange}
        title={title}
        placement={"right"}
        width="70%"
        onClose={closeDrawer}
        open={drawer}
        extra={
          <Space>
            {!view && (
              <>
                {!editRecord && (
                  <>
                    <button
                      disabled={isLoading}
                      onClick={resetForm}
                      className="btn-sm btn btn-light px-4 "
                      style={{ border: "1px solid grey" }}
                    >
                      Clear
                    </button>
                  </>
                )}
                <button
                  type="button"
                  disabled={isLoading}
                  onClick={formik.handleSubmit}
                  className={`btn-sm btn ${isLoading ? "btn-light" : "btn-theme"} px-4`}
                >
                  {isLoading ? <Spin /> : "Save"}
                </button>
              </>
            )}
          </Space>
        }
      >
        <div className="drawer_main_div ">
          <h2 className="text-center">{title}</h2>

          {/* language segment */}
          <div className="language-segmented px-3 mb-3">{Segment}</div>

          <div className="content container-fluid mt-2 mx-0 px-3">
            <div className="col mx-3">
              {language === "en" && (
                <>
                  <div className="my-2">
                    <span className="drawer_span required">
                      Name <span className="form-label-secondary">(English)</span>
                    </span>
                    <input
                      disabled={view}
                      type="text"
                      name="name_en"
                      className={`form-control ${
                        formik.errors.name_en && formik.touched.name_en ? "input_error" : ""
                      }`}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.name_en}
                      placeholder="Enter department name in (English)"
                    />
                    {formik.errors.name_en && formik.touched.name_en && (
                      <ErrorMessage message={formik.errors.name_en} />
                    )}
                  </div>
                  <div className="my-3">
                    <span className="drawer_span required">
                      Detail <span className="form-label-secondary">(English)</span>
                    </span>
                    <RichEditor
                      value={formik.values.detail_en}
                      formik={formik}
                      setValue={(value) => formik.setFieldValue("detail_en", value)}
                    />
                    {formik.errors.detail_en && formik.touched.detail_en && (
                      <ErrorMessage message={formik.errors.detail_en} />
                    )}
                  </div>
                </>
              )}

              {language === "ar" && (
                <>
                  <div className="my-2">
                    <span className="drawer_span">
                      Name <span className="form-label-secondary">(Arabic)</span>
                    </span>
                    <input
                      disabled={view}
                      type="text"
                      name="name_ar"
                      className={`form-control ${
                        formik.errors.name_ar && formik.touched.name_ar ? "input_error" : ""
                      }`}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.name_ar}
                      placeholder="Enter department name in (Arabic)"
                    />
                    {formik.errors.name_ar && formik.touched.name_ar && (
                      <ErrorMessage message={formik.errors.name_ar} />
                    )}
                  </div>
                  <div className="my-3">
                    <span className="drawer_span">
                      Detail <span className="form-label-secondary">(Arabic)</span>
                    </span>
                    <RichEditor
                      value={formik.values.detail_ar}
                      formik={formik}
                      setValue={(value) => formik.setFieldValue("detail_ar", value)}
                    />
                  </div>
                </>
              )}

              {language === "de" && (
                <>
                  <div className="my-2">
                    <span className="drawer_span">
                      Name <span className="form-label-secondary">(German)</span>
                    </span>
                    <input
                      disabled={view}
                      type="text"
                      name="name_de"
                      className={`form-control ${
                        formik.errors.name_de && formik.touched.name_de ? "input_error" : ""
                      }`}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.name_de}
                      placeholder="Enter department name in (German)"
                    />
                    {formik.errors.name_de && formik.touched.name_de && (
                      <ErrorMessage message={formik.errors.name_de} />
                    )}
                  </div>
                  <div className="my-3">
                    <span className="drawer_span">
                      Detail <span className="form-label-secondary">(German)</span>
                    </span>
                    <RichEditor
                      value={formik.values.detail_de}
                      formik={formik}
                      setValue={(value) => formik.setFieldValue("detail_de", value)}
                    />
                  </div>
                </>
              )}

              {language === "ru" && (
                <>
                  <div className="my-2">
                    <span className="drawer_span">
                      Name <span className="form-label-secondary">(Russian)</span>
                    </span>
                    <input
                      disabled={view}
                      type="text"
                      name="name_ru"
                      className={`form-control ${
                        formik.errors.name_ru && formik.touched.name_ru ? "input_error" : ""
                      }`}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.name_ru}
                      placeholder="Enter department name in (Russian)"
                    />
                    {formik.errors.name_ru && formik.touched.name_ru && (
                      <ErrorMessage message={formik.errors.name_ru} />
                    )}
                  </div>
                  <div className="my-3">
                    <span className="drawer_span">
                      Detail <span className="form-label-secondary">(Russian)</span>
                    </span>
                    <RichEditor
                      value={formik.values.detail_ru}
                      formik={formik}
                      setValue={(value) => formik.setFieldValue("detail_ru", value)}
                    />
                  </div>
                </>
              )}

              {language === "uk" && (
                <>
                  <div className="my-2">
                    <span className="drawer_span">
                      Name <span className="form-label-secondary">(Ukrainian)</span>
                    </span>
                    <input
                      disabled={view}
                      type="text"
                      name="name_uk"
                      className={`form-control ${
                        formik.errors.name_uk && formik.touched.name_uk ? "input_error" : ""
                      }`}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.name_uk}
                      placeholder="Enter department name in (Ukrainian)"
                    />
                    {formik.errors.name_uk && formik.touched.name_uk && (
                      <ErrorMessage message={formik.errors.name_uk} />
                    )}
                  </div>
                  <div className="my-3">
                    <span className="drawer_span">
                      Detail <span className="form-label-secondary">(Ukrainian)</span>
                    </span>
                    <RichEditor
                      value={formik.values.detail_uk}
                      formik={formik}
                      setValue={(value) => formik.setFieldValue("detail_uk", value)}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </Drawer>
    </>
  );
}

export default FacilityDepartmentDrawer;
