import React from "react";
import { PlusOutlined } from "@ant-design/icons";

export const IconButton = ({ iconClass, backgroundColor, ...props }) => (
  <i
    {...props}
    className={iconClass}
    style={{
      opacity: 1,
      cursor: "pointer",
      color: "white",
      padding: "8px 10px",
      background: backgroundColor,
      borderRadius: 10,
    }}
  ></i>
);
export const IconButtonResponse = ({ iconClass, backgroundColor, ...props }) => (
  <i
    {...props}
    className={iconClass}
    style={{
      cursor: "pointer",
      color: "black",
      padding: "8px 5px",
      fontSize:"28px"
    }}
  ></i>
);
export const DeleteButton = ({ backgroundColor, ...props }) => (
  <IconButton
    iconClass="bi-trash nav-icon "
    backgroundColor={backgroundColor ?? "#f30301"}
    {...props}
    
  />
);
export const DeleteButtonResponse = ({ backgroundColor, ...props }) => (
  <IconButtonResponse
    iconClass="bi-trash nav-icon "
    backgroundColor={backgroundColor ?? "#f30301"}
    {...props}
  />
);

export const EditReportViewButton = ({ backgroundColor, ...props }) => (
  <IconButtonResponse
    iconClass="bi-pencil nav-icon "
    backgroundColor={backgroundColor ?? "#f30301"}
    {...props}
  />
);

export const DetailReportViewButton = ({ backgroundColor, ...props }) => (
  <IconButtonResponse
    iconClass="bi bi-tv nav-icon "
    backgroundColor={backgroundColor ?? "#f30301"}
    {...props}
  />
);

export const HistoryReportViewButton = ({ backgroundColor, ...props }) => (
  <IconButtonResponse
    iconClass="bi bi-clock-history nav-icon "
    backgroundColor={backgroundColor ?? "#f30301"}
    {...props}
  />
);

export const EditButton = (props) => (
  <IconButton
    iconClass="bi-pencil-fill nav-icon "
    backgroundColor="#132144"
    {...props}
  />
);
export const ViewButton = (props) => (
  <IconButton
    iconClass="bi bi-eye-fill nav-icon"
    backgroundColor="#808080"
    {...props}
  />
);

export const UploadButton = () => (
  <div>
    <PlusOutlined />
    <div style={{ marginTop: 8 }}>Upload</div>
  </div>
);
