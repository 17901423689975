import { notification } from "antd";
import { CRUD_COMMON_MESSAGE } from "utils/constants/contants";
import * as requestFromServer from "./Crud";
import { testimonialsSlice } from "./Reducer";
const { actions } = testimonialsSlice;

export const loading = (payload) => (dispatch) => {
  dispatch(actions.setLoading(payload));
};


export const getUploadUrl = (videoLinkKey) => (dispatch) => {
  dispatch(actions.setLoading({ getUploadUrl: true }));
  return requestFromServer
    .getUploadUrl(videoLinkKey)
    .finally(() => {
      dispatch(actions.setLoading({ getUploadUrl: false }));
    });
};

export const UploadVideoToS3 = (s3Url,uploadedFile) => (dispatch) => {
  dispatch(actions.setLoading({ getUploadUrl: true }));
  return requestFromServer
    .UploadVideoToS3(s3Url,uploadedFile)
    .finally(() => {
      dispatch(actions.setLoading({ getUploadUrl: false }));

    });
};

export const getVideoUrl = (videoLinkKey) => (dispatch) => {
  dispatch(actions.setLoading({ getUploadUrl: true }));
  return requestFromServer
    .getVideoUrl(videoLinkKey)    
    .finally(() => {
      dispatch(actions.setLoading({ getUploadUrl: false }));
    });
};


export const getAll = (pagination) => (dispatch) => {
  dispatch(actions.setLoading({ getAll: true }));
  return requestFromServer
    .getAll(pagination)
    .then((response) => {
      const { records = [], count = 0 } = response.data;
      dispatch(actions.getAll({ records, count }));
    })
    .catch(() => {})
    .finally(() => dispatch(actions.setLoading({ getAll: false })));
};


export const create =
  (data, setDrawer, resetForm, pagination) => (dispatch) => {
    dispatch(actions.setLoading({ create: true }));
    return requestFromServer
      .create(data)
      .then(({ data }) => {
        if (data) {
          notification.success({ description: CRUD_COMMON_MESSAGE.CREATE });
          dispatch(getAll(pagination));
          setDrawer(false);
          resetForm();
        }
      })
      .catch((error) => {})
      .finally(() => {
        dispatch(actions.setLoading({ create: false }));
      });
  };

export const remove = (id, setDeleteRecord, pagination) => (dispatch) => {
  dispatch(actions.setLoading({ remove: true }));
  return requestFromServer
    .remove(id)
    .then(({ data }) => {
      if (data) {
        notification.success({ description: CRUD_COMMON_MESSAGE.REMOVE });
        dispatch(getAll(pagination));
        setDeleteRecord(null);
      }
    })
    .catch((error) => {})
    .finally(() => {
      dispatch(actions.setLoading({ remove: false }));
    });
};

export const update =
  (id, data, setDrawer, resetForm, pagination, setEditRecord) => (dispatch) => {
    dispatch(actions.setLoading({ update: true }));
    return requestFromServer
      .update(id, data)
      .then(({ data }) => {
        if (data) {
          notification.success({ description: CRUD_COMMON_MESSAGE.UPDATE });
          dispatch(getAll(pagination));
          setDrawer(false);
          resetForm();
          setEditRecord(null);
        }
      })
      .catch((error) => {})
      .finally(() => {
        dispatch(actions.setLoading({ update: false }));
      });
  };
