import { Tag } from "antd";
import React, { useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useParams } from "react-router";
import { createQueryParams } from "utils/helpers/helper-functions";
import CitiesDrawer from "./city.drawer";
import CitiesTable from "./city.table";
import { cityRelations as relations } from "services/relations";

export default function Citites() {
  const { id } = useParams();
  const [drawer, setDrawer] = useState(false);
  const [editRecord, setEditRecord] = useState(null);
  const [view, setView] = useState(false);
  const [deleteRecord, setDeleteRecord] = useState(null);
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchText, setSearchText] = useState("");
  const { country } = useSelector(
    (state) => ({
      country: state.city?.country,
    }),
    shallowEqual
  );

  const getPagination = () => {
    const limit = pageSize;
    let offset = (pageNumber - 1) * pageSize;
    offset = offset > 1 ? offset : 0;
    const text = searchText.trim() || null;
    let query = createQueryParams(limit, offset, text, relations, `where[countryId]='${id}'`);
    return { limit, offset, text, relations, query };
  };

  const commonProps = {
    view,
    setView,
    pageSize,
    setPageSize,
    pageNumber,
    setPageNumber,
    searchText,
    setSearchText,
    getPagination,
    drawer,
    setDrawer,
    deleteRecord,
    setDeleteRecord,
    editRecord,
    setEditRecord,
  };

  return (
    <div>
      <div className="py-4 mt-3 table_main_div d-flex justify-content-between">
        <h1 className="mx-5 mt-2">
          Cities
          {country?.name_en && (
              <Tag
                color="purple"
                className="px-4 py-1 mx-2"
                style={{ fontSize: "14px" }}
              >
                {country?.name_en || ""}
              </Tag>
          )}
        
        </h1>
        <button className="btn mx-5 btn-theme" onClick={() => setDrawer(true)}>
          Add New City
        </button>
      </div>

      <CitiesTable {...commonProps} />
      <CitiesDrawer {...commonProps} />
    </div>
  );
}
