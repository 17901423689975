import axios from "axios";
import { ApiUrl } from "services/api";

const { Country: CountryApi } = ApiUrl;

export const getAll = async ({ query }) =>
  axios[CountryApi.getAll.method](CountryApi.getAll.url(query));

export const create = async (data) =>
  axios[CountryApi.create.method](CountryApi.create.url(), data);

export const createGlobalContent = async (data) =>
  axios[CountryApi.createGeneralContent.method](CountryApi.createGeneralContent.url(), data);

export const getGlobalContent = async ( query ) =>
  axios[CountryApi.getGeneralContent.method](CountryApi.getGeneralContent.url(query));

export const bulk = async (data) =>
  axios[CountryApi.bulk.method](CountryApi.bulk.url(), data);

export const remove = async (id) =>
  axios[CountryApi.remove.method](CountryApi.remove.url(id));

export const update = async (id, data) =>
  axios[CountryApi.update.method](CountryApi.update.url(id), data);
