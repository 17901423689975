import { Drawer, Space, Spin } from "antd";
import ErrorMessage from "components/error-message/ErrorMessage";
import { useFormik } from "formik";
import { useEffect,useCallback } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "redux/diseases/Actions";
import * as treatment_actions from "redux/treatment/Actions";
import * as Yup from "yup";
import Select from "react-select";
import {createQueryParams} from "utils/helpers/helper-functions";
function DiseasesDrawer(props) {
  const { edit, setEdit, view, setView, getPagination, drawer, setDrawer } =
    props;
    
    const {
      treatments,
    } = useSelector(
      (state) => ({
        treatments: state.treatment?.records|| [],
      }),
      shallowEqual
    );
    const dispatch = useDispatch();


    const fetchTreatments = useCallback(() => {
      const limit = 100000;
      let offset = 0;
      const text = "";
      const relations = [];
      const query = createQueryParams(limit, offset, text, relations);
      dispatch(treatment_actions.getAll({ limit, offset, text, relations, query }));
    }, 
    // eslint-disable-next-line
    [dispatch]);
  
    useEffect(() => {
      fetchTreatments();
    },
    // eslint-disable-next-line
    [dispatch]);

  const record = edit || view;

  const { loading } = useSelector(
    (state) => ({
      loading: state.treatment?.loading,
    }),
    shallowEqual
  );

  const {
    name_en = "",
    name_ar = "",
    name_ru = "",
    name_de = "",
    name_uk = "",
    id = null,
    treatmentIds= []
  } = record || {};

  const isEditing = Boolean(record);
  const isLoading = edit ? loading.update : loading.create;
  const title = `${view ? " View " : edit ? " Edit " : " Add "} Disease`;
  const pagination = getPagination();

  const resetForm = () => {
    formik.resetForm();
  };

  const closeDrawer = () => setDrawer(false);

  const onDrawerChange = (e) => {
    if (!e) {
      setView(null);
      setEdit(null);
      resetForm();
      dispatch(
        actions.loading({ update: false, create: false, remove: false })
      );
    }
  };

  const setEditDefaultValues = () => {
    if (record && record.treatmentDisease && record.treatmentDisease[0] && record.treatmentDisease[0].length > 0) {
      const selectedTreatments = record.treatmentDisease[0].map(treatmentDisease => {
        const selectedTreatment = treatments.find(treatment => treatment.id === treatmentDisease.treatmentId);
        if (selectedTreatment) {
          return { value: selectedTreatment.id, label: selectedTreatment.name_en };
        }
        return null; 
      }).filter(Boolean); 
      formik.setValues({ 
        ...initialValues ,
        treatmentIds: selectedTreatments
      });
    } else {
      formik.setValues({ 
        ...initialValues ,
        treatmentIds: []
      });
    }
  };
  

  useEffect(() => {
    drawer && isEditing && setEditDefaultValues();
  },
  // eslint-disable-next-line
  [drawer]);

  
  //formik Handler
  const initialValues = {
    name_en: name_en || "",
    name_ar: name_ar || "",
    name_ru: name_ru || "",
    name_de: name_de || "",
    name_uk: name_uk || "",
    treatmentIds: treatmentIds || []
  };

  const validationSchema = Yup.object().shape({
    name_en: Yup.string().required("Name (English) is required"),
    name_ar: Yup.string().notRequired(),
    name_ru: Yup.string().notRequired(),
    name_de: Yup.string().notRequired(),
    name_uk: Yup.string().notRequired(),
    treatmentIds: Yup.array().notRequired(),
  });

  const onSubmit = async (values) => {
    const transformedValues = {
      ...values,
      treatmentIds: values.treatmentIds.map(treatment => treatment.value)
    };
    dispatch(actions.loading(edit ? { update: true } : { create: true }));

    const action = edit
      ? actions.update(id, transformedValues, setDrawer, resetForm, pagination)
      : actions.create(transformedValues, setDrawer, resetForm, pagination);
    return dispatch(action);
  };

  const formik = useFormik({ initialValues, validationSchema, onSubmit });

  const treatments_ids = treatments?.map(
    ({ id: value, name_en }, index) => ({
      value,
      index,
      label: name_en,
    })
  );

  return (
    <>
      <Drawer
        afterOpenChange={onDrawerChange}
        title={title}
        placement={"right"}
        width={500}
        onClose={closeDrawer}
        open={drawer}
        extra={
          <Space>
            {!view && (
              <>
                {!edit && (
                  <>
                    <button
                      disabled={isLoading}
                      onClick={resetForm}
                      className="btn-sm btn btn-light px-4 "
                      style={{ border: "1px solid grey" }}
                    >
                      Clear
                    </button>
                  </>
                )}
                <button
                  type="button"
                  disabled={isLoading}
                  onClick={formik.handleSubmit}
                  className={`btn-sm btn ${
                    isLoading ? "btn-light" : "btn-theme"
                  } px-4`}
                >
                  {isLoading ? <Spin /> : "save"}
                </button>
              </>
            )}
          </Space>
        }
      >
        <div className="drawer_main_div">
          <h2 className=" text-center">{title}</h2>
          <div className="container-fluid">
            <div className="row">
              <form onSubmit={formik.handleSubmit}>
                {/* /********** INPUT ********/}
                <div className="my-2">
                  <span className="drawer_span required">
                    Name <span className="form-label-secondary">(English)</span>
                  </span>
                  <input
                    disabled={view}
                    type="text"
                    name="name_en"
                    className={`form-control ${
                      formik.errors.name_en && formik.touched.name_en
                        ? "input_error"
                        : ""
                    }`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name_en}
                    placeholder="Enter treatment name in (English)"
                  />
                  {formik.errors.name_en && formik.touched.name_en && (
                    <ErrorMessage message={formik.errors.name_en} />
                  )}
                </div>
                {/* /********** INPUT ********/}
                <div className="my-2">
                  <span className="drawer_span">
                    Name <span className="form-label-secondary">(Arabic)</span>
                  </span>
                  <input
                    disabled={view}
                    type="text"
                    name="name_ar"
                    className={`form-control ${
                      formik.errors.name_ar && formik.touched.name_ar
                        ? "input_error"
                        : ""
                    }`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name_ar}
                    placeholder="Enter treatment name in (Arabic)"
                  />
                  {formik.errors.name_ar && formik.touched.name_ar && (
                    <ErrorMessage message={formik.errors.name_ar} />
                  )}
                </div>
                {/* /********** INPUT ********/}
                <div className="my-2">
                  <span className="drawer_span">
                    Name <span className="form-label-secondary">(Russian)</span>
                  </span>
                  <input
                    disabled={view}
                    type="text"
                    name="name_ru"
                    className={`form-control ${
                      formik.errors.name_ru && formik.touched.name_ru
                        ? "input_error"
                        : ""
                    }`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name_ru}
                    placeholder="Enter treatment name in (Russian)"
                  />
                  {formik.errors.name_ru && formik.touched.name_ru && (
                    <ErrorMessage message={formik.errors.name_ru} />
                  )}
                </div>
                {/* /********** INPUT ********/}
                <div className="my-2">
                  <span className="drawer_span">
                    Name <span className="form-label-secondary">(German)</span>
                  </span>
                  <input
                    disabled={view}
                    type="text"
                    name="name_de"
                    className={`form-control ${
                      formik.errors.name_de && formik.touched.name_de
                        ? "input_error"
                        : ""
                    }`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name_de}
                    placeholder="Enter treatment name in (German)"
                  />
                  {formik.errors.name_de && formik.touched.name_de && (
                    <ErrorMessage message={formik.errors.name_de} />
                  )}
                </div>
                 
                 {/* /********** INPUT ********/}
                 <div className="my-2">
                  <span className="drawer_span">
                    Name <span className="form-label-secondary">(Ukrainian)</span>
                  </span>
                  <input
                    disabled={view}
                    type="text"
                    name="name_uk"
                    className={`form-control ${
                      formik.errors.name_uk && formik.touched.name_uk
                        ? "input_error"
                        : ""
                    }`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name_uk}
                    placeholder="Enter disease name in (Ukrainian)"
                  />
                  {formik.errors.name_uk && formik.touched.name_uk && (
                    <ErrorMessage message={formik.errors.name_uk} />
                  )}
                </div>
                {/* /********** BUTTON ********/}
                 {/*************Treatments***********/}
                 <div className="row   mt-2">
                    <div className="col">
                      <span className="drawer_span">Treatments</span>
                      <div className="d-flex">
                        <Select
                          isDisabled={view}
                          isMulti
                          name="treatmentIds"
                          value={formik.values.treatmentIds}
                          options={treatments_ids}
                          className="drawer_input form-control w-100 basic-multi-select multiSelectBox"
                          classNamePrefix="select"
                          menuPlacement="top"
                          onChange={(selectedOptions) => {
                            formik.setFieldValue("treatmentIds", selectedOptions);
                          }}
                          />
                        {formik.errors.treatmentIds && formik.touched.treatmentIds && (
                    <ErrorMessage message={formik.errors.treatmentIds} />
                  )}
                      </div>
                    </div>
                  </div>
                  {/*************Treatments***********/}
              </form>
            </div>
          </div>
        </div>
      </Drawer>
    </>
  );
}

export default DiseasesDrawer;
