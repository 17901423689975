import { Drawer, Space, Spin, Tabs, notification } from "antd";
import { useFormik } from "formik";
import { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as cityyAction from "redux/city/Actions";
import * as faqsActions from "redux/common/faqs/Actions";
import * as serviceLanguageActions from "redux/common/service-language/Actions";
import * as countryAction from "redux/country/Actions";
import * as actions from "redux/doctor/Actions";
import * as specialitiesAction from "redux/specialities/Actions";
import * as uploadActions from "redux/upload/Actions";
import * as Yup from "yup";
import Achievement from "./components/achievement";
import BasicInfo from "./components/basic-info";
import Education from "./components/education";
import Expertise from "./components/expertise";
import FAQs from "./components/faqs";
import Membership from "./components/membership";

function DoctorDrawer(props) {
  const {
    setDrawer,
    drawer,
    getPagination,
    editRecord,
    setEditRecord,
    view,
    setView,
    recordId,
    setRecordId,
  } = props;

  const { loading } = useSelector(
    (state) => ({
      loading: state.doctor?.loading,
    }),
    shallowEqual
  );

  /********************* DEFAUL || INITAL DATA **********************/

  const {
    firstName_en = null,
    firstName_ar = null,
    firstName_ru = null,
    firstName_de = null,
    firstName_uk = null,

    lastName_en = null,
    lastName_ar = null,
    lastName_ru = null,
    lastName_de = null,
    lastName_uk = null,

    experiance = 0,

    address_en = null,
    address_ar = null,
    address_ru = null,
    address_de = null,
    address_uk = null,
    zipCode = null,

    // education
    education_en = null,
    education_ar = null,
    education_ru = null,
    education_de = null,
    education_uk = null,

    // achievement
    achievement_en = null,
    achievement_ar = null,
    achievement_ru = null,
    achievement_de = null,
    achievement_uk = null,

    // membership
    membership_en = null,
    membership_de = null,
    membership_ar = null,
    membership_ru = null,
    membership_uk = null,

    // expertise
    expertise_en = null,
    expertise_de = null,
    expertise_ar = null,
    expertise_ru = null,
    expertise_uk = null,

    email = "",
    phone = "",
    isFeatured = false,
    isActive = false,
    cityId = null,
    countryId = null,
    id = null,
    overview_en = "",
    overview_de = "",
    overview_ar = "",
    overview_ru = "",
    overview_uk = "",

    facebook = "",
    whatsapp = "",
  } = editRecord || {};
  const isEditing = Boolean(editRecord);
  const isLoading = isEditing ? loading.update : loading.create;

  const title = `${view ? " View " : isEditing ? " Edit " : " Add "} Doctor`;
  const dispatch = useDispatch();
  const pagination = getPagination();

  const setEditDefaultValues = () => {
    const serviceLanguageIds = editRecord?.doctorServiceLanguage?.map(
      ({ serviceLanguage: { id: value, name_en: label } }, index) => ({
        value,
        label,
        index,
      })
    );

    const specialityIds = editRecord?.doctorSpeciality?.map(
      ({ speciality: { id: value, name_en: label } }, index) => ({
        value,
        label,
        index,
      })
    );

    const faqIds = Array.isArray(editRecord?.doctorFaq) ? 
    editRecord?.doctorFaq?.map((faq, index) => ({
    value: faq?.id,
    label:  faq?.question_en || "",
    index,
  })) : [];

    const image = [
      { uid: 1, name: "Name", status: "done", url: editRecord?.image || "" },
    ];
    formik.setValues({
      ...initialValues,
      image,
      serviceLanguageIds,
      specialityIds,
      faqIds,
    });
  };

  const resetForm = () => {
    formik.resetForm();
  };

  const closeDrawer = () => {
    setDrawer(false);
  };

  const onDrawerChange = (e) => {
    if (!e) {
      setView(false);
      setEditRecord(null);
      resetForm();
      setRecordId(null);
      dispatch(
        actions.loading({ update: false, create: false, remove: false })
      );
    }
  };

  const getMasterData = () => {
    const query = `limit=1000000&offset=0`;
    

    Promise.all([
      dispatch(countryAction.getAll({ query })),
      dispatch(cityyAction.getAll({ query })),
      dispatch(specialitiesAction.getAll({ query })),
      dispatch(faqsActions.getAll({ query:`limit=1000000&offset=0&where[doctorFaq][doctorId]=${recordId}` })),
      dispatch(serviceLanguageActions.getAll({ query })),
    ]);
  };

  useEffect(() => {
    // Only call getMasterData when the component mounts
    if (drawer) {
      getMasterData();
      isEditing && setEditDefaultValues();
    }
  }, 
  // eslint-disable-next-line
  [drawer]);

  //formik Handler
  const initialValues = {
    firstName_en: firstName_en || "",
    firstName_ar: firstName_ar || "",
    firstName_ru: firstName_ru || "",
    firstName_de: firstName_de || "",
    firstName_uk: firstName_uk || "",

    lastName_en: lastName_en || "",
    lastName_ar: lastName_ar || "",
    lastName_ru: lastName_ru || "",
    lastName_de: lastName_de || "",
    lastName_uk: lastName_uk || "",

    experiance: experiance || 0,
    image: [],

    address_en: address_en || "",
    address_ar: address_ar || "",
    address_ru: address_ru || "",
    address_de: address_de || "",
    address_uk: address_uk || "",

    zipCode: zipCode || "",
    cityId: cityId || "",
    countryId: countryId || "",

    isFeatured: isFeatured || false,
    isActive: isActive || false,

    facebook: facebook || "",
    whatsapp: whatsapp || "",
    email: email || "",
    phone: phone || "",

    specialityIds: [],

    overview_en: overview_en || "",
    overview_ar: overview_ar || "",
    overview_ru: overview_ru || "",
    overview_de: overview_de || "",
    overview_uk: overview_uk || "",

    //education
    education_en: education_en || "",
    education_ar: education_ar || "",
    education_ru: education_ru || "",
    education_de: education_de || "",
    education_uk: education_uk || "",

    // achievement
    achievement_en: achievement_en || "",
    achievement_de: achievement_de || "",
    achievement_ar: achievement_ar || "",
    achievement_ru: achievement_ru || "",
    achievement_uk: achievement_uk || "",

    // membership
    membership_en: membership_en || "",
    membership_de: membership_de || "",
    membership_ar: membership_ar || "",
    membership_ru: membership_ru || "",
    membership_uk: membership_uk || "",

    // expertise
    expertise_en: expertise_en || "",
    expertise_de: expertise_de || "",
    expertise_ar: expertise_ar || "",
    expertise_ru: expertise_ru || "",
    expertise_uk: expertise_uk || "",

    serviceLanguageIds: [],
  };

  const validationSchema = Yup.object().shape({
    // name
    firstName_en: Yup.string().required("First Name (English) is required"),
    firstName_ar: Yup.string().notRequired(),
    firstName_ru: Yup.string().notRequired(),
    firstName_de: Yup.string().notRequired(),
    firstName_uk: Yup.string().notRequired(),


    lastName_en: Yup.string().required("Last Name (English) is required"),
    lastName_ar: Yup.string().notRequired(),
    lastName_ru: Yup.string().notRequired(),
    lastName_de: Yup.string().notRequired(),
    lastName_uk: Yup.string().notRequired(),

    experiance: Yup.number().required("experiance is required"),

    // adddress
    address_en: Yup.string().required("Address (English) is required"),
    address_ar: Yup.string().notRequired(),
    address_ru: Yup.string().notRequired(),
    address_de: Yup.string().notRequired(),
    address_uk: Yup.string().notRequired(),

    zipCode: Yup.string().required("Zipcode is required").min(5),

    // education
    education_en: Yup.string().notRequired(),
    education_ar: Yup.string().notRequired(),
    education_ru: Yup.string().notRequired(),
    education_de: Yup.string().notRequired(),
    education_uk: Yup.string().notRequired(),


    // achievement
    achievement_en: Yup.string().notRequired(),
    achievement_ar: Yup.string().notRequired(),
    achievement_ru: Yup.string().notRequired(),
    achievement_de: Yup.string().notRequired(),
    achievement_uk: Yup.string().notRequired(),


    // membership
    membership_en: Yup.string().notRequired(),
    membership_de: Yup.string().notRequired(),
    membership_ar: Yup.string().notRequired(),
    membership_ru: Yup.string().notRequired(),
    membership_uk: Yup.string().notRequired(),


    // expertise
    expertise_en: Yup.string().notRequired(),
    expertise_de: Yup.string().notRequired(),
    expertise_ar: Yup.string().notRequired(),
    expertise_ru: Yup.string().notRequired(),
    expertise_uk: Yup.string().notRequired(),

    phone: Yup.string().notRequired(),
    email: Yup.string().notRequired().email(),
    isFeatured: Yup.boolean().notRequired(),
    isActive: Yup.boolean().notRequired(),
    countryId: Yup.string().required("Country is required"),
    cityId: Yup.string().required("City is required"),

    // OverView
    overview_en: Yup.string().required("Overview (English) is required"),
    overview_de: Yup.string(),
    overview_ar: Yup.string(),
    overview_ru: Yup.string(),
    overview_uk: Yup.string(),


    facebook: Yup.string().notRequired(),
    whatsapp: Yup.string().notRequired(),

    serviceLanguageIds: Yup.array().notRequired(),
    specialityIds: Yup.array().notRequired(),
    faqIds: Yup.array().notRequired(),
  });

  const ImageUpload = async (fileList) => {
    const oldImages = fileList
      .filter((file) => file?.url)
      .map(({ url }) => url);
    const NewImages = fileList.filter((file) => file?.originFileObj);

    if (NewImages?.length) {
      const Images = await dispatch(uploadActions.upload(NewImages));
      if (!Images?.length) {
        notification.success({ description: "Image Upload Failed" });
        return;
      }
      return [...Images, ...oldImages];
    }
    return oldImages;
  };

  const onSubmit = async (values) => {
    dispatch(actions.loading(isEditing ? { update: true } : { create: true }));

    const serviceLanguageIds = values.serviceLanguageIds?.map(
      ({ value: id }) => id
    );
    const specialityIds = values.specialityIds?.map(({ value: id }) => id);
    const faqIds = values.faqIds?.map(({ value: id }) => id);

    const Images = await ImageUpload(values.image);

    const payload = {
      ...values,
      faqIds,
      specialityIds,
      serviceLanguageIds,
      image: Images[0] || "",
    };
    const action = isEditing
      ? actions.update(id, payload, setDrawer, resetForm, pagination)
      : actions.create(payload, setDrawer, resetForm, pagination);
    return dispatch(action);
  };

  const formik = useFormik({ initialValues, validationSchema, onSubmit });

  const items = [
    {
      key: "1",
      label: "Basic Info",
      children: <BasicInfo formik={formik} view={view} />,
    },
    {
      key: "2",
      label: "Education",
      children: <Education formik={formik} view={view} />,
    },
    {
      key: "5",
      label: "Awards & Achievement",
      children: <Achievement formik={formik} view={view} />,
    },
    {
      key: "6",
      label: "Professional Membership",
      children: <Membership formik={formik} view={view} />,
    },
    {
      key: "7",
      label: "A Glimpse into Expertise",
      children: <Expertise formik={formik} view={view} />,
    },
    {
      key: "8",
      label: "FAQS",
      children: <FAQs formik={formik} view={view} recordId={recordId} />,
    },
  ];

  return (
    <>
      <Drawer
        afterOpenChange={onDrawerChange}
        title={title}
        placement={"right"}
        width={"85%"}
        onClose={closeDrawer}
        open={drawer}
        extra={
          <Space>
            {!view && (
              <>
                {!editRecord && (
                  <>
                    <button
                      disabled={isLoading}
                      onClick={resetForm}
                      className="btn-sm btn btn-light px-4 "
                      style={{ border: "1px solid grey" }}
                    >
                      Clear
                    </button>
                  </>
                )}
                <button
                  type="button"
                  disabled={isLoading}
                  onClick={formik.handleSubmit}
                  className={`btn-sm btn ${
                    isLoading ? "btn-light" : "btn-theme"
                  } px-4`}
                >
                  {isLoading ? <Spin /> : "save"}
                </button>
              </>
            )}
          </Space>
        }
      >
        <div className="drawer_main_div">
          <div className="bg-white  mx-3">
            <Tabs defaultActiveKey="1" items={items} />
          </div>
        </div>
      </Drawer>
    </>
  );
}

export default DoctorDrawer;
