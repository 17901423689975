import { Drawer, Space, Spin, Tabs, notification } from "antd";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import * as actions from "redux/facility/Actions";
import * as uploadActions from "redux/upload/Actions";
import BasicInfo from "./components/basic-info";
import FacilityDoctors from "./components/facility-doctors";
import FacilityDepartments from "./components/facility-departments";
import FacilitySpecilities from "./components/facility-Specilities";
import FAQs from "./components/faqs";
import Prices from "./components/prices";
import Services from "./components/services";
import WhyChooseUs from "./components/why-choose-us";

import * as requestFacilityRelations from "./../../../redux/facility/Crud"; //Achievements,media,slug with relation passing to facility api
import * as requestFacilityWhyChooseUs from "./../../../redux/common/why-choose-us/Crud";
import * as requestFacilityServicePrice from "./../../../redux/common/service-price/Crud";
import * as requestFacilityService from "./../../../redux/common/service/Crud";
import * as requestFacilityDoctor from "./../../../redux/doctor/Crud";
import * as requestFacilityFaq from "./../../../redux/common/faqs/Crud";
import * as requestFacilityDepartment from "./../../../redux/common/facility-department/Crud";
import * as requestFacilitySpeciality from "./../../../redux/common/facility-speciality/Crud";
import * as requestFacilityServiceLanguage from "./../../../redux/common/service-language/Crud";

import * as FacilitySpecialityActions from "redux/common/facility-speciality/Actions";
import { createQueryParams } from "utils/helpers/helper-functions";
// import { facilityRelations } from "./../../../services/relations";
import { format } from "date-fns";

function FacilityDrawer(props) {
  const {
    setDrawer,
    drawer,
    getPagination,
    view,
    setView,
    recordId,
    setRecordId,
  } = props;

  const [editRecord, setEditRecord] = useState(null);
  
  const [itemLoaded, setItemLoaded] = useState(false);

  const { loading } = useSelector(
    (state) => ({
      loading: state.facility?.loading,
    }),
    shallowEqual
  );
  /********************* DEFAUL || INITAL DATA **********************/
  const {
    name_en = "",
    name_ar = "",
    name_ru = "",
    name_de = "",
    name_uk = "",
    address_en = "",
    address_ar = "",
    address_ru = "",
    address_de = "",
    address_uk = "",
    lat = null,
    long = null,
    zipCode = 0,
    description_en = "",
    description_ar = "",
    description_ru = "",
    description_de = "",
    description_uk = "",
    descriptionMeta = "",
    email = "",
    phone = "",
    type = null,
    isFeatured = false,
    isActive = false,
    cityId = null,
    countryId = null,
    foundation,
    department,
    bed,
    doctor,
    yearly_patient,
    id = null,
    overview_en,
    overview_de,
    overview_ar,
    overview_ru,
    overview_uk,
  } = editRecord || {};
  const isEditing = !view;
  const isLoading = isEditing ? loading.update : loading.create;
  let title = "";
  if (view) title = "View";
  if (isEditing) title = "Add";
  if (isEditing && editRecord) title = "Edit";

  title = title + " Facility";

  const dispatch = useDispatch();
  const pagination = getPagination();

  const setEditDefaultValues = () => {
    const media = Array.isArray(editRecord?.media) ? 
    editRecord.media.map(({ url }, index) => ({
        uid: index + 1,
        name: "Name",
        status: "done",
        url,
    })) : []; 

const achievements = Array.isArray(editRecord?.achievements) ? 
editRecord?.achievements?.map(
    ({ achievement: url }, index) => ({
        uid: index + 1,
        name: "Name",
        status: "done",
        url,
  })) : [];


const whyChooseUsIds = Array.isArray(editRecord?.facilityWhyChooseUs) ? 
editRecord?.facilityWhyChooseUs?.map((whyChooseUs, index) => ({
        value: whyChooseUs?.id,
        label: whyChooseUs?.text_en || "",
        index,

  })) : [];

const doctorIds =  Array.isArray(editRecord?.facilityDoctor) ? 
editRecord?.facilityDoctor?.map((doctor, index) => ({
    value: doctor?.id,
    label:doctor?.firstName_en || "",
    index,
  })) : [];

const servicePriceIds = Array.isArray(editRecord?.facilityServicePrice) ? 
editRecord?.facilityServicePrice?.map(
    (servicePrice, index) => ({
        value: servicePrice?.id,
        label:  servicePrice?.name_en || "",
        index,
      })) : [];

const serviceIds = Array.isArray(editRecord?.facilityServicePrice) ? 
editRecord?.facilityService?.map((service, index) => ({
    value: service?.id,
    label: service?.name_en || "",
    index,
  })) : [];

const faqIds = Array.isArray(editRecord?.facilityFaq) ? 
editRecord?.facilityFaq?.map((faq, index) => ({
    value: faq?.id,
    label:  faq?.question_en || "",
    index,
  })) : [];
  
const departmentIds =Array.isArray(editRecord?.facilityDepartment) ? 
 editRecord?.facilityDepartment?.map(
    (department, index) => ({
        value: department?.id,
        label:  department?.name_en || "",
        index,
 
  })) : [];
const specialityIds =  Array.isArray(editRecord?.facilitySpeciality) ? 
editRecord?.facilitySpeciality?.map((speciality, index) => {
    return {
      value: speciality?.id,
      label: speciality?.name_en || "",
      index,
    };
  }) : [];

const serviceLanguageIds = Array.isArray(editRecord?.facilityServiceLanguage) ? 
editRecord?.facilityServiceLanguage?.map(
    (serviceLanguage, index) => ({
        value: serviceLanguage?.id,
        label: serviceLanguage?.name_en || "",
        index,

  })) : [];

    const facilityType = {
      value: type,
      label: type,
      index: 0,
    };

    formik.setValues({
      ...initialValues,
      mediaUrls: media,
      achievements,
      whyChooseUsIds,
      doctorIds,
      servicePriceIds,
      serviceIds,
      faqIds,
      departmentIds,
      specialityIds,
      serviceLanguageIds,
      type: facilityType,
    });
  };

  const resetForm = () => formik.resetForm();

  const closeDrawer = () => setDrawer(false);

  const onDrawerChange = (e) => {
    if (!e) {
      setView(false);
      setEditRecord(null);
      setRecordId(null);
      resetForm();
      dispatch(
        actions.loading({ update: false, create: false, remove: false })
      );
    }
  };

  

const getRecordDataAndSetValuesForViewingOrEditing = async (recordId) => {
  try {
    // Initialize responseData with empty arrays for all relations
    let responseData = {
      achievements: [],
      facilityWhyChooseUs: [],
      facilityServicePrice: [],
      facilityService: [],
      facilityDoctor: [],
      facilityFaq: [],
      facilityDepartment: [],
      facilitySpeciality: [],
      facilityServiceLanguage: []
    };

    // Create queries for all relations
    const queries = [
      requestFacilityRelations.getAllAchievements({ query: `where[facilityId]=${recordId}` }),
      requestFacilityWhyChooseUs.getAll({ query: `where[facilityWhyChooseUs][facilityId]=${recordId}` }),
      requestFacilityServicePrice.getAll({ query: `where[facilityServicePrice][facilityId]=${recordId}` }),
      requestFacilityService.getAll({ query: `where[facilityService][facilityId]=${recordId}` }),
      requestFacilityDoctor.getAll({ query: `where[facilityDoctor][facilityId]=${recordId}` }),
      requestFacilityFaq.getAll({ query: `where[facilityFaq][facilityId]=${recordId}` }),
      requestFacilityDepartment.getAll({ query: `where[facilityDepartment][facilityId]=${recordId}` }),
      requestFacilitySpeciality.getAll({ query: `where[facilitySpeciality][facilityId]=${recordId}` }),
      requestFacilityServiceLanguage.getAll({ query: `where[facilityServiceLanguage][facilityId]=${recordId}` })
    ];

    const responses = await Promise.all(queries);

    // Map response keys to their corresponding property in responseData
    const responseMap = {
      0: 'achievements',
      1: 'facilityWhyChooseUs',
      2: 'facilityServicePrice',
      3: 'facilityService',
      4: 'facilityDoctor',
      5: 'facilityFaq',
      6: 'facilityDepartment',
      7: 'facilitySpeciality',
      8: 'facilityServiceLanguage'
    };

    responses.forEach((response, index) => {
      if (response.status === 200 && response.data.records?.length) {
        const key = responseMap[index];
        if (key) {
          responseData[key] = responseData[key].concat(response.data.records);
        } else {
          console.warn(`No matching key found for index ${index}`);
        }
      } else {
        console.warn(`No data or bad status for index ${index}`);
      }
    });

      const query = createQueryParams(
          1,
          0,
          null,
          ["city","media"],
          `where[id]=${recordId}`
        );
        let response = await requestFacilityRelations.getAll({ query });
        if (response.status === 200 && response.data.records?.length) {
            responseData = { ...responseData, ...response.data.records[0] };
        }
      
    setEditRecord(responseData);
    setItemLoaded(true);
  } catch (error) {
    console.error('Error fetching record data:', error);
    notification.error({ description: 'Sorry! Something went wrong' });
  }
};



  useEffect(() => {
    setEditDefaultValues();
  }, 
  // eslint-disable-next-line
  [editRecord]);

  const getFacilitySpeciality = async (recordId) => {
    try {
        const facilitySpecialityQuery=`limit=1000000&offset=0&relations=speciality&where[facilityId]=${recordId}`
        dispatch(FacilitySpecialityActions.getAllFaciltiesSpecialities({ query: facilitySpecialityQuery }))
    } catch (error) {
        console.error("Error fetching record data:", error);
        notification.error({ description: "Sorry! Something went wrong" });
    }
  };
  
  useEffect(() => {
    // Only call getMasterData when the component mounts
    if (drawer && recordId) {
      getRecordDataAndSetValuesForViewingOrEditing(recordId);
      getFacilitySpeciality(recordId)
    }
  }, 
  // eslint-disable-next-line
  [drawer]);



  //formik Handler
  const initialValues = {
    id:id||null,
    name_en: name_en || "",
    name_ar: name_ar || "",
    name_ru: name_ru || "",
    name_de: name_de || "",
    name_uk: name_uk || "",


    address_en: address_en || "",
    address_ar: address_ar || "",
    address_ru: address_ru || "",
    address_de: address_de || "",
    address_uk: address_uk || "",

    description_en: description_en || "",
    description_ar: description_ar || "",
    description_ru: description_ru || "",
    description_de: description_de || "",
    description_uk: description_uk || "",

    descriptionMeta: descriptionMeta || "",

    email: email || "",
    phone: phone || "",
    lat: lat || "",
    long: long || "",
    zipCode: zipCode || "",
    type: type || "",
    isFeatured: isFeatured || false,
    isActive: isActive || false,
    cityId: cityId || "",
    countryId: countryId || "",
    mediaUrls: [],

    // About
    foundation: foundation || "1999",
    department: department || "0",
    bed: bed || "0",
    doctor: doctor || "0",
    yearly_patient: yearly_patient || "0",

    // OverView
    overview_en: overview_en || "",
    overview_de: overview_de || "",
    overview_ar: overview_ar || "",
    overview_ru: overview_ru || "",
    overview_uk: overview_uk || "",
  
    doctorIds: [],
    servicePriceIds: [],
    serviceIds: [],
    faqIds: [],
    whyChooseUsIds: [],
    departmentIds: [],
    serviceLanguageIds: [],
    specialityIds: [],
    achievements: [],
  };

  const validationSchema = Yup.object().shape({
    name_en: Yup.string().required("First Name (English) is required"),
    name_ar: Yup.string().notRequired(),
    name_ru: Yup.string().notRequired(),
    name_de: Yup.string().notRequired(),
    name_uk: Yup.string().notRequired(),


    address_en: Yup.string().required("Address (English) is required"),
    address_ar: Yup.string().notRequired(),
    address_ru: Yup.string().notRequired(),
    address_de: Yup.string().notRequired(),
    address_uk: Yup.string().notRequired(),

    lat: Yup.string().notRequired("Latitude is required"),
    long: Yup.string().notRequired("Longitude is required"),
    zipCode: Yup.string().notRequired(),
    countryId: Yup.string().notRequired(),
    cityId: Yup.string().required("City is required"),

    isFeatured: Yup.boolean().notRequired(),
    isActive: Yup.boolean().notRequired(),

    phone: Yup.string().notRequired(),
    email: Yup.string().notRequired(),
    type: Yup.object().required("Facility Type is required"),

    mediaUrls: Yup.array().notRequired(),
    achievements: Yup.array().notRequired(),

    // About
    foundation: Yup.number()
      .typeError("Must be a number")
      .min(1100)
      .max(format(new Date(), "yyyy"))
      .required("Year of Foundation is required"),
    department: Yup.number()
      .typeError("Must be a number")
      .required("No. of Departments is required"),
    bed: Yup.number()
      .typeError("Must be a number")
      .required("No. of Beds is required"),
    doctor: Yup.number()
      .typeError("Must be a number")
      .required("No. of  Doctors Patient is required"),
    yearly_patient: Yup.number()
      .typeError("Must be a number")
      .required("No. of  Yearly Patients is required"),

    // OverView
    overview_en: Yup.string().required("Overview (English) is required"),
    overview_de: Yup.string(),
    overview_ar: Yup.string(),
    overview_ru: Yup.string(),
    overview_uk: Yup.string(),

    doctorIds: Yup.array().notRequired(),
    servicePriceIds: Yup.array().notRequired(),
    serviceIds: Yup.array().notRequired(),
    faqIds: Yup.array().notRequired(),
    whyChooseUsIds: Yup.array().notRequired(),
    serviceLanguageIds: Yup.array().notRequired(),
    departmentIds: Yup.array().notRequired(),
    specialityIds: Yup.array().notRequired(),
  });

  const ImageUpload = async (fileList) => {
    const oldImages = fileList
      .filter((file) => file?.url)
      .map(({ url }) => url);
    const NewImages = fileList.filter((file) => file?.originFileObj);

    if (NewImages?.length) {
      const Images = await dispatch(uploadActions.upload(NewImages));
      if (!Images?.length) {
        notification.success({ description: "Image Upload Failed" });
        return;
      }
      return [...Images, ...oldImages];
    }
    return oldImages;
  };

  const onSubmit = async (values) => {
    const serviceIds = values.serviceIds?.map(({ value: id }) => id);
    const serviceLanguageIds = values.serviceLanguageIds?.map(
      ({ value: id }) => id
    );
    const servicePriceIds = values.servicePriceIds?.map(({ value: id }) => id);
    const specialityIds = values.specialityIds?.map(({ value: id }) => id);
    const whyChooseUsIds = values.whyChooseUsIds?.map(({ value: id }) => id);
    const doctorIds = values.doctorIds?.map(({ value: id }) => id);
    const faqIds = values.faqIds?.map(({ value: id }) => id);
    const departmentIds = values.departmentIds?.map(({ value: id }) => id);

    const [mediaUrls, achievements] = await Promise.all([
      ImageUpload(values.mediaUrls),
      ImageUpload(values.achievements),
    ]);

    const payload = {
      ...values,
      specialityIds,
      doctorIds,
      servicePriceIds,
      whyChooseUsIds,
      faqIds,
      serviceLanguageIds,
      serviceIds,
      departmentIds,
      type: values.type.value,
      mediaUrls,
      achievements,
    };

    const action = recordId
      ? actions.update(id, payload, setDrawer, resetForm, pagination)
      : actions.create(payload, setDrawer, resetForm, pagination);
    return dispatch(action);
  };

  const formik = useFormik({ initialValues, validationSchema, onSubmit });

  const items = [
    {
      key: "1",
      label: "Basic Info",
      children: <BasicInfo {...{ formik, view }} />,
    },
    {
      key: "2",
      label: "Why Choose Us",
      children: <WhyChooseUs {...{ formik, view, recordId }} />,
    },
    {
      key: "5",
      label: " Doctors",
      children: <FacilityDoctors {...{ formik, view }} />,
    },
    {
      key: "6",
      label: "Prices",
      children: <Prices {...{ formik, view }} />,
    },
    {
      key: "7",
      label: "Services",
      children: <Services {...{ formik, view }} />,
    },
    {
      key: "8",
      label: "FAQS",
      children: <FAQs {...{ formik, view , recordId}} />,
    },
    {
      key: "9",
      label: "Departments",
      children: <FacilityDepartments {...{ formik, view }} />,
    },
    {
      key: "10",
      label: "Specilities",
      children: <FacilitySpecilities {...{ formik, view }} />,
    },
  ];

  return (
    <>
      <Drawer
        afterOpenChange={onDrawerChange}
        title={title}
        placement={"right"}
        width={"85%"}
        onClose={closeDrawer}
        open={drawer}
        extra={
          <Space>
            {!view && (
              <>
                {!editRecord && (
                  <>
                    <button
                      disabled={isLoading}
                      onClick={resetForm}
                      className="btn-sm btn btn-light px-4 "
                      style={{ border: "1px solid grey" }}
                    >
                      Clear
                    </button>
                  </>
                )}
                <button
                  type="button"
                  disabled={isLoading}
                  onClick={formik.handleSubmit}
                  className={`btn-sm btn ${
                    isLoading ? "btn-light" : "btn-theme"
                  } px-4`}
                >
                  {isLoading ? <Spin /> : "save"}
                </button>
              </>
            )}
          </Space>
        }
      >
        <div className="drawer_main_div">
          {itemLoaded===false && title==='Edit Facility' ?
        
        <div className="row h-100 d-flex jusitfy-content-center align-items-center">
              <Spin />
        </div>
        :
            <div className="bg-white mx-3">
              <Tabs defaultActiveKey="1" items={items} />
            </div>
}
        </div>
      </Drawer>
    </>
  );
}

export default FacilityDrawer;
