import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { authSlice } from "./Auth/authslice";
import { blogSlice } from "./blog/Reducer";
import { citySlice } from "./city/Reducer";
import { countrySlice } from "./country/Reducer";
import { doctorSlice } from "./doctor/Reducer";
import { facilityDepartmentSlice } from "./common/facility-department/Reducer";
import { facilitySlice } from "./facility/Reducer";
import { faqsSlice } from "./common/faqs/Reducer";
import { requestSlice } from "./request/Reducer";
import { serviceLanguageSlice } from "./common/service-language/Reducer";
import { servicePriceSlice } from "./common/service-price/Reducer";
import { serviceSlice } from "./common/service/Reducer";
import { specialitySlice } from "./specialities/Reducer";
import { statisticsSlice } from "./statistics/Reducer";
import { treatmentSlice } from "./treatment/Reducer";
import { uploadSlice } from "./upload/Reducer";
import { userSlice } from "./user/Reducer";
import { whyChooseUsSlice } from "./common/why-choose-us/Reducer";
import { facilitySpecialitySlice } from "./common/facility-speciality/Reducer";
import { settingsSlice } from "./settings/Reducer";
import { DiseaseSlice } from "./diseases/Reducer";
import { testimonialsSlice } from "./testimonials/Reducer";
import { contentSlice } from "./content/Reducer";



const authConfig = { key: "auth", storage };

export const rootReducer = combineReducers({
  auth: persistReducer(authConfig, authSlice.reducer),
  country: countrySlice.reducer,
  city: citySlice.reducer,
  treatment: treatmentSlice.reducer,
  speciality: specialitySlice.reducer,
  doctor: doctorSlice.reducer,
  statistics: statisticsSlice.reducer,
  blog: blogSlice.reducer,
  user: userSlice.reducer,
  facility: facilitySlice.reducer,
  request: requestSlice.reducer,
  upload: uploadSlice.reducer,
  serviceLanguage: serviceLanguageSlice.reducer,
  service: serviceSlice.reducer,
  faqs: faqsSlice.reducer,
  servicePrice: servicePriceSlice.reducer,
  whyChooseUs: whyChooseUsSlice.reducer,
  facilityDepartment: facilityDepartmentSlice.reducer,
  facilitySpeciality: facilitySpecialitySlice.reducer,
  settings: settingsSlice.reducer,
  disease: DiseaseSlice.reducer,
  testimonials:testimonialsSlice.reducer,
  content:contentSlice.reducer,




});
