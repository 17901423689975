import * as requestFromServer from "./Crud";
// import { uploadSlice } from "./Reducer";
// const { actions } = uploadSlice;

// export const upload = (data) => (dispatch) => {
//   dispatch(actions.setLoading({ getAll: true }));
//   return requestFromServer
//     .upload(data)
//     .then((response) => {
//       upload || response.data===>", response.data);
//       dispatch(actions.upload({ records: response.data }));
//       return response;
//     })
//     .catch((error) => {})
//     .finally(() => {
//       dispatch(actions.setLoading({ getAll: false }));
//     });
// };

export const upload = (file) => async (dispatch) => {
  if (!file?.length) return;

  const formData = new FormData();
  file.forEach((file) => formData.append("files", file.originFileObj || file));

  try {
    const response = await requestFromServer.upload(formData);
    return response.data;
  } catch (error) {
    return null;
  }
};
