import React from "react";
import { Outlet } from "react-router-dom";
import Header from "../../components/header/Header";
import Sidebar from "../../components/sidebar/Sidebar";
import "./dashboard.style.css";
const Dashboard = () => {
  return (
    <>
      <div className="" style={{ maxHeight: "120vh", background: "#e3e3ec" }}>
        <Header />
        <Sidebar />
        <main id="content " role="main" className="main dashboard_content_main">
          <Outlet />
        </main>
      </div>
    </>
  );
};

export default Dashboard;
