import ErrorMessage from "components/error-message/ErrorMessage";
import { LanguageSegment } from "components/language-segment/LanguageSegment";
import RichEditor from "components/rich-text-Editor/RichEditor";

function Education({ formik, view }) {
  const { Segment, language } = LanguageSegment();

  return (
    <>
      <div className="language-segmented my-2 drawer_area_main_div mx-3 px-4">
        {Segment}
      </div>
      <div className="drawer_area_main_div mx-3 my-2 px-3">
        <div className=" ">
          {/* Overview  */}
          {language === "en" && (
            <>
              {" "}
              <div className="my-3">
                <span className="drawer_span">
                  Education
                  <span className="form-label-secondary"> (English)</span>
                </span>
                <RichEditor
                  disabled={view}
                  className="input_error"
                  value={formik.values.education_en}
                  formik={formik}
                  setValue={(value) =>
                    formik.setFieldValue("education_en", value)
                  }
                />
                {formik.errors.education_en && formik.touched.education_en && (
                  <ErrorMessage message={formik.errors.education_en} />
                )}
              </div>
            </>
          )}
          {/* Overview  */}
          {language === "ar" && (
            <>
              {" "}
              <div className="my-3">
                <span className="drawer_span">
                  Education{""}
                  <span className="form-label-secondary"> (Arabic)</span>
                </span>
                <RichEditor
                  disabled={view}
                  value={formik.values.education_ar}
                  formik={formik}
                  setValue={(value) =>
                    formik.setFieldValue("education_ar", value)
                  }
                />
              </div>
            </>
          )}
          {/* Overview  */}
          {language === "de" && (
            <>
              {" "}
              <div className="my-3">
                <span className="drawer_span">
                  Education
                  <span className="form-label-secondary"> (German)</span>
                </span>
                <RichEditor
                  disabled={view}
                  value={formik.values.education_de}
                  formik={formik}
                  setValue={(value) =>
                    formik.setFieldValue("education_de", value)
                  }
                />
              </div>
            </>
          )}
          {/* Overview  */}
          {language === "ru" && (
            <>
              {" "}
              <div className="my-3">
                <span className="drawer_span">
                  Education
                  <span className="form-label-secondary"> (Russian)</span>
                </span>
                <RichEditor
                  disabled={view}
                  value={formik.values.education_ru}
                  formik={formik}
                  setValue={(value) =>
                    formik.setFieldValue("education_ru", value)
                  }
                />
              </div>
            </>
          )}
           {/* Overview  */}
           {language === "uk" && (
            <>
              {" "}
              <div className="my-3">
                <span className="drawer_span">
                  Education
                  <span className="form-label-secondary"> (Ukrainian)</span>
                </span>
                <RichEditor
                  disabled={view}
                  value={formik.values.education_uk}
                  formik={formik}
                  setValue={(value) =>
                    formik.setFieldValue("education_uk", value)
                  }
                />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default Education;
