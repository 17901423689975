import React from 'react';
import NoDataIcon from "components/animation-icons/no-data-icon";
import { shallowEqual, useSelector } from "react-redux";
import { DoctorSelect } from "./common/doctor.select";
import defaultDoctor from "assets/images/avatar.svg";

function FacilityDoctors({ formik, view }) {
  const { doctor } = useSelector(
    (state) => ({ doctor: state.doctor?.records }),
    shallowEqual
  );

  return (
    <>
      <div className="drawer_area_main_div mx-3 px-3">
        <span className="pb-2">Doctors</span>
        <DoctorSelect
          name="doctorIds"
          value={formik.values.doctorIds || []}
          onChange={(e) => formik.setFieldValue("doctorIds", e)}
        />
      </div>
      {formik.values.doctorIds?.length ? (
        <>
          <div className="row row-cols-1 row-cols-sm-2 row-cols-xl-3  mx-1 my-3 ">
            {formik.values.doctorIds.map(({ value }, index) => {
              const doctor_data = doctor.find(doc => doc.id === value);
              if (!doctor_data) return null;

              return (
                <div key={index} className="col mb-3 mb-lg-5">
                  <div className="card h-100">
                    <div className="card-pinned">
                      <div className="card-pinned-top-end">
                        <div className="dropdown">
                          <button
                            disabled={view}
                            type="button"
                            className="btn btn-ghost-secondary btn-icon btn-sm rounded-circle"
                            id={`connectionsDropdown${index}`}
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <i className="bi-three-dots-vertical"></i>
                          </button>

                          <div
                            className="dropdown-menu dropdown-menu-sm dropdown-menu-end"
                            aria-labelledby={`connectionsDropdown${index}`}
                          >
                            <div
                              className="dropdown-item text-danger"
                              onClick={() =>
                                formik.setFieldValue(
                                  "doctorIds",
                                  formik.values.doctorIds.filter(
                                    (item, idx) => idx !== index
                                  )
                                )
                              }
                            >
                              Delete
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="card-body text-center">
                      <div className="avatar avatar-xl avatar-soft-primary avatar-circle avatar-centered mb-3">
                        <img
                          className="avatar-img"
                          src={
                            doctor_data?.image?.trim() !== ""
                              ? doctor_data?.image
                              : defaultDoctor
                          }
                          alt={`${doctor_data.firstName_en} ${doctor_data.lastName_en}`}
                        />
                        <span
                          className={
                            "avatar-status avatar-sm-status " +
                            `${
                              doctor_data.isActive
                                ? "avatar-status-success"
                                : "avatar-status-danger"
                            }`
                          }
                        ></span>
                      </div>

                      <h3 className="mb-1">
                        <span className="text-dark cursor-pointer">
                          {doctor_data.firstName_en} {doctor_data.lastName_en}
                        </span>
                      </h3>

                      <div className="mb-1">
                        <i className="bi-geo-alt-fill me-1"></i>
                        <span>
                          {doctor_data.city?.name_en},{" "}
                          {doctor_data.city?.country?.name_en}
                        </span>
                      </div>
                      <div className="mb-3">
                        <i className=" me-1"></i>
                        <span>Experience: {doctor_data.experience}</span>
                      </div>

                      <ul className="list-inline mb-0">
                        {doctor_data?.doctorSpeciality?.map(
                          (doctorSpeciality, idx) => (
                            <li key={idx} className="list-inline-item mt-1">
                              <span className="badge bg-soft-secondary text-secondary p-2">
                                {doctorSpeciality.speciality.name_en}
                              </span>
                            </li>
                          )
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </>
      ) : (
        <>
          <div className="px-4 drawer_area_main_div mx-3 my-2">
            <NoDataIcon />
          </div>
        </>
      )}
    </>
  );
}

export default FacilityDoctors;

