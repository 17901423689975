import axios from "axios";
import { ApiUrl } from "services/api";

const { Testimonials: Api } = ApiUrl;

export const getAll = async ({ query }) =>
  axios[Api.getAll.method](Api.getAll.url(query));

export const create = async (data) =>
  axios[Api.create.method](Api.create.url(), data);

export const remove = async (id) =>
  axios[Api.remove.method](Api.remove.url(id));

export const update = async (id, data) =>
  axios[Api.update.method](Api.update.url(id), data);

export const getUploadUrl = async (query ) =>
  axios[Api.getUploadUrl.method](Api.getUploadUrl.url(query));

export const getVideoUrl = async (query ) =>
  axios[Api.getVideoUrl.method](Api.getVideoUrl.url(query));


export const UploadVideoToS3 = async (s3Url,file ) => {
  try {
    const uploadResponse = await fetch(Api.UploadVideoToS3.url(s3Url), {
      method: "PUT",
      body: file,
      headers: {
        "Content-Type": file.type,
      },
    });

    if (uploadResponse.ok) {
    } else {
      throw new Error("Failed to upload video");
    }
  } catch (error) {
    console.error("Error uploading video:", error);
  }
};

