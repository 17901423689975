import React from "react";
// import NoDataIconImage from "../../assets/images/no-data.svg";

function NoDataIcon() {
  return (
    <div className="text-center">
      {/* <img src={NoDataIconImage} /> */}
      No Data Found...
    </div>
  );
}

export default NoDataIcon;
