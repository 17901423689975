import { Drawer, Image, Space, Spin, Upload, notification } from "antd";
import CountrySelect from "components/country-select/CountrySelect";
import ErrorMessage from "components/error-message/ErrorMessage";
import { LanguageSegment } from "components/language-segment/LanguageSegment";
import RichEditor from "components/rich-text-Editor/RichEditor";

import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "redux/country/Actions";
import * as uploadActions from "redux/upload/Actions";
import { FALLBACK_IMAGE_URL } from "utils/constants/contants";
import { getBase64 } from "utils/helpers/helper-functions";
import * as Yup from "yup";

function CountryDrawer(props) {
  const { edit, setEdit, view, setView, getPagination, drawer, setDrawer } =
    props;

  const record = edit || view;
  const { Segment, language } = LanguageSegment();

  const { loading } = useSelector(
    (state) => ({
      loading: state.country?.loading,
    }),
    shallowEqual
  );
  const {
    name_en = "",
    name_ar = "",
    name_de = "",
    name_ru = "",
    name_uk = "",

    overview_en = "",
    overview_ar = "",
    overview_de = "",
    overview_ru = "",
    overview_uk = "",

    code = null,
    image = null,
    id = null,
    isFeatured = false,
    isActive=false
  } = record || {};

  const isEditing = Boolean(record);
  const isLoading = edit ? loading.update : loading.create;

  const title = `${view ? " View " : edit ? " Edit " : " Add "} Country`;
  const dispatch = useDispatch();
  const pagination = getPagination();
  const [uploadedImage, setImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(edit ? image : "");
  

  const initialValues = {
    name_en: name_en || "",
    name_ar: name_ar || "",
    name_ru: name_ru || "",
    name_de: name_de || "",
    name_uk: name_uk || "",

    overview_en: overview_en || "",
    overview_ar: overview_ar || "",
    overview_ru: overview_ru || "",
    overview_de: overview_de || "",
    overview_uk: overview_uk || "",

    code: code || "",
    image: image || "",
    isFeatured: isFeatured || false,
    isActive: isActive || false,

  };

  const setEditDefaultValues = () => {
    formik.setValues({ ...initialValues });
    setPreviewImage(image || "");
  };

  const onChange = async (e) => {
    const file = e.file;
    setImage(e.file);
    file.preview = await getBase64(file.originFileObj);
    setPreviewImage(file.url || file.preview);
    formik.setFieldValue("image", file.url || file.preview);
  };

  const removeImage = () => {
    setImage(null);
    setPreviewImage(null);
    formik.setFieldValue("image", "");
  };

  const resetForm = () => {
    formik.resetForm();
    setPreviewImage("");
    setImage(null);
  };

  const closeDrawer = () => setDrawer(false);

  const onDrawerChange = (e) => {
    if (!e) {
      setView(null);
      setEdit(null);
      resetForm();
      dispatch(
        actions.loading({ update: false, create: false, remove: false })
      );
    }
  };

  useEffect(() => {
    drawer && isEditing && setEditDefaultValues();
  }, 
  // eslint-disable-next-line
  [drawer]);

  const validationSchema = Yup.object().shape({
    name_en: Yup.string().required("Name (English) is required"),
    name_ar: Yup.string().notRequired(),
    name_ru: Yup.string().notRequired(),
    name_de: Yup.string().notRequired(),
    name_uk: Yup.string().notRequired(),

    isFeatured: Yup.boolean().notRequired(),
    isActive: Yup.boolean().notRequired(),

    code: Yup.string().notRequired(),
    image: Yup.string().notRequired(),
  });

  const onSubmit = async (values) => {
    dispatch(actions.loading(edit ? { update: true } : { create: true }));

    if (uploadedImage) {
      const Images = await dispatch(uploadActions.upload([uploadedImage]));
      if (!Images?.length) {
        notification.success({ description: "Image Upload Failed" });
        dispatch(actions.loading(edit ? { update: false } : { create: false }));
        return;
      }
      values.image = Images[0] || "";
    }

    const action = edit
      ? actions.update(id, values, setDrawer, resetForm, pagination)
      : actions.create(values, setDrawer, resetForm, pagination);
    return dispatch(action);
  };

  const formik = useFormik({ initialValues, validationSchema, onSubmit });

  return (
    <>
      <Drawer
        afterOpenChange={onDrawerChange}
        title={title}
        placement={"right"}
        width={"70%"}
        onClose={closeDrawer}
        open={drawer}
        extra={
          <Space>
            {!view && (
              <>
                {!edit && (
                  <>
                    <button
                      disabled={isLoading}
                      onClick={resetForm}
                      className="btn-sm btn btn-light px-4 "
                      style={{ border: "1px solid grey" }}
                    >
                      Clear
                    </button>
                  </>
                )}
                <button
                  type="button"
                  disabled={isLoading}
                  onClick={formik.handleSubmit}
                  className={`btn-sm btn ${
                    isLoading ? "btn-light" : "btn-theme"
                  } px-4`}
                >
                  {isLoading ? <Spin /> : "save"}
                </button>
              </>
            )}
          </Space>
        }
      >
        <div
          className="language-segmented   px-3  mb-2 "
          style={{
            padding: "20px 0",
            margin: 0,
            background: "#fff",
            border: "1px solid #dbdbdb",
            boxShadow: "0px 4px 31px rgba(0, 0, 0, 0.14)",
            borderRadius: "10px",
          }}
        >
          {Segment}
        </div>

        <div className="drawer_main_div ">
          <h2 className=" text-center ">{title}</h2>
          <div className="container-fluid">
            <div className="row">
              <form onSubmit={formik.handleSubmit}>
                {/* /********** INPUT ********/}
                {language === "en" && (
                  <>
                    <div className="my-2">
                      <span className="drawer_span required">
                        Name{" "}
                        <span className="form-label-secondary">(English)</span>
                      </span>
                      <CountrySelect
                        isDisabled={edit || view ? true : false}
                        values={{
                          value: formik.values.code || "us",
                          label: (
                            <div>
                              {" "}
                              <img
                                className="avatar avatar-xss avatar-circle me-2"
                                src={`assets_modules/flag-icon-css/flags/1x1/${
                                  formik?.values?.code?.toLowerCase() || "us"
                                }.svg`}
                                alt=" Flag loading..."
                              />
                              {formik.values.name_en || "United States"}
                            </div>
                          ),
                        }}
                        defaultNameRedux={edit?.name_en || ""}
                        handleBlur={formik.handleBlur}
                        defaultValueRedux={edit?.code || ""}
                        setCountry={(e) => {
                          formik.setFieldValue('name_en', e?.label?.props?.children[1] || '');
                          formik.setFieldValue('code', e?.label?.props?.children[0]?.props?.alt || '');
                        }}
                        className={
                          "w-100" +
                          ` ${
                            formik.errors.name_en && formik.touched.name_en
                              ? "input_error"
                              : ""
                          }`
                        }
                        style={{
                          border: "0.0625rem solid rgba(231, 234, 243, 0.7)",
                        }}
                      />
                      {formik.errors.name_en && formik.touched.name_en && (
                        <ErrorMessage message={formik.errors.name_en} />
                      )}
                    </div>
                  </>
                )}
                {/* /********** INPUT ********/}
                {language === "ar" && (
                  <>
                    <div className="my-2">
                      <span className="drawer_span">
                        Name{" "}
                        <span className="form-label-secondary">(Arabic)</span>
                      </span>
                      <input
                        disabled={view}
                        type="text"
                        name="name_ar"
                        className={`form-control  ${
                          formik.errors.name_ar && formik.touched.name_ar
                            ? "input_error"
                            : ""
                        }`}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.name_ar}
                        placeholder="Enter country name in (Arabic)"
                      />
                      {formik.errors.name_ar && formik.touched.name_ar && (
                        <ErrorMessage message={formik.errors.name_ar} />
                      )}
                    </div>
                  </>
                )}
                {/* /********** INPUT ********/}
                {language === "ru" && (
                  <>
                    <div className="my-2">
                      <span className="drawer_span">
                        Name{" "}
                        <span className="form-label-secondary">(Russian)</span>
                      </span>
                      <input
                        disabled={view}
                        type="text"
                        name="name_ru"
                        className={`form-control drawer_input ${
                          formik.errors.name_ru && formik.touched.name_ru
                            ? "input_error"
                            : ""
                        }`}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.name_ru}
                        placeholder="Enter country name in (Russian)"
                      />
                      {formik.errors.name_ru && formik.touched.name_ru && (
                        <ErrorMessage message={formik.errors.name_ru} />
                      )}
                    </div>
                  </>
                )}
                {/* /********** INPUT ********/}
                {language === "de" && (
                  <>
                    <div className="my-2">
                      <span className="drawer_span">
                        Name{" "}
                        <span className="form-label-secondary">(German)</span>
                      </span>
                      <input
                        disabled={view}
                        type="text"
                        name="name_de"
                        className={`form-control drawer_input ${
                          formik.errors.name_de && formik.touched.name_de
                            ? "input_error"
                            : ""
                        }`}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.name_de}
                        placeholder="Enter country name in (German)"
                      />
                      {formik.errors.name_de && formik.touched.name_de && (
                        <ErrorMessage message={formik.errors.name_de} />
                      )}
                    </div>
                  </>
                )}
                 {/* /********** INPUT ********/}
                 {language === "uk" && (
                  <>
                    <div className="my-2">
                      <span className="drawer_span">
                        Name{" "}
                        <span className="form-label-secondary">(Ukrainian)</span>
                      </span>
                      <input
                        disabled={view}
                        type="text"
                        name="name_uk"
                        className={`form-control drawer_input ${
                          formik.errors.name_uk && formik.touched.name_uk
                            ? "input_error"
                            : ""
                        }`}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.name_uk}
                        placeholder="Enter country name in (Ukrainian)"
                      />
                      {formik.errors.name_uk && formik.touched.name_uk && (
                        <ErrorMessage message={formik.errors.name_uk} />
                      )}
                    </div>
                  </>
                )}
                {/* /********** Featured ********/}
                <div className="d-flex">

                  <div className="col-md-6 col-sm-12 form-check form-switch my-2">
                  <input
                    disabled={view}
                    name="isFeatured"
                    type="checkbox"
                    role="switch"
                    checked={formik.values.isFeatured}
                    className={` drawer_input form-check-input drawer_input ${
                      formik.errors.isFeatured && formik.touched.isFeatured
                        ? "input_error"
                        : ""
                    }`}
                    onChange={(e) => {
                      formik.setFieldValue("isFeatured", e.target.checked);
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.isFeatured}
                  />
                  <span className="">
                    Is Featured{" "}
                    <span className="form-label-secondary">
                      (Display on navbar ?)
                    </span>
                  </span>
                  </div>
                  {formik.errors.isFeatured && formik.touched.isFeatured && (
                    <ErrorMessage message={formik.errors.isFeatured} />
                  )}
                    {/* /********** INPUT ********/}
                    <div className="col-md-6 col-sm-12 form-check form-switch my-2">
                    <input
                      disabled={view}
                      name="isActive"
                      type="checkbox"
                      role="switch"
                      checked={formik.values.isActive}
                      className={` drawer_input form-check-input drawer_input ${
                        formik.errors.isActive && formik.touched.isActive
                          ? "input_error"
                          : ""
                      }`}
                      onChange={(e) => {
                        formik.setFieldValue("isActive", e.target.checked);
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.isActive}
                    />
                    <span className="drawer_span_no-margin">
                    Active?
                    </span>
                  </div>
                  {formik.errors.isActive && formik.touched.isActive && (
                    <ErrorMessage message={formik.errors.isActive} />
                  )}
                </div>

                
                {/* /********** Over View ********/}
                {language === "en" && (
                  <>
                    {" "}
                    <div className="my-3">
                      <span className="drawer_span">
                        Overview
                        <span className="form-label-secondary"> (English)</span>
                      </span>
                      <RichEditor
                        disabled={view}
                        name="overview_en"
                        value={formik.values?.overview_en || ""}
                        formik={formik}
                        setValue={(value) =>
                          formik.setFieldValue("overview_en", value)
                        }
                      />
                    </div>
                  </>
                )}
                {language === "ar" && (
                  <>
                    {" "}
                    <div className="my-3">
                      <span className="drawer_span">
                        Overview
                        <span className="form-label-secondary"> (Arabic)</span>
                      </span>
                      <RichEditor
                        name="overview_ar"
                        disabled={view}
                        value={formik.values.overview_ar}
                        formik={formik}
                        setValue={(value) =>
                          formik.setFieldValue("overview_ar", value)
                        }
                      />
                    </div>
                  </>
                )}
                {language === "ru" && (
                  <>
                    {" "}
                    <div className="my-3">
                      <span className="drawer_span">
                        Overview
                        <span className="form-label-secondary"> (Russian)</span>
                      </span>
                      <RichEditor
                        name="overview_ru"
                        disabled={view}
                        value={formik.values.overview_ru}
                        formik={formik}
                        setValue={(value) =>
                          formik.setFieldValue("overview_ru", value)
                        }
                      />
                    </div>
                  </>
                )}
                {language === "de" && (
                  <>
                    {" "}
                    <div className="my-3">
                      <span className="drawer_span">
                        Overview
                        <span className="form-label-secondary"> (German)</span>
                      </span>
                      <RichEditor
                        name="overview_de"
                        disabled={view}
                        value={formik.values.overview_de}
                        formik={formik}
                        setValue={(value) =>
                          formik.setFieldValue("overview_de", value)
                        }
                      />
                    </div>
                  </>
                )}
                 {language === "uk" && (
                  <>
                    {" "}
                    <div className="my-3">
                      <span className="drawer_span">
                        Overview
                        <span className="form-label-secondary"> (Ukrainian)</span>
                      </span>
                      <RichEditor
                        name="overview_uk"
                        disabled={view}
                        value={formik.values.overview_uk}
                        formik={formik}
                        setValue={(value) =>
                          formik.setFieldValue("overview_uk", value)
                        }
                      />
                    </div>
                  </>
                )}
                {/* /********** Image ********/}
                <div className=" m-auto my-4 text-center w-50">
                  <Image
                    className="drawer_input"
                    preview={previewImage ? true : false}
                    width={150}
                    height={150}
                    src={previewImage || FALLBACK_IMAGE_URL}
                    fallback={FALLBACK_IMAGE_URL}
                  />

                  <div className="mb-4 mt-2 text-center ">
                    {!view && (
                      <>
                        {" "}
                        <Upload
                          showUploadList={false}
                          maxCount={1}
                          disabled={isLoading || view}
                          onChange={onChange}
                        >
                          <button
                            type="button"
                            disabled={isLoading || view}
                            className="btn-theme btn py-2 px-4"
                          >
                            Upload
                          </button>
                        </Upload>
                        <button
                          type="button"
                          style={{
                            background: previewImage ? "#f30301" : "grey",
                          }}
                          className="btn py-2 px-4 mx-2 text-white "
                          disabled={isLoading || view || !previewImage}
                          onClick={removeImage}
                        >
                          Delete
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Drawer>
    </>
  );
}

export default CountryDrawer;
