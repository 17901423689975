import { Column } from "@ant-design/plots";
import { Spin } from "antd";
import React from "react";
import "./dashboard.chart.style.css";

const DashboardChart = ({ statistics, loading = false }) => {
  const DemoColumn = () => {
    const data = [
      {
        type: "Countries",
        Rate: parseInt(statistics?.country || 0),
      },
      {
        type: "Cities",
        Rate: parseInt(statistics?.city || 0),
      },
      // {
      //   type: "Search",
      //   Rate: parseInt(statistics?.search || 0),
      // },
      {
        type: "Treatments",
        Rate: parseInt(statistics?.treatment || 0),
      },
      {
        type: "specialities",
        Rate: parseInt(statistics?.speciality || 0),
      },
      {
        type: "Doctors",
        Rate: parseInt(statistics?.doctor || 0),
      },
      {
        type: "Facilities",
        Rate: parseInt(statistics?.facility || 0),
      },
      {
        type: "Requests",
        Rate: parseInt(statistics?.request || 0),
      },
      {
        type: "Users",
        Rate: parseInt(statistics?.user || 0),
      },
      {
        type: "Blogs",
        Rate: parseInt(statistics?.blog || 0),
      },
    ];
    const config = {
      data,
      xField: "type",
      yField: "Rate",
      lineWidth: 20,
      columnStyle: {
        radius: [20, 20, 0, 0],
      },

      yAxis: {
        label: {
          formatter: (props) => {
            return props > 1000000
              ? props / 1000000 + "M"
              : props > 1000
              ? props / 1000 + "k"
              : props;
          },
          style: {
            fontWeight: "500",
            fontSize: 13,
            width: "25px",
            fontFamily: "Red Hat Display",
          },
        },
        grid: {
          line: false,
        },
      },
      xAxis: {
        label: {
          style: {
            fontWeight: "500",
            fontSize: 13,
            fontFamily: "Red Hat Display",
          },
        },
      },
      columnBackground: {
        style: {
          radius: [20, 20, 0, 0],
          fill: "#BDC5D1",
        },
      },
      minColumnWidth: 10,
      maxColumnWidth: 10,
    };
    return <Column {...config} />;
  };

  return (
    <>
      <div className="row m-auto">
        <div className={` col-12 m-auto`}>
          <div className={` me-0 `} style={{ height: "280px" }}>
            <div className="row ">
              <div
                className={`col-10 offset-1   mt-4`}
                style={{ height: "236px" }}
              >
                {loading ? (
                  <>
                    <Spin
                      size="large"
                      style={{
                        position: "relative",
                        top: "50%",
                        left: "50%",
                      }}
                    />
                  </>
                ) : (
                  <DemoColumn />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardChart;
