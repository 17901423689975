import { initializeApp } from "firebase/app";
import {
  createUserWithEmailAndPassword,
  getAuth,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import { firebaseConfig } from "./firebase-config";

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export const firebaseSignUp = async (email, password) => {
  try {
    const credential = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );
    await sendEmailVerification(getAuth().currentUser);
    return credential.user;
  } catch (error) {
    return error;
  }
};
export const ResetPassword = async (email) => {
  var actionCodeSettings = {
    url: `${process.env.REACT_APP_BASE_URL}dashboard`,
    handleCodeInApp: true,
  };
  const res = await sendPasswordResetEmail(auth, email, actionCodeSettings);
  return res;
};
export const firebaseLogin = async (email, password) => {
  const res = await signInWithEmailAndPassword(auth, email, password);
  return res.user;
};
export const checkUserisverifed = async (email, password) => {
  const res = await signInWithEmailAndPassword(auth, email, password)
    .then((authUser) => {
      return authUser.user;
    })
    .catch(function (error) {
      return error;
    });
  return res;
};
export const firebaseLogout = () => {
  return signOut(auth)
    .then(() => {
      // Sign-out successful.
    })
    .catch((error) => {
      // An error happened.
    });
};

// const UpdateFireBaseToken = async () => {
//   return await firebase.auth.currentUser.getIdToken().then(function (data) {
//     return data;
//   });
// };

export const getIdToken = async () => {
  return new Promise((resolve, reject) => {
    auth.onAuthStateChanged(function (user) {
      if (user) {
        user
          .getIdToken()
          .then(function (idToken) {
            resolve(idToken);
          })
          .catch(function (error) {
            resolve("");
          });
      } else {
        resolve("");
      }
    });
  });
};
