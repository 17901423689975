import { Pagination, Table } from "antd";
import { useCallback, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  DeleteButton,
  EditButton,
  ViewButton,
} from "components/button/Buttons";
import { DeleteConfirmationDialog } from "components/delete-confirmation-dialog/DeleteConfirmationDialog";
import * as actions from "redux/facility/Actions";
import * as cityyAction from "redux/city/Actions";
import * as facilityDepartmentActions from "redux/common/facility-department/Actions";
import * as FacilitySpecialityActions from "redux/common/facility-speciality/Actions";
import * as faqsActions from "redux/common/faqs/Actions";
import * as serviceLanguageActions from "redux/common/service-language/Actions";
import * as servicePriceActions from "redux/common/service-price/Actions";
import * as serviceActions from "redux/common/service/Actions";
// import * as whyChooseUsActions from "redux/common/why-choose-us/Actions";
import * as countryAction from "redux/country/Actions";
import * as doctorActions from "redux/doctor/Actions";
import * as specialitiesActions from "redux/specialities/Actions";
import * as treatmentActions from "redux/treatment/Actions";

function FacilityTable(props) {
  const {
    setView,
    setDrawer,
    deleteRecord,
    setDeleteRecord,
    pageSize,
    setPageSize,
    pageNumber,
    setPageNumber,
    searchText,
    setSearchText,
    getPagination,
    recordId,
    setRecordId,
  } = props;

  const dispatch = useDispatch();
  const pagination = getPagination();

  const { records, count, loading } = useSelector(
    (state) => ({
      records: state.facility?.records || [],
      count: state.facility?.count || 0,
      loading: state.facility?.loading,
    }),
    shallowEqual
  );

  const fetchFacilities = useCallback(() => {
    dispatch(actions.getAll(pagination));
  }, 
  // eslint-disable-next-line
  [pageSize, pageNumber, searchText, dispatch]);

  useEffect(() => {
    fetchFacilities();
  }, 
  // eslint-disable-next-line
  [dispatch, pageSize, pageNumber, searchText]);

  const handleDelete = () => {
    dispatch(actions.remove(deleteRecord?.id, setDeleteRecord, pagination));
  };
  const columns = [
    {
      title: (
        <>
          Name <span className="form-label-secondary">(English)</span>
        </>
      ),
      render: (name_en, entity) => {
        return (
          <>
            <div className="ms-3">
              <span className="d-block h5 text-inherit mb-0">
                {entity.name_en}
              </span>
            </div>
          </>
        );
      },
    },

    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Is Active",
      dataIndex: "name_ru",
      key: "name_ru",
      render: (name_en, entity) => {
        return (
          <>
            <div
              className={
                entity.isActive
                  ? "badge bg-soft-success text-success"
                  : "badge bg-soft-danger text-danger"
              }
            >
              <span
                className={
                  entity.isActive
                    ? "legend-indicator bg-success"
                    : "legend-indicator bg-danger"
                }
              ></span>
              {entity.isActive ? "Active" : "InActive"}
            </div>{" "}
          </>
        );
      },
    },
    {
      title: "Is Featured",
      dataIndex: "isFeatured",
      key: "isFeatured",
      render: (name_en, entity) => {
        return (
          <>
            <div
              className={
                entity.isFeatured
                  ? "badge bg-soft-success text-success"
                  : "badge bg-soft-danger text-danger"
              }
            >
              <span
                className={
                  entity.isFeatured
                    ? "legend-indicator bg-success"
                    : "legend-indicator bg-danger"
                }
              ></span>
              {entity.isFeatured ? "Featured" : "Not Featured"}
            </div>{" "}
          </>
        );
      },
    },
    {
      title: "Actions",
      dataIndex: "name_de",
      key: "name_de",
      width: "150px",
      render: (name_en, entity) => {
        return (
          <>
            <div className="d-flex mx-auto ">
              <span className="nav-link">
                <ViewButton
                  onClick={() => {
                    setView(true);
                    setDrawer(true);
                    setRecordId(entity.id);
                  }}
                />
              </span>

              <span className="nav-link mx-1">
                <EditButton
                  onClick={() => {
                    setDrawer(true);
                    setRecordId(entity.id);
                  }}
                />
              </span>
              <span className="nav-link ">
                <DeleteButton onClick={() => setDeleteRecord(entity)} />
              </span>
            </div>
          </>
        );
      },
    },
  ];

  const paginationHandler = (current, pageSize) => {
    setPageSize(pageSize);
    setPageNumber(current);
  };

  useEffect(() => {
    const getMasterData = () => {
      const query = `limit=1000000&offset=0`;
      const doctorQuery = `limit=1000000&offset=0&relations[]=city&relations[]=city.country&relations[]=doctorSpeciality&relations[]=doctorSpeciality.speciality`;
      const facilityDepartmentQuery = `limit=1000000&offset=0`;
      const countryQuery = `limit=1000000&offset=0&relations[]=city`;
      const faqQuery = `limit=1000000&offset=0&where[facilityFaq][facilityId]=${recordId}`;

      Promise.all(
        recordId
          ? [dispatch(faqsActions.getAll({ query: faqQuery }))]
          : [
              dispatch(countryAction.getAll({ query: countryQuery })),
              dispatch(cityyAction.getAll({ query })),
              dispatch(specialitiesActions.getAll({ query })),
              dispatch(treatmentActions.getAll({ query })),
              // dispatch(whyChooseUsActions.getAll({ query })),
              dispatch(servicePriceActions.getAll({ query })),
              dispatch(serviceActions.getAll({ query })),
              dispatch(serviceLanguageActions.getAll({ query })),
              dispatch(doctorActions.getAll({ query: doctorQuery })),
              dispatch(facilityDepartmentActions.getAll({ query: facilityDepartmentQuery })),
            ]
      )
        .then(responses => {
          // Handle successful responses here
        })
        .catch(error => {
          // Handle errors here
          console.error('Error in Promise.all:', error);
        });
      
    };

    getMasterData();
  }, 
  // eslint-disable-next-line
  [recordId]);
  useEffect(() => {
    const getMasterData = () => {
      const facilitySpecialityQuery=`limit=1000000&offset=0&relations=speciality&where[facilityId]=${recordId}`

      Promise.all([
        dispatch(FacilitySpecialityActions.getAllFaciltiesSpecialities({ query: facilitySpecialityQuery })),
      ]);
    };

    recordId && getMasterData();
  }, 
  // eslint-disable-next-line
  [recordId]);

  return (
    <>
      <div className="pb-2 pt-3 table_main_div " style={{ height: "contain" }}>
        <div className="row " style={{ paddingRight: "50px" }}>
          <div className="d-grid d-sm-flex gap-3 justify-content-between">
            <div className=" mx-4 ">
              <h3 className="  pt-3">Total - {count}</h3>
            </div>
            <div
              className="input-group input-group-merge input-group-flush mr-4 ml-2 "
              style={{ width: "180px" }}
            >
              <div className="input-group-prepend input-group-text">
                <i className="bi-search"></i>
              </div>
              <input
                onChange={(e) => setSearchText(e.target.value)}
                id="datatableSearch"
                type="search"
                className="form-control"
                placeholder="Search "
                aria-label="Search "
              />
            </div>
            {/* <!-- End Search --> */}
          </div>
        </div>

        <div className="domain_history table_main py-3 px-2">
          <Table
            className="table_tag"
            size="small"
            loading={loading?.getAll || false}
            scroll={{ y: "calc(100vh - 420px)" }}
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  // navigate(`/countries/${record?.id}`);
                  // navigateTotrafficPage(record, event);
                },
              };
            }}
            pagination={false}
            dataSource={records}
            columns={columns}
          />
          <div
            className="d-flex mt-3 mr-5 justify-content-end"
            style={{ marginRight: "20px" }}
          >
            <Pagination
              showSizeChanger
              onChange={paginationHandler}
              onShowSizeChange={paginationHandler}
              pageSize={pageSize}
              defaultCurrent={1}
              total={count}
            />
          </div>
        </div>
      </div>

      <DeleteConfirmationDialog
        loading={loading?.remove}
        open={deleteRecord}
        onClose={setDeleteRecord}
        onDelete={handleDelete}
        title="Delete"
        content="Are you sure you want to delete it?"
        onCancelLabel="Cancel"
        onConfirmLabel="Delete"
      />
    </>
  );
}

export default FacilityTable;
