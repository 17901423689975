import { Pagination, Table } from "antd";
import { useCallback, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { DeleteConfirmationDialog } from "components/delete-confirmation-dialog/DeleteConfirmationDialog";
import * as actions from "redux/request/Actions";
import { HistoryReportViewButton,DeleteButtonResponse, DetailReportViewButton,EditReportViewButton } from "components/button/Buttons";

function RequestsTable(props) {
  const {
    deleteRecord,
    setDeleteRecord,
    pageSize,
    setPageSize,
    pageNumber,
    setPageNumber,
    searchText,
    setSearchText,
    getPagination,
    setInfo,
    setStatus,
    setDetailsModal,
    updateModal,
    setUpdateModal,
  } = props;

  const dispatch = useDispatch();
  const pagination = getPagination();
  const user = useSelector((state) => state.auth.user);

  const { records, count, loading } = useSelector(
    (state) => ({
      records: state.request?.records || [],
      count: state.request?.count || 0,
      loading: state.request?.loading,
    }),
    shallowEqual
  );

  const fetch = useCallback(() => {
    dispatch(actions.getAll(pagination));
  }, 
  // eslint-disable-next-line
  [pageSize, pageNumber, searchText, dispatch]);

  useEffect(() => {
    fetch();
  }, 
  // eslint-disable-next-line
  [dispatch, pageSize, pageNumber, searchText,updateModal]);

  const handleDelete = () => {
    dispatch(actions.remove(deleteRecord?.id, setDeleteRecord, pagination));
  };
  const truncateMessage = (message, charLimit) => {
    if (message.length <= charLimit) {
      return message;
    }
    return message.slice(0, charLimit) + '...';
  };
  const columns = [

    {
      title: "User",
      render: (_, entity) => {
        return (
          <>
            <div className="d-flex cursor-pointer"  onClick={() => setInfo(entity)}>
              <img
                src={entity.media.length > 0 ? entity.media : `https://dummyimage.com/40x40/007bff/fff&text=${entity.firstName.charAt(0).toUpperCase()}`}
                alt="Profile"
                style={{
                  borderRadius: "50%",
                  backgroundColor: "blue",
                  marginRight: "5%"
                }}
              />
              <div className="align-self-center">
                {" "}
                <span className="">
                  {entity.firstName} {entity.lastName && entity.lastName !== "null" ? entity.lastName : ''}
                </span>
              </div>
              <div>

              </div>
            </div>
          </>
        );
      },
    },
    {
      title: "Assigned To",
      render: (_, entity) => {
        return (
          <>
          <span>{entity?.user?.email ? entity.user.email : "Not Assigned"}</span>
          </>
        );
      },
    },
    {
      title: "Status",
      render: (_, entity) => {
        return (
          <span>  {entity.status}  </span>
        );
      },
    },
    {
      title: "Reason",
      render: (_, entity) => {
        return (
          <>
          <span>{truncateMessage(entity.message, 20)}</span>
          </>
        );
      },
    },
    {
      title: "Date & Time",
      render: (_, entity) => {
        return (
          <>
            <span>{new Date(entity?.createdAt).toLocaleString()}</span>
          </>
        );
      },
    },
    {
      title: "Actions",
      dataIndex: "name_de",
      key: "name_de",
      width: "170px",
      render: (name_en, entity) => {
        return (
          <>
            <div className="flex align-self-center">
            {user.role === 'admin' && (
              <>
            <DeleteButtonResponse onClick={() => setDeleteRecord(entity)} title="Delete" />
            </>
          )}
            <EditReportViewButton  onClick={() => setUpdateModal(entity)}  props={props} title="Edit Status & Reason" />
            <HistoryReportViewButton  onClick={() => setStatus(entity)} info={entity} title="Logs"  />
            <DetailReportViewButton   onClick={() => setDetailsModal(entity)} title="Detail Report" />
            </div>

          </>
        );
      },
    },

  ];

  const paginationHandler = (current, pageSize) => {
    setPageSize(pageSize);
    setPageNumber(current);
  };

  return (
    <>
      <div
        className="pb-2 pt-3 table_main_div request-main-div"
        style={{ height: "contain" }}
      >
        <div className="row " style={{ paddingRight: "50px" }}>
          <div className="d-grid d-sm-flex gap-3 justify-content-between">
            <div className=" mx-4 ">
              <h3 className="  pt-3">Total - {count}</h3>
            </div>
            <div
              className="input-group input-group-merge input-group-flush mr-4 ml-2 "
              style={{ width: "180px" }}
            >
              <div className="input-group-prepend input-group-text">
                <i className="bi-search"></i>
              </div>
              <input
                onChange={(e) => setSearchText(e.target.value)}
                id="datatableSearch"
                type="search"
                className="form-control"
                placeholder="Search "
                aria-label="Search "
              />
            </div>
            {/* <!-- End Search --> */}
          </div>
        </div>

        <div className="domain_history table_main py-3 px-2">
          <Table
            className="table_tag"
            size="small"
            loading={loading?.getAll || false}
            scroll={{ y: "calc(100vh - 420px)" }}
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                },
              };
            }}
            pagination={false}
            dataSource={records}
            columns={columns}
          />
          <div
            className="d-flex mt-3 mr-5 justify-content-end"
            style={{ marginRight: "20px" }}
          >
            <Pagination
              showSizeChanger
              onChange={paginationHandler}
              onShowSizeChange={paginationHandler}
              pageSize={pageSize}
              defaultCurrent={1}
              total={count}
            />
          </div>
        </div>
      </div>

      <DeleteConfirmationDialog
        loading={loading?.remove}
        open={deleteRecord}
        onClose={setDeleteRecord}
        onDelete={handleDelete}
        title="Delete"
        content="Are you sure you want to delete it?"
        onCancelLabel="Cancel"
        onConfirmLabel="Delete"
      />
    </>
  );
}

export default RequestsTable;
