import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  firebaseToken: false,
  userPlan: false,
  userId: false,
  expandArea: false,
  ispremium: false,
  profileImage: "",
  s3UrlExpiry: "",
  user: null,
};
export const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    setFirebasetoken: (state, action) => {
      state.firebaseToken = action.payload.firebaseToken;
    },
    login: (state, action) => {
      state.firebaseToken = action.payload.firebaseToken;
      state.user = action.payload.user;
      state.userId = action.payload.user.id;
    },
    updateUser: (state, action) => {
      state.user = action.payload.user;
    },
    setS3UrlExpiry: (state, action) => {
      state.s3UrlExpiry = action.payload.s3UrlExpiry;
    },
    setUserPlan: (state, action) => {
      state.userPlan = action.payload.userPlan;
    },
    setUserId: (state, action) => {
      state.userId = action.payload.userId;
    },
    setExpanArea: (state, action) => {
      state.expandArea = action.payload.expandArea;
    },
    setIspremium: (state, action) => {
      state.ispremium = action.payload.ispremium;
    },
    setProfileImage: (state, action) => {
      state.profileImage = action.payload.profileImage;
    },
    updateUserData: (state, action) => {
      state.userPlan = { ...state.userPlan, ...action.payload.userPlan };
    },
    logout: (state, action) => {
      state.firebaseToken = false;
      state.userPlan = false;
      state.profileImage = "";
      state.userId = false;
      state.ispremium = false;
      state.user = null;
    },
  },
});
