import { useState } from "react";
import { useSelector } from 'react-redux';
import { createQueryParams } from "utils/helpers/helper-functions";
import RequestsDrawer from "./request.drawer";
import RequestsTable from "./request.table";
import { requestRelations as relations } from "services/relations";
import { Modal } from "antd";
import RequestInfoModal from "./request-info.modal";
import RequestStatusModal from "./request-status.modal";
import RequestDetailModal from "./request-detail.modal"
import RequestUpdateModal from "./request-update.modal";

function Requests() {
  const [drawer, setDrawer] = useState(false);
  const [editRecord, setEditRecord] = useState(null);
  const [deleteRecord, setDeleteRecord] = useState(null);
  const [view, setView] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [info, setInfo] = useState(null);
  const [status, setStatus] = useState(null);
  const [detailsModal, setDetailsModal] = useState(null);
  const [updateModal, setUpdateModal] = useState(null);
  const userId = useSelector((state) => state.auth.userId);

  
  const getPagination = () => {
    const limit = pageSize;
    let offset = (pageNumber - 1) * pageSize;
    offset = offset > 1 ? offset : 0;
    const text = searchText.trim() || null;
    const where=`userId=${userId}`
    const query = createQueryParams(limit, offset, text, relations,where);
    return { limit, offset, text, relations, query };
  };

  const commonProps = {
    view,
    setView,
    pageSize,
    setPageSize,
    pageNumber,
    setPageNumber,
    searchText,
    setSearchText,
    getPagination,
    drawer,
    setDrawer,
    deleteRecord,
    setDeleteRecord,
    editRecord,
    setEditRecord,
    info,
    setInfo,
    status,
    setStatus,
    detailsModal,
    setDetailsModal,
    updateModal,
    setUpdateModal,
  };

  return (
    <div>
      <div className="py-4 mt-3 table_main_div d-flex justify-content-between">
        <h1 className="mx-5 mt-2">Request</h1>
      
        <button className="btn mx-5 btn-theme" onClick={() => setDrawer(true)}>
          Add New Request
        </button>
        
      </div>

      <RequestsTable {...commonProps} />
      <RequestsDrawer {...commonProps} />

      {/* Info Modal */}
      <Modal
        width={"80%"}
        open={info}
        onOk={() => setInfo(false)}
        onCancel={() => setInfo(false)}
        footer={""}
      >
        <RequestInfoModal {...commonProps} />
      </Modal>
      {/* Message Modal */}
      <Modal
        width={"60%"}
        open={status}
        onOk={() => setStatus(false)}
        onCancel={() => setStatus(false)}
        footer={""}
      >
        <RequestStatusModal {...commonProps} />
      </Modal>

        {/* Request Detail Modal */}
        <Modal
        width={"60%"}
        open={detailsModal}
        onOk={() => setDetailsModal(false)}
        onCancel={() => setDetailsModal(false)}
        footer={""}
      >
        <RequestDetailModal {...commonProps} />
        
      </Modal>

       {/* Request Update Drawer */}
        <RequestUpdateModal {...commonProps} />
     
    </div>
  );
}

export default Requests;
