import { Popover } from "antd";
import { shallowEqual, useSelector } from "react-redux";
import Select, { components } from "react-select";
import { DoctorCard } from "./doctor.card";

export const DoctorSelect = ({ name, value, onChange }) => {
  const { doctor } = useSelector(
    (state) => ({ doctor: state.doctor?.records }),
    shallowEqual
  );

  const doctors = doctor?.map((doc, index) => ({
    value: doc?.id,
    label: doc?.firstName_en + " " + doc?.lastName_en,
    index,
    component: <DoctorCard {...doc} />,
  }));

  const Option = (props) => {
    return (
      <Popover content={<>{props.data.component}</>}>
        <components.Option {...props} />{" "}
      </Popover>
    );
  };

  return (
    <>
      <Select
        components={{ Option }}
        isMulti
        name={name}
        value={value}
        onChange={onChange}
        options={doctors}
        className="drawer_input form-control w-100 basic-multi-select multiSelectBox"
        classNamePrefix="select"
      />
    </>
  );
};
