import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { logOutHandler } from "utils/helpers/helper-functions";
import user_profile_logo from "../../assets/images/user-profile-logo.svg";
import { authSlice } from "./../../redux/Auth/authslice";
import "./Header.style.css";

function Header() {
  const dispatch = useDispatch();
  const authAction = authSlice.actions;

  const { authState, user } = useSelector(
    (state) => ({ authState: state.auth, user: state.auth?.user }),
    shallowEqual
  );
  let navigate = useNavigate();
  // const breadcrumbNames = location.pathname.split("/").filter((value) => value);
  // .map((value) => ({ title: value, href: value }));

  // const logOut = async () => {
  //   try {
  //     localStorage.removeItem("token");
  //     dispatch(authAction.logout());
  //     navigate("/");
  //   } catch (error) {}
  // };
  return (
    <>
      {/* <!-- ========== HEADER START ========== --> */}
      <header
        id="header"
        className="navbar navbar-expand-lg navbar-fixed navbar-height navbar-container navbar-bordered bg-white"
      >
        <div className="navbar-nav-wrap">
          <div className={`navbar-nav-wrap-content-start`}>
            {/* <span className="navbar-back-domain-span  domain_heading_padding">
              {invoiceState.singleData[0]?.name}

              <Breadcrumb>
                {breadcrumbNames.map((value, index) => (
                  <Breadcrumb.Item key={index}>{value}</Breadcrumb.Item>
                ))}
              </Breadcrumb>
            </span> */}
          </div>

          {/* <!-- ========== END CONTAINER ========== --> */}
          <div className="navbar-nav-wrap-content-end">
            <ul className="navbar-nav">
              <li className="nav-item">
                <div className="dropdown">
                  <a
                    href="/"
                    className="navbar-dropdown-account-wrapper"
                    id="accountNavbarDropdown"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    data-bs-auto-close="outside"
                    data-bs-dropdown-animation
                  >
                    <div className="avatar avatar-sm avatar-circle">
                      <img
                        className="avatar-img"
                        src={user?.image && user.image !== " " ? user.image : user_profile_logo}
                        alt="loading"
                      />
                      <span className="avatar-status avatar-sm-status avatar-status-success"></span>
                    </div>
                  </a>
                  {/* <!-- ========== USER PROFILE - DROPDOWN ========== --> */}
                  <div
                    className="dropdown-menu dropdown-menu-end navbar-dropdown-menu navbar-dropdown-menu-borderless navbar-dropdown-account"
                    aria-labelledby="accountNavbarDropdown"
                    // style={{ width: "16rem" }}
                  >
                    <div className="dropdown-item-text">
                      <div className="d-flex align-items-center">
                        <div className="avatar avatar-sm avatar-circle">
                          <img
                            className="avatar-img"
                            src={user?.image && user.image !== " " ? user.image : user_profile_logo}
                            alt="loading"
                          />
                        </div>
                        <div className="flex-grow-1 ms-3">
                          <h5 className="mb-0 text_20_600">
                            {authState?.user?.name || authState.user?.userName}
                          </h5>
                          <p className="card-text text-body text_14_600">
                            {authState?.user?.email || ""}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="dropdown-divider"></div>
                    <Link className="dropdown-item" to="/profile">
                      {" "}
                      Profile & account{" "}
                    </Link>
                    <a
                     href="/"
                      className="dropdown-item"
                      onClick={() =>
                        logOutHandler(dispatch, authAction, navigate)
                      }
                    >
                      Logout
                    </a>
                  </div>
                </div>
                {/*<!-- End Account --> */}
              </li>
            </ul>
            {/*<!-- End Navbar --> */}
          </div>
        </div>
      </header>
      {/* <!-- ========== HEADER END ========== --> */}
    </>
  );
}

export default Header;
