import { Modal, Spin, Upload, notification } from "antd";
import uploadImageIcon from "assets/images/oc-browser.svg";
import { useFormik } from "formik";
import Papa from "papaparse";
import { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as cityAction from "redux/city/Actions";
import * as faqsActions from "redux/common/faqs/Actions";
import * as serviceLanguageActions from "redux/common/service-language/Actions";
import * as countryAction from "redux/country/Actions";
import * as actions from "redux/doctor/Actions";
import * as specialitiesActions from "redux/specialities/Actions";
import {
  JsonToCSV,
  downloadFile,
  sanitizeString,
  validateFileExtension,
} from "utils/helpers/helper-functions";
import * as Yup from "yup";
import DoctorBulk from "./components/doctor-bulk";

export const DoctorBulkModal = (props) => {
  const { setIsModalOpen, isModalOpen, getPagination } = props;
  const [file, setFile] = useState(null);
  const [fileData, setFileData] = useState(null);
  const dispatch = useDispatch();

  const {
    loading,
    serviceLanguagesRedux,
    specialitiesRedux,
    faqsRedux,
    countryRedux,
    cityRedux,
  } = useSelector(
    (state) => ({
      loading: state.doctor?.loading?.bulkUpload,
      serviceLanguagesRedux: state.serviceLanguage?.records,
      specialitiesRedux: state.speciality?.records,
      faqsRedux: state.faqs?.records,
      countryRedux: state.country?.records,
      cityRedux: state.city?.records,
    }),
    shallowEqual
  );

  const closeModal = () => {
    setFile(null);
    setFileData(null);
    formik.setFieldValue("doctors", []);
    setIsModalOpen(false);
  };

  const prop = {
    name: "file",
    multiple: false,
    action: "https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188",
    maxCount: 1,
    // showUploadList: false,
    onChange(info) {
      if (!info?.file) return;
      const { status } = info?.file;
      if (status !== "uploading") {
        setFile(info?.file);
        csvToJsonParser(info?.file);
      }
      if (status === "removed") {
        setFile(null);
      }
    },
    beforeUpload: (file) => validateFileExtension(file, ["csv"]),
  };

  const csvToJsonParser = (file) => {
    return Papa.parse(file?.originFileObj, {
      header: true,
      skipEmptyLines: true,
      complete: async ({ data = [] }) => {
        if (data?.length) {
          await relationalDataHandler(data);
          setFileData(data);
        }
      },
      error: (error) => console.error("Error parsing CSV:", error.message),
    });
  };

  const saveFileData = async () => {
    if (!fileData.length) return;
    const relationalData = await fetchRelationalData(fileData || []);
    const doctors = await fileData
      .map((entity) =>
        Object.fromEntries(
          Object.keys(mockDoctorObject).map((key) => [key, entity?.[key] || ""])
        )
      )
      .filter((p) => Object.values(p).some((v) => v?.trim() !== ""))
      .map((entity, index) => ({
        ...entity,
        email: entity?.email.trim() || "",
        firstName_en: entity?.firstName || "",
        lastName_en: entity?.lastName || "",
        address_en: entity?.address || "",
        overview_en: entity?.overview || "",
        serviceLanguageIds: [],
        specialityIds: [],
        faqIds: [],
        isActive: true,
        isFeatured: true,
        ...(relationalData[index] || {}),
      }));
    formik.setFieldValue("doctors", doctors);
  };

  const relationalDataHandler = async (data) => {
    if (!data?.length) return [];

    await Promise.all([
      addNewCountry(data),
      addNewServiceLang(data),
      addNewSpecialties(data),
      addNewFaqs(data),
      addNewCity(data),
    ]);
  };

  const fetchRelationalData = (data) => {
    return data.map((entity) => {
      const {
        serviceLanguages = "",
        specialities = "",
        faqQuestions = "",
        country = "",
        city = "",
      } = entity;
      // serviceLanguages
      const serviceLanguageIds = serviceLanguages
        ?.split(";")
        .map(
          (language) =>
            serviceLanguagesRedux.find(
              ({ name_en }) =>
                sanitizeString(name_en) === sanitizeString(language)
            )?.id
        )
        .filter(Boolean);

      // specialities
      const specialityIds = specialities
        ?.split(";")
        .map(
          (name) =>
            specialitiesRedux.find(
              ({ name_en }) => sanitizeString(name_en) === sanitizeString(name)
            )?.id
        )
        .filter(Boolean);

      // faqs
      const faqIds = faqQuestions
        ?.split(";")
        .filter((q) => q !== "")
        .map(
          (question) =>
            faqsRedux.find(
              ({ question_en }) =>
                sanitizeString(question_en) === sanitizeString(question)
            )?.id
        )
        .filter(Boolean);

      //country
      const countryId = countryRedux.find(
        ({ name_en }) => sanitizeString(name_en) === sanitizeString(country)
      )?.id;

      const cityId = cityRedux.find(
        ({ name_en }) => sanitizeString(name_en) === sanitizeString(city)
      )?.id;

      return { cityId, countryId, faqIds, serviceLanguageIds, specialityIds };
    });
  };
  // -------- saving data to Db ------------- //
  const addNewCity = (data) => {
    const city = [
      ...new Map(
        data?.map(({ city = "", country = "" }) => [
          sanitizeString(city),
          {
            name_en: sanitizeString(city),
            countryName: sanitizeString(country),
          },
        ])
      ).values(),
    ].filter(({ name_en }) => name_en);

    const newRecord = city?.filter(
      ({ name_en: name }) =>
        !cityRedux?.find(({ name_en }) => sanitizeString(name_en) === name)
    );

    const payload = newRecord
    // eslint-disable-next-line  
    .map(({ name_en, countryName }) => {
        const country = countryRedux.find(
          (country) =>
            sanitizeString(country?.name_en) === sanitizeString(countryName)
        );

        if (country) {
          return { name_en, countryId: country?.id };
        }
      })
      .filter(Boolean);
    payload.length && dispatch(cityAction.bulk(payload, ""));
  };

  const addNewCountry = (data) => {
    let countries = [
      ...new Set(
        data?.map(({ country }) => sanitizeString(country)).filter(Boolean)
      ),
    ];

    const newRecord = countries?.filter(
      (name) =>
        !countryRedux?.find(({ name_en }) => sanitizeString(name_en) === name)
    );

    if (newRecord.length) {
      const payload = newRecord.map((name_en) => ({ name_en, code: "" }));
      dispatch(countryAction.bulk(payload, ""));
    }
  };

  const addNewFaqs = (data) => {
    const faqQuestions = [
      ...new Set(
        data?.flatMap((item) => item.faqQuestions?.split(";")).filter(Boolean)
      ),
    ];
    const faqAnswers = [
      ...new Set(
        data?.flatMap((item) => item.faqAnswers?.split(";")).filter(Boolean)
      ),
    ];
    const faqs = [
      ...new Map(
        faqQuestions.map((question_en, index) => [
          sanitizeString(question_en),
          {
            question_en: sanitizeString(question_en),
            answer_en: faqAnswers[index] || "  ",
          },
        ])
      ).values(),
    ];

    const newRecord = faqs?.filter(
      ({ question_en: question }) =>
        !faqsRedux?.find(
          ({ question_en }) => question_en === sanitizeString(question)
        )
    );

    newRecord.length && dispatch(faqsActions.bulk(newRecord, ""));
  };

  const addNewSpecialties = (data) => {
    let specialities = [
      ...new Set(
        data?.flatMap((item) => item?.specialities?.split(";")).filter(Boolean)
      ),
    ].map((item) => sanitizeString(item));

    const newRecord = specialities?.filter(
      (name) =>
        !specialitiesRedux?.find(
          ({ name_en }) => sanitizeString(name_en) === name
        )
    );

    let newArray = removeDuplicates(newRecord);

    if (newArray.length) {
      const payload = newArray.map((name_en) => ({ name_en }));
      dispatch(specialitiesActions.bulk(payload, ""));
    }
  };

  function removeDuplicates(arr) {
    const uniqueNames = new Set();
    return arr.filter((obj) => {
      if (!uniqueNames.has(obj.name_en)) {
        uniqueNames.add(obj.name_en);
        return true;
      }
      return false;
    });
  }

  const addNewServiceLang = (data) => {
    let serviceLanguages = [
      ...new Set(
        data
          ?.flatMap((item) => item?.serviceLanguages?.split(";"))
          .filter(Boolean)
      ),
    ].map((language) => sanitizeString(language));

    const newRecord = serviceLanguages?.filter(
      (name) =>
        !serviceLanguagesRedux?.find(
          ({ name_en }) => sanitizeString(name_en) === name
        )
    );

    let newArray = removeDuplicates(newRecord);

    if (newArray.length) {
      const payload = newArray.map((name_en) => ({ name_en }));
      dispatch(serviceLanguageActions.bulk(payload, ""));
    }
  };

  const submit = () => {
    const payload = formik?.values?.doctors?.map((e) => ({
      ...e,
      membership_en: e.membership,
      achievement_en: e.achievement,
      expertise_en: e.expertise,
      education_en: e.education,
      experiance: +e?.experiance || 0,
    }));

    if (!payload.length) {
      return notification.error({ description: "No record to save" });
    }

    const pagination = getPagination();
    return dispatch(actions.bulk(payload, pagination, closeModal));
  };

  const download = (e) => {
    e.stopPropagation();
    const content = JsonToCSV([mockDoctorObject]);
    downloadFile("bulk-doctor.csv", content);
  };

  const formik = useFormik({
    initialValues: { doctors: [] },
    validationSchema: Yup.object({ doctors: Yup.array(validationSchema) }),
    onSubmit: () => {},
  });

  const getMasterData = () => {
    const query = `limit=1000000&offset=0`;
    const countryQuery = `limit=1000000&offset=0&relations[]=city`;

    Promise.all([
      dispatch(countryAction.getAll({ query: countryQuery })),
      dispatch(cityAction.getAll({ query: query })),
      dispatch(specialitiesActions.getAll({ query })),
      dispatch(faqsActions.getAll({ query })),
      dispatch(serviceLanguageActions.getAll({ query })),
    ]);
  };

  useEffect(() => {
    fileData?.length && saveFileData();
  },
  // eslint-disable-next-line
  [
    fileData,
    serviceLanguagesRedux,
    specialitiesRedux,
    faqsRedux,
    countryRedux,
    cityRedux,
  ]);

  useEffect(() => {
    isModalOpen && getMasterData();
  }, 
  // eslint-disable-next-line
  [isModalOpen]);

  return (
    <>
      <Modal
        width={"80%"}
        footer={""}
        title=" Bulk Upload"
        open={isModalOpen}
        onOk={closeModal}
        onCancel={closeModal}
      >
        <>
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              {file ? (
                <div>
                  <div
                    className="py-2 d-flex justify-content-between  align-items-center mt-2 mb-0 px-2"
                    style={{
                      background: "#fafafa",
                      borderRadius: "3px",
                      border: "1px dashed #d9d9d9",
                    }}
                  >
                    <div className="">{file && file?.originFileObj?.name}</div>
                    <div>
                      {" "}
                      <button
                        onClick={() => {
                          setFile(null);
                          formik.setFieldValue("doctors", []);
                        }}
                        className="btn btn-white text-danger btn-icon btn-sm show"
                      >
                        <i className="bi-trash "></i>
                      </button>
                    </div>
                  </div>
                  <>
                    <div className="mt-4">
                      <DoctorBulk formik={formik} />

                      <div className=" mt-3 text-center">
                        <button
                          disabled={loading}
                          onClick={closeModal}
                          type="button"
                          className="btn btn-white px-5"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        >
                          Cancel
                        </button>
                        <button
                          disabled={
                            !file || loading || formik?.errors?.doctors?.length
                          }
                          type="button"
                          className={`btn mx-2 px-5 ${
                            loading ? "btn-white" : "btn-theme"
                          }`}
                          onClick={submit}
                        >
                          {loading ? <Spin /> : "Submit"}
                        </button>
                      </div>
                    </div>
                  </>
                </div>
              ) : (
                <>
                  <Upload.Dragger {...prop}>
                    <>
                      <div className="dz-message">
                        <img
                          className="avatar avatar-xl avatar-4x3 mb-3"
                          src={uploadImageIcon}
                          alt="Description"
                          data-hs-theme-appearance="default"
                        />
                        <img
                          className="avatar avatar-xl avatar-4x3 mb-3"
                          src={uploadImageIcon}
                          alt="Description"
                          data-hs-theme-appearance="dark"
                        />

                        <h5>Drag and drop your file here</h5>

                        <p className="mb-2">or</p>

                        <span className="btn btn-white btn-sm">
                          Browse files
                        </span>

                        <div className="mt-2">
                          <span className="anchor-color cursor-pointer " onClick={download}>
                            Download Template
                          </span>
                        </div>
                      </div>
                    </>
                  </Upload.Dragger>
                </>
              )}
            </div>
          </div>
        </>
      </Modal>
    </>
  );
};

var mockDoctorObject = {
  firstName: "john",
  lastName: "doe",
  address: "123 main street",
  zipCode: "12345",
  country: "pakistan",
  city: "islamabad",
  experiance: "5",
  email: "john.doe@example.com",
  phone: "1234567890",
  facebook: "john.doe",
  whatsApp: "1234567890",
  image: "http://example.com/johndoe.jpg",
  education: "bachelor's in science",
  achievement: "award for excellence",
  membership: "professional association",
  expertise: "specialized field",
  overview: "a brief overview of the professional.",
  serviceLanguages: "English;sindhi;Urdu",
  specialities: "Oncology;Dermatology",
  faqQuestions: "Question 1;  Question 33",
  faqAnswers: "Answer 1;  Answer 2",
};

var validationSchema = Yup.object().shape({
  firstName: Yup.string().required("First Name (English) is required"),
  lastName: Yup.string().required("Last Name (English) is required"),
  address: Yup.string().required("Address (English) is required"),
  zipCode: Yup.string().notRequired(),
  education: Yup.string().notRequired(),
  achievement: Yup.string().notRequired(),
  membership: Yup.string().notRequired(),
  expertise: Yup.string().notRequired(),
  phone: Yup.string(),
  email: Yup.string().email(),
  countryId: Yup.string().notRequired(),
  cityId: Yup.string().required("City is required"),
  overview: Yup.string().notRequired(),
  facebook: Yup.string().notRequired(),
  whatsapp: Yup.string().notRequired(),
  serviceLanguageIds: Yup.array().notRequired(),
  specialityIds: Yup.array().notRequired(),
  faqIds: Yup.array().notRequired(),
});
