import React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { UserRole } from "utils/common/common";
import { logOutHandler } from "utils/helpers/helper-functions";
import blog_icon from "../../assets/images/blog_icon.svg";
import country_icon from "../../assets/images/country_sidebar_icon.svg";
import dashboard_icon from "../../assets/images/dashboard_icon.svg";
import doctor_icon from "../../assets/images/doctor_icon.svg";
import common_icon from "../../assets/images/history_icon_inactive.svg";
import hospital_icon from "../../assets/images/hospital_icon.svg";
import logout_icon from "../../assets/images/logout_icon.svg";
import profile_icon from "../../assets/images/profile_icon.svg";
import request_icon from "../../assets/images/request_icon.svg";
import specialities_icon from "../../assets/images/specialities_icon.svg";
import settings_icon from "../../assets/images/settings.svg";
import treatment_icon from "../../assets/images/treatment_icon.svg";
import users_icon from "../../assets/images/users_icon.svg";
import testimonials_icon from "../../assets/images/testimonials_icon.svg";
import content_icon from "../../assets/images/contentIcon.svg";


import { authSlice } from "../../redux/Auth/authslice";
import LOGO from "./../../assets/images/logo.svg";
import LOGO_TITLE_WHITE from "./../../assets/images/logo_title_white.svg";
import diseases_icon from "./../../assets/images/diseases.svg";
import "./Sidebar.style.css";

const Sidebar = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const location = useLocation();
  const { pathname = "" } = location;
  // let { id } = useParams();

  const authAction = authSlice.actions;

  const { authState } = useSelector(
    (state) => ({ authState: state.auth }),
    shallowEqual
  );

  let width = window.screen.width;
  const user = authState?.user;
  // console.log("authState", authState.user);
  // useEffect(() => {
  //   if (!authState.expandArea) {
  //     document.body.classList.remove("navbar-vertical-aside-mini-mode");
  //   } else {
  //     document.body.classList.add("navbar-vertical-aside-mini-mode");
  //   }
  // }, [dispatch]);

  const setexpandorCollapse = () => {
    if (!authState.expandArea) {
      dispatch(authAction.setExpanArea({ expandArea: true }));
      document.body.classList.add("navbar-vertical-aside-mini-mode");
    } else {
      dispatch(authAction.setExpanArea({ expandArea: false }));
      document.body.classList.remove("navbar-vertical-aside-mini-mode");
    }
  };
  const filterLinksByRole = (links, role) => {
    return links
      .filter(link => link?.roles?.includes(role))
      .map(link => ({
        ...link,
        subLinks: link?.subLinks ? link?.subLinks?.filter(subLink => subLink?.roles?.includes(role)) : []
      }));
  };
  const filteredLinks = filterLinksByRole(SideBarOptions, user?.role);
  

  // const logOutHandler = async () => {
  //   try {
  //     localStorage.removeItem("token");
  //     dispatch(authAction.logout());
  //     navigate("/login");
  //   } catch (error) {}
  // };
  return (
    <>
      <aside
        className={`${
          authState.expandArea && width < 760 && "margin_minus_240"
        } js-navbar-vertical-aside navbar navbar-vertical-aside navbar-vertical navbar-vertical-fixed navbar-expand-xl  navbar-dark bg-dark `}
      >
        <div className="navbar-vertical-container">
          <div className="navbar-vertical-footer-offset">
            {/****************** Header *******************/}
            <p className="navbar-brand">
              <img
                className="navbar-brand-logo"
                src={LOGO_TITLE_WHITE}
                alt="Logo"
                data-hs-theme-appearance="default"
              />
              <span className="navbar-brand-logo-text">
                {/* {PROJECT.NAME} */}
                {/* <span className="navbar-brand-logo-text_orange">CLINIC</span> */}
              </span>
              <img
                style={{ marginTop: "20px" }}
                className="navbar-brand-logo-mini"
                src={LOGO}
                alt="Logo"
                data-hs-theme-appearance="default"
              />
            </p>

            <button
              onClick={() => setexpandorCollapse()}
              type="button"
              className="js-navbar-vertical-aside-toggle-invoker navbar-aside-toggler opacity_1"
            >
              <i
                className="bi-arrow-bar-left navbar-toggler-short-align"
                data-bs-template='<div className="tooltip d-none d-md-block" role="tooltip"><div className="arrow"></div><div className="tooltip-inner"></div></div>'
                data-bs-toggle="tooltip"
                data-bs-placement="right"
                title="Collapse"
              ></i>
              <i
                className="bi-arrow-bar-right navbar-toggler-full-align"
                data-bs-template='<div className="tooltip d-none d-md-block" role="tooltip"><div className="arrow"></div><div className="tooltip-inner"></div></div>'
                data-bs-toggle="tooltip"
                data-bs-placement="right"
                title="Expand"
              ></i>
            </button>
            {/****************** Content *******************/}
            <div className="navbar-vertical-content"  >
              <div
                id="navbarVerticalMenu"
                className="nav nav-pills nav-vertical card-navbar-nav"
              >
                {/* {SideBarOptions?.map(
                  (entity,index) =>
                    entity?.roles?.some((r) => r === user?.role) && ( */}
                                  {filteredLinks?.map((entity, index) => (

                      <React.Fragment key={index}>
                      {entity?.subLinks?.length ? (
                          <SideBarBulkItems
                            {...entity}
                            pathname={pathname}
                            id={entity.label}
                           
                          />
                        ) : (
                          <SiderBarItem {...entity} pathname={pathname} />
                        )}
                 </React.Fragment>
                    )
                )}
              </div>
            </div>
            {/****************** Footer *******************/}
            <div className="navbar-vertical-content border-top_custom">
              <div
                id="navbarVerticalMenu"
                className="nav nav-pills nav-vertical card-navbar-nav pt-2"
              >
                <div className="nav-item ">
                  <p
                    className="nav-link "
                    data-placement="left"
                    onClick={() =>
                      logOutHandler(dispatch, authAction, navigate)
                    }
                  >
                    <img
                      className="width_height_25"
                      alt="loading..."
                      src={logout_icon}
                    />
                    <span className="nav-link-title padding_nav_title text_16_600">
                      Log Out
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </aside>
    </>
  );
};

export default Sidebar;

var SideBarBulkItems = ({
  path = "",
  icon = "",
  pathname = "",
  label = "",
  subLinks = [],
  id = "",
}) => (
  <div className="nav-item">
    {/* navbar item */}
    <a
  className="nav-link dropdown-toggle"
  href={`#navbarVerticalMenuPagesFacilitiesMenu${id}`}
  role="button"
  data-bs-toggle="collapse"
  data-bs-target={`#navbarVerticalMenuPagesFacilitiesMenu${id}`}
  aria-expanded="false"
  aria-controls={`navbarVerticalMenuPagesFacilitiesMenu${id}`}
>
      <div className="width_height_25">
        <img className="width_height_25" alt="loading..." src={icon} />
      </div>

      <span className="nav-link-title padding_nav_title text_16_600">
        {label}
      </span>
    </a>
    {/* block to display */}
    <div
  id={`navbarVerticalMenuPagesFacilitiesMenu${id}`}
  className="nav-collapse collapse "
      data-bs-parent="#navbarVerticalMenuPagesMenu"
      hs-parent-area="#navbarVerticalMenu"
    >
      {subLinks?.map(({ path = "", label }, index) => (
        <Link
          key={index}
          className={`${
            (pathname === path || pathname === `${path}/${id}`) &&
            "nav_link_active"
          } nav-link `}
          data-placement="left"
          to={path}
        >
          {label}
        </Link>
      ))}
    </div>
  </div>
);

var SiderBarItem = ({ path = "", icon = "", pathname = "", label = "" }) => (
  <div className="nav-item mt-2">
    <Link
      to={path}
      className={`${pathname === path && "nav_link_active"} nav-link `}
      data-placement="left"
    >
      <div className="width_height_25">
        <img alt="loading..." className="width_height_25" src={icon} />
      </div>
      <span className="nav-link-title padding_nav_title text_16_600">
        {label}
      </span>
    </Link>
  </div>
);

var SideBarOptions = [
  {
    label: "Dashboard",
    icon: dashboard_icon,
    path: "/dashboard",
    childrens: [],
    roles: [UserRole.Admin],
  },
  {
    label: "Countries",
    icon: country_icon,
    path: "/countries",
    childrens: [],
    roles: [UserRole.Admin],
  },
  {
    label: "Treatments",
    icon: treatment_icon,
    path: "/treatments",
    childrens: [],
    roles: [UserRole.Admin],
  },
  {
    label: "Disease",
    icon: diseases_icon,
    path: "/disease",
    childrens: [],
    roles: [UserRole.Admin],
  },
  {
    label: "Specialities",
    icon: specialities_icon,
    path: "/specialities",
    childrens: [],
    roles: [UserRole.Admin],
  },
  {
    label: "Doctors",
    icon: doctor_icon,
    path: "/doctors",
    childrens: [],
    roles: [UserRole.Admin],
  },
  {
    label: "Facilities",
    icon: hospital_icon,
    path: "/facilities",
    childrens: [],
    roles: [UserRole.Admin],
  },
  
  {
    label: "Request",
    icon: request_icon,
    path: "",
    roles: [UserRole.Admin, UserRole.Doctor, UserRole.CustomerSupport],
    subLinks: [
      { path: "/requests", label: "Request", roles: [UserRole.Admin, UserRole.Doctor, UserRole.CustomerSupport] },
      // { path: "/requests", label: "Request Details", roles: [UserRole.Admin] },
    ],
  },
  {
    label: "Users",
    icon: users_icon,
    path: "/users",
    childrens: [],
    roles: [UserRole.Admin],
  },
  {
    label: "Blogs",
    icon: blog_icon,
    path: "/blogs",
    childrens: [],
    roles: [UserRole.Admin],
  },
  {
    label: "Testimonials",
    icon: testimonials_icon,
    path: "/testimonials",
    childrens: [],
    roles: [UserRole.Admin],
  },
  {
    label: "Content",
    icon: content_icon,
    path: "/content",
    childrens: [],
    roles: [UserRole.Admin],
  },
  {
    label: "Profile",
    icon: profile_icon,
    path: "/profile",
    childrens: [],
    roles: [UserRole.Admin, UserRole.Doctor, UserRole.CustomerSupport],
  },

  {
    label: "Common",
    icon: common_icon,
    path: "",
    roles: [UserRole.Admin],
    subLinks: [
      { path: "/service-languages", label: "Service Languages ", roles: [UserRole.Admin]  },
      { path: "/service", label: "Services" , roles: [UserRole.Admin] },
      { path: "/service-price", label: "Service Prices", roles: [UserRole.Admin]  },
    ],
  },
  {
    label: "Settings",
    icon: settings_icon,
    path: "/settings",
    childrens: [],
    roles: [UserRole.Admin],
  },
];
