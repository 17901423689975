import { PlusOutlined } from "@ant-design/icons";
import { Drawer, Modal, Space, Spin, Upload, notification } from "antd";
import ErrorMessage from "components/error-message/ErrorMessage";
import RichTextEditor from "components/rich-text-Editor/RichTextEditor";
import { useFormik } from "formik";
import { useCallback, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "redux/testimonials/Actions";
import * as Yup from "yup";
import CountrySelect from "components/country-select/CountrySelect";
import * as CountryAction from "redux/country/Actions";
import * as facilityActions from "redux/facility/Actions";
import * as doctorActions from "redux/doctor/Actions";

function TestimonialsDrawer(props) {
  const {
    setDrawer,
    drawer,
    getPagination,
    editRecord,
    setEditRecord,
    view,
    setView,

  } = props;
  const record = editRecord;
  const dispatch = useDispatch();
  const pagination = getPagination();
  const fetchCountries = useCallback(() => {
    dispatch(CountryAction.getAll({ query: 'limit=100000000&offset=0' }));
  },
    // eslint-disable-next-line
    [dispatch]);

  useEffect(() => {
    fetchCountries();
  },
    // eslint-disable-next-line
    []);

  const uploaderLoader = useSelector(
    (state) => state.testimonials.loading.getUploadUrl,
    shallowEqual
  );

  const { countriesRecords, loading, doctor, facility } = useSelector(
    (state) => ({
      countriesRecords: state.country?.records || [],
      loading: state.country?.loading,
      doctor: state.doctor?.records,
      facility: state.facility?.records,
    }),
    shallowEqual
  );

  const getMasterData = () => {
    const query = `limit=1000000&offset=0`;
    dispatch(doctorActions.getAll({ query }));
    dispatch(facilityActions.getAll({ query }));
  };

  const {
    clientName = "",
    clientDesignation = "",
    companyName = "",
    comapnyType = "",
    comapnyWebsiteLink = "",
    countryId = countriesRecords[0]?.id,
    // name_en = "us",
    reviewText = "",
    reviewType = "",
    videoLink = "",
    isFeatured = "",
    userId = null,
    id = null,
    rating = null,
    facilityId = null,
    doctorId = null,
  } = record || {};

  const isEditing = Boolean(record);
  // const isDisabled = view || isEditing ? true : false;
  const isLoading = isEditing ? loading.update : loading.create;
  const title = `${view ? " View " : isEditing ? " Edit " : " Add "} Testimonial`;
  const [code, setCode] = useState(null);
  const [country, setCountry] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [s3Url, setS3Url] = useState(null);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [reviewFrom, setReviewFrom] = useState(false);
  const [showErrorReviewFrom, setShowErrorReviewFrom] = useState(false);


  useEffect(() => {
    reviewFrom && setShowErrorReviewFrom(false);
  },
    [reviewFrom]);

  useEffect(() => {
    if (editRecord && editRecord.facilityId && editRecord.facilityId !== null) {
      setReviewFrom("Facility")
    }
    else if (editRecord && editRecord.doctorId && editRecord.doctorId !== null) {
      setReviewFrom("Doctor")
    }

  }, [editRecord]);

  const handleUpload = async (file) => {
    setUploadedFile(file)
    const currentDate = new Date().toISOString().replace(/:/g, '-');
    const videoLinkKey = (`${currentDate}_${file.name}`);
    formik.setFieldValue("videoLink", videoLinkKey);
    dispatch(actions.getUploadUrl(videoLinkKey)).then(response => {
      try {
        setS3Url(response.data);
      }
      catch {
        notification.success({ description: "Video Upload Failed" });
      }
    })
      .catch(error => {
        console.error("Error fetching preview URL:", error);
      });
  };

  useEffect(() => {
    if (s3Url && uploadedFile) {
      dispatch(actions.UploadVideoToS3(s3Url, uploadedFile)).then(response => {
        if (uploaderLoader === false) {
          notification.success({ description: "Video Uploaded." });
        }
      })
        .catch(error => {
          notification.success({ description: error });
        });
    }
  }, 
  // eslint-disable-next-line
  [s3Url, uploadedFile]);


  const setEditDefaultValues = () => {
    formik.setValues({ ...initialValues });
  };

  const resetForm = () => {
    formik.resetForm();
    setPreviewOpen(false);
    setPreviewUrl(null);
    formik.setFieldValue('countryId', countriesRecords[0]?.id);
    setCode(countriesRecords[0]?.code);
    setCountry(countriesRecords[0]?.name_en);
    setReviewFrom(null);
  };

  const closeDrawer = () => setDrawer(false);

  const onDrawerChange = (e) => {
    if (!e) {
      setView(false);
      resetForm();
      setEditRecord(null);
      dispatch(
        actions.loading({ update: false, create: false, remove: false })
      );
    }
  };

  useEffect(() => {
    drawer && isEditing && setEditDefaultValues();
    getMasterData();
  },
    // eslint-disable-next-line
    [drawer]);

  //formik Handler
  const initialValues = {
    clientName: clientName || "",
    clientDesignation: clientDesignation || "",
    companyName: companyName || "",
    comapnyType: comapnyType || "",
    comapnyWebsiteLink: comapnyWebsiteLink || "",
    countryId: countryId || "",
    reviewText: reviewText || "",
    reviewType: reviewType || "text",
    videoLink: videoLink || "",
    isFeatured: isFeatured || false,
    userId: userId || null,
    rating: rating || null,
    facilityId: facilityId || null,
    doctorId: doctorId || null,
  };

  const validationSchema = Yup.object().shape({
    clientName: Yup.string().required("Client name is required"),
    clientDesignation: Yup.string().required("Client designation is required"),
    companyName: Yup.string().required("Company name is required"),
    comapnyType: Yup.string().notRequired(),
    comapnyWebsiteLink: Yup.string().notRequired(),
    countryId: Yup.string().required("Country is required"),
    reviewText: Yup.string().required("Review text is required"),
    reviewType: Yup.string().required("Review type is required"),
    videoLink: Yup.string().notRequired(),
    isFeatured: Yup.string().notRequired(),
    rating: Yup.number()
      .required("Rating is required")
      .oneOf(
        [0.5, 1.0, 1.5, 2.0, 2.5, 3.0, 3.5, 4.0, 4.5, 5.0],
        "Invalid rating value"
      )
      .typeError("Rating must be a number"),
  });

  const onSubmit = async (values) => {
    if (values.reviewType === "text") {
      values.videoLink = "";
    }
    if (!reviewFrom || reviewFrom === null) {
      setShowErrorReviewFrom(true);
      return;
    }
    else {
      setShowErrorReviewFrom(false);
    }
    if (reviewFrom === 'Facility' && (!values.facilityId || values.facilityId === null)) {
      formik.setFieldError("facilityId", "Facility is required");
      return
    }
    else if (reviewFrom === 'Doctor' && (!values.doctorId || values.doctorId === null)) {
      formik.setFieldError("doctorId", "Doctor is required");
      return
    }
    else {
      formik.setFieldError("facilityId", "");
      formik.setFieldError("doctorId", "");
    }

    values.rating = parseFloat(values.rating);
    dispatch(actions.loading(isEditing ? { update: true } : { create: true }));

    const action = isEditing
      ? actions.update(
        id,
        values,
        setDrawer,
        resetForm,
        pagination,
        setEditRecord
      )
      : actions.create(values, setDrawer, resetForm, pagination);
    return dispatch(action);
  };

  const handleCancel = () => setPreviewOpen(false);

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );
  const formik = useFormik({ initialValues, validationSchema, onSubmit });
  const fetchGetUrl = async () => {
    dispatch(actions.getVideoUrl(editRecord?.videoLink || formik?.values?.videoLink)).then(response => {
      setPreviewUrl(response.data);
    })
  };
  useEffect(() => {
    if ((editRecord && editRecord?.videoLink !== "" && typeof editRecord.videoLink !== 'undefined' &&  editRecord.videoLink !== null) || formik?.values?.videoLink !== "") {
      fetchGetUrl();
    }
  }, 
  // eslint-disable-next-line
  [editRecord, formik?.values?.videoLink]);

  useEffect(() => {
    if (countriesRecords && editRecord) {
      setCode(editRecord?.country?.code || countriesRecords[0]?.code);
      setCountry(editRecord?.country?.name_en || countriesRecords[0]?.name_en);
    }
    if (countriesRecords && !editRecord) {
      formik.setFieldValue('countryId', countriesRecords[0]?.id);
    }
  
  }, 
  // eslint-disable-next-line
  [editRecord]);


  return (
    <>
      <Drawer
        afterOpenChange={onDrawerChange}
        title={title}
        placement={"right"}
        width={"60%"}
        onClose={closeDrawer}
        open={drawer}
        extra={
          <Space>
            {!view && (
              <>
                {!isEditing && (
                  <>
                    <button
                      disabled={isLoading}
                      onClick={resetForm}
                      className="btn-sm btn btn-light px-4 "
                      style={{ border: "1px solid grey" }}
                    >
                      Clear
                    </button>
                  </>
                )}
                <button
                  type="button"
                  disabled={isLoading || uploaderLoader}
                  onClick={formik.handleSubmit}
                  className={`btn-sm btn ${isLoading || uploaderLoader ? "btn-light" : "btn-theme"
                    } px-4`}
                >
                  {isLoading ? <Spin /> : uploaderLoader ? <Spin /> : "save"}
                </button>
              </>
            )}
          </Space>
        }
      >
        <div className="drawer_main_div">
          <h2 className=" text-center">{title}</h2>
          <form onSubmit={formik.handleSubmit}>
            <div className="  my-2">
              <div className="row mx-3">
                <div className="col mt-2"></div>
              </div>
              <div className="row mx-3">
                {/* /********** INPUT ********/}
                <div className="">
                  <span className="drawer_span required">
                    Client Full Name
                  </span>
                  <input
                    disabled={view}
                    type="text"
                    name="clientName"
                    className={`form-control ${formik.errors.clientName && formik.touched.clientName
                      ? "input_error"
                      : ""
                      }`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.clientName}
                    placeholder="Enter client full name"
                  />
                  {formik.errors.clientName && formik.touched.clientName && (
                    <ErrorMessage message={formik.errors.clientName} />
                  )}
                </div>
                {/* /********** INPUT ********/}
                {/* /********** INPUT ********/}
                <div className="">
                  <span className="drawer_span required">
                    Company Name
                  </span>
                  <input
                    disabled={view}
                    type="text"
                    name="companyName"
                    className={`form-control ${formik.errors.companyName && formik.touched.companyName
                      ? "input_error"
                      : ""
                      }`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.companyName}
                    placeholder="Enter company name"
                  />
                  {formik.errors.companyName && formik.touched.companyName && (
                    <ErrorMessage message={formik.errors.companyName} />
                  )}
                </div>
                {/* /********** INPUT ********/}


                {/* /********** INPUT ********/}
                <div className="">
                  <span className="drawer_span required">
                    Company Type
                  </span>
                  <input
                    disabled={view}
                    type="text"
                    name="comapnyType"
                    className={`form-control ${formik.errors.comapnyType && formik.touched.comapnyType
                      ? "input_error"
                      : ""
                      }`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.comapnyType}
                    placeholder="Enter what the company is about"
                  />
                  {formik.errors.comapnyType && formik.touched.comapnyType && (
                    <ErrorMessage message={formik.errors.comapnyType} />
                  )}
                </div>
                {/* /********** INPUT ********/}
                {/* /********** INPUT ********/}
                <div className="">
                  <span className="drawer_span required">
                    Company Website Link
                  </span>
                  <input
                    disabled={view}
                    type="text"
                    name="comapnyWebsiteLink"
                    className={`form-control ${formik.errors.comapnyWebsiteLink && formik.touched.comapnyWebsiteLink
                      ? "input_error"
                      : ""
                      }`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.comapnyWebsiteLink}
                    placeholder="Enter link of company website"
                  />
                  {formik.errors.comapnyWebsiteLink && formik.touched.comapnyWebsiteLink && (
                    <ErrorMessage message={formik.errors.comapnyWebsiteLink} />
                  )}
                </div>
                {/* /********** INPUT ********/}
                {/* /********** INPUT ********/}
                <div className="">
                  <span className="drawer_span required">
                    Designation
                  </span>
                  <input
                    disabled={view}
                    type="text"
                    name="clientDesignation"
                    className={`form-control ${formik.errors.clientDesignation && formik.touched.clientDesignation
                      ? "input_error"
                      : ""
                      }`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.clientDesignation}
                    placeholder="Enter designation"
                  />
                  {formik.errors.clientDesignation && formik.touched.clientDesignation && (
                    <ErrorMessage message={formik.errors.clientDesignation} />
                  )}
                </div>
                {/* /********** INPUT ********/}
                <div className="">
                  <span className="drawer_span required">
                    Country
                  </span>
                  <CountrySelect

                    values={{
                      value: code || countriesRecords[0]?.code,
                      label: (
                        <div>
                          {' '}
                          <img
                            className="avatar avatar-xss avatar-circle me-2"
                            src={`assets_modules/flag-icon-css/flags/1x1/${ code?.toLowerCase() || editRecord?.country?.code  || countriesRecords[0]?.code
                              }.svg`}
                            alt=" Country Flag"
                          />
                          {country || countriesRecords[0]?.name_en}
                        </div>
                      ),
                    }}

                    defaultNameRedux={''}
                    handleBlur={formik.handleBlur}
                    defaultValueRedux={''}
                    setCountry={(e) => {
                      formik.setFieldValue('countryId', e?.id || '');
                      setCountry(e?.value || '');
                      setCode(e?.label?.props?.children[0]?.props?.alt || '');

                    }}
                    className={
                      'w-100' +
                      ` ${formik.errors.countryId && formik.touched.countryId
                        ? 'input_error'
                        : ''
                      }`
                    }
                    style={{
                      border: '0.0625rem solid rgba(231, 234, 243, 0.7)',
                    }}
                    missingRecords={countriesRecords}
                    isDisabled={view}

                  />
                  {formik.errors.countryId && formik.touched.countryId && (
                    <ErrorMessage message={formik.errors.countryId} />
                  )}
                </div>
                {/* /********** INPUT ********/}
                {/* /********** INPUT ********/}
                <div className="">
                  <span className="drawer_span required">
                    Testimonial Type
                  </span>
                  <select
                    disabled={view}
                    className="form-control drawer_input"
                    name="reviewType"
                    onChange={(e) => {
                      formik.handleChange(e);
                      formik.setFieldValue("reviewType", e.target.value);
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.reviewType || "text"}
                  >
                    <option value={null} disabled>Select Testimonials Type</option>
                    <option value="text">Written Testimonials</option>
                    <option value="video">Video Testimonials</option>
                  </select>
                  {formik.errors.reviewType && formik.touched.reviewType && (
                    <ErrorMessage message={formik.errors.reviewType} />
                  )}
                </div>
                {/* /********** INPUT ********/}
                {formik.values.reviewType === "video" && ( // Conditionally render if testimonial type is "Video Testimonials"
                  <div className="mb-5 pb-4">
                    <span className="pb-2  drawer_span">Upload Video Testimonials
                      {previewUrl && !uploaderLoader && <button className="btn btn-outline-dark mx-3" type="button" onClick={() => setPreviewOpen(true)} >Preview Video</button>}
                    </span>
                    <div
                      className="d-flex align-items-center h-75  text-center"
                      style={{ border: "dotted 3px  grey", placeContent: "center" }}
                    >
                      <Upload
                      disabled={view}
                        action=""
                        customRequest={({ file }) => handleUpload(file)}
                        showUploadList={false}
                      >
                        {uploadButton}
                        {uploaderLoader && <> <Spin /> <span>Uploading Video, Please Wait</span> </>}

                      </Upload>

                      <Modal
                        title="Preview Video"
                        visible={previewOpen}
                        footer={null}
                        onCancel={handleCancel}
                        bodyStyle={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '70vh' }} // Center the video vertically
                      >
                        <video src={previewUrl} controls style={{ maxWidth: '100%', maxHeight: '100%' }} />
                      </Modal>
                    </div>

                  </div>
                )}
                {/* /********** INPUT ********/}
                <div className="">
                  <span className="drawer_span required">Rating</span>
                  <select
                    disabled={view}
                    className={`form-control ${formik.errors.rating && formik.touched.rating ? "input_error" : ""}`}
                    name="rating"
                    onChange={(e) => {
                      formik.setFieldValue("rating", e.target.value);
                    }}
                    onBlur={formik.handleBlur}
                    value={parseFloat(formik.values.rating) || parseFloat(editRecord?.rating) || ""}
                  >
                    <option value="">Select rating</option>
                    <option value={0.5}>0.5</option>
                    <option value={1.0}>1.0</option>
                    <option value={1.5}>1.5</option>
                    <option value={2.0}>2.0</option>
                    <option value={2.5}>2.5</option>
                    <option value={3.0}>3.0</option>
                    <option value={3.5}>3.5</option>
                    <option value={4.0}>4.0</option>
                    <option value={4.5}>4.5</option>
                    <option value={5.0}>5.0</option>
                  </select>
                  {formik.errors.rating && formik.touched.rating && (
                    <ErrorMessage message={formik.errors.rating} />
                  )}
                </div>
                {/* /********** INPUT ********/}
                <div className="">
                  <span className="drawer_span required">
                    Testimonial From
                  </span>
                  <select
                    disabled={view}
                    className="form-control drawer_input"
                    name="reviewFrom"
                    onChange={(e) => {
                      setReviewFrom(e.target.value);
                    }}
                    value={reviewFrom || ""}
                  >
                    <option value="">Select Testimonials From</option>
                    <option value="Facility">Facility</option>
                    <option value="Doctor">Doctor</option>
                  </select>
                  {showErrorReviewFrom && (
                    <ErrorMessage message={'Testimonials From is required'} />
                  )}
                </div>
                {/* /********** INPUT ********/}
                {reviewFrom === "Facility" && (
                  <div className="">
                    <span className="pb-2 drawer_span">Facility</span>
                    <select
                      disabled={view}
                      className="form-control drawer_input"
                      name="facilityId"
                      onChange={(e) => {
                        formik.setFieldValue("facilityId", e.target.value);
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.facilityId}
                    >
                      <option value={null}> Select Facility</option>
                      {facility?.map((record, index) => (
                        <option key={index} value={record?.id}>
                          {" "}
                          {record?.name_en}
                        </option>
                      ))}
                    </select>
                    {formik.errors.facilityId && formik.touched.facilityId && (
                      <ErrorMessage message={formik.errors.facilityId} />
                    )}
                  </div>
                )}
                {/* /********** INPUT ********/}
                {reviewFrom === "Doctor" && (
                  <div className="">
                    <span className="pb-2 drawer_span">Doctor</span>
                    <select
                      defaultValue={null}
                      disabled={view}
                      className="form-control drawer_input"
                      name="doctorId"
                      onChange={(e) => {
                        const selectedDoctorId = e.target.value === 'null' ? null : e.target.value;
                        formik.setFieldValue("doctorId", selectedDoctorId);
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.doctorId}
                    >
                      <option value={null}> Select Doctor</option>
                      {doctor?.map((record, index) => (
                        <option key={index} value={record?.id}>
                          {" "}
                          {record?.firstName_en + " " + record?.lastName_en}
                        </option>
                      ))}
                    </select>
                    {formik.errors.doctorId && formik.touched.doctorId && (
                      <ErrorMessage message={formik.errors.doctorId} />
                    )}
                  </div>
                )}
                {/* /********** INPUT ********/}
                <div className="">
                  <div className="form-check form-switch my-2">
                    <input
                      disabled={view}
                      name="isFeatured"
                      type="checkbox"
                      role="switch"
                      checked={formik.values.isFeatured}
                      className={` drawer_input form-check-input drawer_input ${formik.errors.isFeatured && formik.touched.isFeatured
                        ? "input_error"
                        : ""
                        }`}
                      onChange={(e) => {
                        formik.setFieldValue("isFeatured", e.target.checked);
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.isFeatured}
                    />
                    <span className="drawer_span">
                      Is Featured{" "}
                      <span className="form-label-secondary">
                        (Display on navbar ?)
                      </span>
                    </span>
                  </div>
                </div>
                {formik.errors.isFeatured && formik.touched.isFeatured && (
                  <ErrorMessage message={formik.errors.isFeatured} />
                )}
              </div>

              <div className="row mx-3 ">
                <div className="col  ">
                  <span className="pb-2 drawer_span required">
                    Testimonial Content
                  </span>
                  <RichTextEditor
                    value={formik.values.reviewText}
                    name="reviewText"
                    formik={formik}
                    setValue={(value) => formik.setFieldValue("reviewText", value)}
                    disabled={view}
                  />
                  {formik.errors.reviewText && formik.touched.reviewText && (
                    <ErrorMessage message={formik.errors.reviewText} />
                  )}
                </div>
              </div>
            </div>
          </form>
        </div>
      </Drawer>
    </>
  );
}

export default TestimonialsDrawer;
