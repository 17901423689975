import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: {
    update: false,
    getAll: false,
  },
  records: null,
};

export const settingsSlice = createSlice({
  name: "settings",
  initialState: initialState,
  reducers: {
    setLoading: (state, action) => {
      const {
        getAll = null,
        update = null,
      } = action.payload;
      state.loading = {
        getAll: getAll != null ? getAll : state.loading?.getAll || false,
        update: update != null ? update : state.loading.update || false,
      };
    },
    getAll: (state, action) => {
      const  records  = action.payload;
      state.records = records;
    },
  },
});
