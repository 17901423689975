
const RequestDetailModal = ({ detailsModal }) => {
  return (
    <>
      <div className="container-fluid ">
        <h1 className="text-center mt-2 ">Request Details</h1>
        <h6 className="text-center mb-4">{new Date(detailsModal?.createdAt).toLocaleString()}</h6>
        <div className="row">
          <div className="col-md-4 col-sm-12">
            <h6>User</h6>
            <label>{detailsModal.firstName}</label>
          </div>
          <div className="col-md-4 col-sm-12">
            <h6>Email</h6>
            <label>{detailsModal.email}</label>
          </div>
          <div className="col-md-4 col-sm-12">
            <h6>Phone</h6>
            <label>{detailsModal.phone}</label>
          </div>

        </div>
        <div className="row mt-4">
          <div className="col-md-4 col-sm-12 ">
            <h6>Assigned User</h6>
            <label>{detailsModal.user ? detailsModal.user.name : "Not Assigned"}</label>
          </div>
          <div className="col-md-4 col-sm-12">
            <h6>Assigned User Email</h6>
            <label>{detailsModal.user? detailsModal.user.email: "Not Assigned"}</label>
          </div>
          <div className="col-md-4 col-sm-12">
            <h6>Assigned User Phone</h6>
            <label>{detailsModal.user ? detailsModal.user.phone: "Not Assigned"}</label>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-md-4 col-sm-12 ">
            <h6>Status</h6>
            <label>{detailsModal.status}</label>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-md-12 ">
            <h6>User Message</h6>
            <p>{detailsModal.message}</p>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-md-12 ">
            <h6>Status Update Reason</h6>
            <p>{detailsModal.message}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default RequestDetailModal;
