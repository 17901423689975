import React from "react";
import ChangePassword from "./change-password";
import "./dashboard-profile.style.css";
import UpdateProfile from "./update-profile";

const Profile = () => {
  return (
    <>
      <main className="dashboard_profile_main d-flex   ">
        {/* <!-- ========== LEFT DIV ========== --> */}
        <div className="dashboard_profile_left_div  d-none d-lg-flex flex-column height_fit_content">
          <div className="dashboard_profile_left_div_nav_item">
            <i className="bi bi-person  bi-3x"></i>
            <a className="text_profile_link" href="#profileUpdateSection">Profile</a>
          </div>
          <div className="dashboard_profile_left_div_nav_item">
            <i className=" bi bi-key "></i>
            <a className="text_profile_link" href="#passwordSection">
              Password
            </a>
          </div>
        </div>
        <div className="dashboard_profile_right_div" id="content">
          <UpdateProfile />
          <ChangePassword />
        </div>
      </main>
    </>
  );
};

export default Profile;
