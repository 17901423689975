import { Drawer, Space, Spin } from "antd";
import ErrorMessage from "components/error-message/ErrorMessage";
import { useFormik } from "formik";
import { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "redux/common/service-language/Actions";
import * as Yup from "yup";

function ServiceLanguageDrawer(props) {
  const {
    setDrawer,
    drawer,
    getPagination,
    editRecord,
    setEditRecord,
    view,
    setView,
  } = props;
  const { loading } = useSelector(
    (state) => ({
      loading: state.speciality?.loading,
    }),
    shallowEqual
  );
  const {
    name_en = "",
    name_ar = "",
    name_de = "",
    name_ru = "",
    name_uk = "",

    id = null,
  } = editRecord || {};

  const isEditing = Boolean(editRecord);
  const isLoading = isEditing ? loading.update : loading.create;
  const dispatch = useDispatch();
  const pagination = getPagination();
  const title = `${
    view ? "View" : isEditing ? " Edit " : " Add "
  } Service Language`;

  const setEditDefaultValues = () => formik.setValues({ ...initialValues });
  const resetForm = () => formik.resetForm();
  const closeDrawer = () => setDrawer(false);

  const onDrawerChange = (e) => {
    if (!e) {
      view && setView(false);
      editRecord && setEditRecord(null);
      resetForm();
      dispatch(
        actions.loading({ update: false, create: false, remove: false })
      );
    }
  };

  useEffect(() => {
    drawer && isEditing && setEditDefaultValues();
  }, 
  // eslint-disable-next-line
  [drawer]);

  //formik Handler
  const initialValues = {
    name_en: name_en || "",
    name_ar: name_ar || "",
    name_ru: name_ru || "",
    name_de: name_de || "",
    name_uk: name_uk || "",

  };

  const validationSchema = Yup.object().shape({
    name_en: Yup.string().required("Name (English) is required"),
    name_ar: Yup.string().notRequired(),
    name_ru: Yup.string().notRequired(),
    name_de: Yup.string().notRequired(),
    name_uk: Yup.string().notRequired(),

  });

  const onSubmit = async (values) => {
    dispatch(actions.loading(isEditing ? { update: true } : { create: true }));

    const action = isEditing
      ? actions.update(id, values, setDrawer, resetForm, pagination)
      : actions.create(values, setDrawer, resetForm, pagination);
    return dispatch(action);
  };

  const formik = useFormik({ initialValues, validationSchema, onSubmit });

  return (
    <>
      <Drawer
        afterOpenChange={onDrawerChange}
        title={title}
        placement={"right"}
        width={500}
        onClose={closeDrawer}
        open={drawer}
        extra={
          <Space>
            {!view && (
              <>
                {!editRecord && (
                  <>
                    <button
                      disabled={isLoading}
                      onClick={resetForm}
                      className="btn-sm btn btn-light px-4 "
                      style={{ border: "1px solid grey" }}
                    >
                      Clear
                    </button>
                  </>
                )}
                <button
                  type="button"
                  disabled={isLoading}
                  onClick={formik.handleSubmit}
                  className={`btn-sm btn ${
                    isLoading ? "btn-light" : "btn-theme"
                  } px-4`}
                >
                  {isLoading ? <Spin /> : "save"}
                </button>
              </>
            )}
          </Space>
        }
      >
        <div className="drawer_main_div">
          <h2 className=" text-center">{title}</h2>
          <div className="container-fluid">
            <div className="row">
              <form onSubmit={formik.handleSubmit}>
                {/* /********** INPUT ********/}
                <div className="my-2">
                  <span className="drawer_span required">
                    Name{" "}
                    <span className="form-label-secondary"> (English)</span>
                  </span>
                  <input
                    disabled={view}
                    // disabled={isEditing ? true : false}
                    type="text"
                    name="name_en"
                    className={`form-control ${
                      formik.errors.name_en && formik.touched.name_en
                        ? "input_error"
                        : ""
                    }`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name_en}
                    placeholder="Enter name in (English)"
                  />
                  {formik.errors.name_en && formik.touched.name_en && (
                    <ErrorMessage message={formik.errors.name_en} />
                  )}
                </div>
                {/* /********** INPUT ********/}
                <div className="my-2">
                  <span className="drawer_span">
                    Name <span className="form-label-secondary"> (Arabic)</span>
                  </span>
                  <input
                    disabled={view}
                    type="text"
                    name="name_ar"
                    className={`form-control ${
                      formik.errors.name_ar && formik.touched.name_ar
                        ? "input_error"
                        : ""
                    }`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name_ar}
                    placeholder="Enter name in (Arabic)"
                  />
                  {formik.errors.name_ar && formik.touched.name_ar && (
                    <ErrorMessage message={formik.errors.name_ar} />
                  )}
                </div>
                {/* /********** INPUT ********/}
                <div className="my-2">
                  <span className="drawer_span">
                    Name{" "}
                    <span className="form-label-secondary"> (Russian)</span>
                  </span>
                  <input
                    disabled={view}
                    type="text"
                    name="name_ru"
                    className={`form-control ${
                      formik.errors.name_ru && formik.touched.name_ru
                        ? "input_error"
                        : ""
                    }`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name_ru}
                    placeholder="Enter name in (Russian)"
                  />
                  {formik.errors.name_ru && formik.touched.name_ru && (
                    <ErrorMessage message={formik.errors.name_ru} />
                  )}
                </div>
                {/* /********** INPUT ********/}
                <div className="my-2">
                  <span className="drawer_span">
                    Name <span className="form-label-secondary"> (German)</span>
                  </span>
                  <input
                    disabled={view}
                    type="text"
                    name="name_de"
                    className={`form-control ${
                      formik.errors.name_de && formik.touched.name_de
                        ? "input_error"
                        : ""
                    }`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name_de}
                    placeholder="Enter name in (German)"
                  />
                  {formik.errors.name_de && formik.touched.name_de && (
                    <ErrorMessage message={formik.errors.name_de} />
                  )}
                </div>
                 {/* /********** INPUT ********/}
                 <div className="my-2">
                  <span className="drawer_span">
                    Name <span className="form-label-secondary"> (Ukrainian)</span>
                  </span>
                  <input
                    disabled={view}
                    type="text"
                    name="name_uk"
                    className={`form-control ${
                      formik.errors.name_uk && formik.touched.name_uk
                        ? "input_error"
                        : ""
                    }`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name_uk}
                    placeholder="Enter name in (Ukrainian)"
                  />
                  {formik.errors.name_uk && formik.touched.name_uk && (
                    <ErrorMessage message={formik.errors.name_uk} />
                  )}
                </div>
                {/* /********** BUTTON ********/}
              </form>
            </div>
          </div>
        </div>
      </Drawer>
    </>
  );
}

export default ServiceLanguageDrawer;
