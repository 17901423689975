import { Drawer, Image, Space, Spin, Upload, notification } from "antd";
import user_profile_logo from "assets/images/user-profile-logo.svg";
import ErrorMessage from "components/error-message/ErrorMessage";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as uploadActions from "redux/upload/Actions";
import * as actions from "redux/user/Actions";
import { getBase64 } from "utils/helpers/helper-functions";
import * as Yup from "yup";

function UserDrawer(props) {
  const {
    setDrawer,
    drawer,
    getPagination,
    editRecord,
    setEditRecord,
    view,
    setView,
  } = props;
  const { loading } = useSelector(
    (state) => ({
      loading: state.user?.loading,
    }),
    shallowEqual
  );
  const {
    email = "",
    name = "",
    phone = "",
    role = null,
    isActive = true,
    image = " ",
    id = null,
  } = editRecord || {};

  const isEditing = Boolean(editRecord);
  const isLoading = isEditing ? loading.update : loading.create;
  const title = `${view ? " View " : isEditing ? " Edit " : " Add "} User`;
  const dispatch = useDispatch();
  const pagination = getPagination();
  const [uploadedImage, setImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(isEditing ? image : "");

  const setEditDefaultValues = () => {
    formik.setValues({ ...initialValues });
    setPreviewImage(image || "");
  };

  const onChange = async (e) => {
    const file = e.file;
    setImage(e.file);
    file.preview = await getBase64(file.originFileObj);
    setPreviewImage(file.url || file.preview);
    formik.setFieldValue("image", file.url || file.preview);
  };

  const removeImage = () => {
    setImage(null);
    setPreviewImage(null);
    formik.setFieldValue("image", "");
  };

  const resetForm = () => {
    formik.resetForm();
    setPreviewImage("");
    setImage(null);
    setEditRecord(null);
  };

  const closeDrawer = () => setDrawer(false);

  const onDrawerChange = (e) => {
    if (!e) {
      setView(false);
      resetForm();
      setEditRecord(null);
      dispatch(
        actions.loading({ update: false, create: false, remove: false })
      );
    }
  };

  useEffect(() => {
    drawer && isEditing && setEditDefaultValues();
  }, 
  // eslint-disable-next-line
  [drawer]);

  //formik Handler
  const initialValues = {
    email: email || "",
    name: name || "",
    phone: phone || "",
    role: role || "",
    isActive: isActive || true,
    image: image || "",
    password: "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().required().email("Email is required "),
    name: Yup.string().required("Name is required "),
    phone: Yup.string().required("Phone Number is required ").min(3),
    password: isEditing
      ? Yup.string().optional().min(6)
      : Yup.string().required("Password is required").min(6),
    role: Yup.string().required("User role is required "),
    isActive: Yup.boolean().notRequired(),
    image: Yup.string().notRequired(),
  });

  const onSubmit = async (values) => {
    dispatch(actions.loading(isEditing ? { update: true } : { create: true }));

    if (uploadedImage) {
      const Images = await dispatch(uploadActions.upload([uploadedImage]));
      if (!Images?.length) {
        notification.success({ description: "Image Upload Failed" });
        dispatch(
          actions.loading(isEditing ? { update: false } : { create: false })
        );
        return;
      }
      values.image = Images[0] || "";
    }

    const action = isEditing
      ? actions.update(id, values, setDrawer, resetForm, pagination)
      : actions.create(values, setDrawer, resetForm, pagination);
    return dispatch(action);
  };

  const formik = useFormik({ initialValues, validationSchema, onSubmit });

  return (
    <>
      <Drawer
        afterOpenChange={onDrawerChange}
        title={title}
        placement={"right"}
        width={500}
        onClose={closeDrawer}
        open={drawer}
        extra={
          <Space>
            {!view && (
              <>
                <button
                  disabled={isLoading}
                  onClick={resetForm}
                  className="btn-sm btn btn-light px-4 "
                  style={{ border: "1px solid grey" }}
                >
                  Clear
                </button>
                <button
                  type="button"
                  disabled={isLoading}
                  onClick={formik.handleSubmit}
                  className={`btn-sm btn ${
                    isLoading ? "btn-light" : "btn-theme"
                  } px-4`}
                >
                  {isLoading ? <Spin /> : "save"}
                </button>
              </>
            )}
          </Space>
        }
      >
        <div className="drawer_main_div">
          <h2 className=" text-center">{title}</h2>
          <div className="container-fluid">
            <div className="row">
              <form onSubmit={formik.handleSubmit}>
                {/* /********** INPUT ********/}
                <div className=" m-auto my-4 text-center w-50">
                  <Image
                    style={{
                      // height: "80%",
                      borderRadius: "50%",
                      boxShadow: "0px 0px 15px 0px black",
                    }}
                    preview={previewImage ? true : false}
                    height={150}
                    width={150}
                    src={previewImage || user_profile_logo}
                    fallback={user_profile_logo}
                  />
                  {formik.errors.image && formik.touched.image && (
                    <ErrorMessage message={formik.errors.image} />
                  )}
                  <div className="mb-4 mt-4 d-flex ">
                    {!view && (
                      <>
                        <Upload
                          disabled={isLoading || view}
                          showUploadList={false}
                          maxCount={1}
                          onChange={onChange}
                        >
                          <button
                            type="button"
                            disabled={isLoading || view}
                            className="btn-theme btn py-2 px-4"
                          >
                            Upload
                          </button>
                        </Upload>

                        <button
                          type="button"
                          style={{
                            background: previewImage ? "#f30301" : "grey",
                          }}
                          className="btn py-2 px-4 mx-2 text-white "
                          disabled={isLoading || view || !previewImage}
                          onClick={removeImage}
                        >
                          Delete
                        </button>
                      </>
                    )}
                  </div>
                </div>
                {/* /********** INPUT ********/}
                <div className="my-2">
                  <span className="drawer_span required">Email </span>
                  <input
                    disabled={view ? true : isEditing ? true : false}
                    type="text"
                    name="email"
                    className={`form-control drawer_input ${
                      formik.errors.email && formik.touched.email
                        ? "input_error"
                        : ""
                    }`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    placeholder="Email"
                  />
                  {formik.errors.email && formik.touched.email && (
                    <ErrorMessage message={formik.errors.email} />
                  )}
                </div>
                {!isEditing && (
                  <>
                    <div className="my-2">
                      <span className="drawer_span required">Password </span>
                      <input
                        disabled={view ? true : isEditing ? true : false}
                        type="password"
                        name="password"
                        className={`form-control drawer_input ${
                          formik.errors.password && formik.touched.password
                            ? "input_error"
                            : ""
                        }`}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.password}
                        placeholder="Password"
                      />
                      {formik.errors.password && formik.touched.password && (
                        <ErrorMessage message={formik.errors.password} />
                      )}
                    </div>
                  </>
                )}
                {/* /********** INPUT ********/}
                <div className="my-2">
                  <span className="drawer_span required">Name </span>
                  <input
                    disabled={view}
                    type="text"
                    name="name"
                    className={`form-control drawer_input ${
                      formik.errors.name && formik.touched.name
                        ? "input_error"
                        : ""
                    }`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name}
                    placeholder="Name"
                  />
                  {formik.errors.name && formik.touched.name && (
                    <ErrorMessage message={formik.errors.name} />
                  )}
                </div>
                {/* /********** INPUT ********/}
                <div className="my-2">
                  <span className="drawer_span required">Phone</span>
                  <input
                    type="text"
                    name="phone"
                    disabled={view}
                    className={`form-control  drawer_input ${
                      formik.errors.phone && formik.touched.phone
                        ? "input_error"
                        : ""
                    }`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.phone}
                    placeholder="Phone"
                  />
                  {formik.errors.phone && formik.touched.phone && (
                    <ErrorMessage message={formik.errors.phone} />
                  )}
                </div>
                {/* /********** INPUT ********/}
                <div className="my-2">
                  <span className="drawer_span required">Role</span>
                  <select
                    disabled={view}
                    className={`form-control  drawer_input ${
                      formik.errors.role && formik.touched.role
                        ? "input_error"
                        : ""
                    }`}
                    name="role"
                    onChange={(e) => {
                      // formik.handleChange(e);
                      formik.setFieldValue("role", e.target.value);
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.role}
                  >
                    <option value={""}> Select Role</option>
                    <option value={"admin"}> Admin</option>
                    <option value={"doctor"}> Doctor</option>
                    <option value={"customer-support"}>
                      {" "}
                      Customer Support
                    </option>
                  </select>
                  {formik.errors.role && formik.touched.role && (
                    <ErrorMessage message={formik.errors.role} />
                  )}
                </div>
                {/* /********** INPUT ********/}
                <div className="my-2">
                  <div className="col mt-4 pl-3">
                    <div className="form-check form-switch">
                      <input
                        disabled={view}
                        name="isActive"
                        type="checkbox"
                        defaultChecked={isActive}
                        role="switch"
                        className={`form-check-input drawer_input ${
                          formik.errors.isActive && formik.touched.isActive
                            ? "input_error"
                            : ""
                        }`}
                        onChange={(e) => {
                          formik.setFieldValue("isActive", e.target.checked);
                        }}
                        onBlur={formik.handleBlur}
                        value={formik.values.isActive}
                      />
                      <label
                        className="form-check-label drawer_span "
                        for="flexSwitchCheckDefault"
                      >
                        Is Active?
                      </label>
                    </div>
                    {formik.errors.isActive && formik.touched.isActive && (
                      <ErrorMessage message={formik.errors.isActive} />
                    )}
                  </div>
                </div>
                {/* /********** RIGHT ********/}

                {/* /********** BUTTON ********/}
              </form>
            </div>
          </div>
        </div>
      </Drawer>
    </>
  );
}

export default UserDrawer;
