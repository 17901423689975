import React from "react";

function TranslationNotAvailable() {
  return (
    <>
      <span className="form-label-secondary">( translation not available)</span>
    </>
  );
}

export default TranslationNotAvailable;
