import { Select, Table } from "antd";
// import { FaqSelect } from "app/dashboard/facility/components/common/faq.select";
import { shallowEqual, useSelector } from "react-redux";

let width = "200px";
let selectWidth = "400px";

function DoctorBulk(props) {
  const { formik } = props;

  const { country, city, serviceLanguage, speciality, faqs } = useSelector(
    (state) => ({
      country: state.country?.records,
      city: state.city?.records,
      serviceLanguage: state.serviceLanguage?.records,
      speciality: state.speciality?.records,
      faqs: state.faqs?.records,
    }),
    shallowEqual
  );

  const countries = country?.map(({ name_en, id, city = [] }) => {
    let children = city.map(({ name_en: label, id: value }) => ({
      value,
      label,
    }));
    return {
      value: id,
      label: name_en,
      children,
      disabled: children.length ? false : true,
    };
  });

  const serviceLanguages = serviceLanguage?.map(
    ({ id: value, name_en }, index) => ({ value, index, label: name_en })
  );
  const cities = city?.map(({ id: value, name_en, countryId }, index) => ({
    value,
    index,
    label: name_en,
    countryId,
  }));

  const specialities = speciality?.map(({ id: value, name_en }, index) => ({
    value,
    index,
    label: name_en,
  }));

  const faq = faqs?.map((entity, index) => ({
    value: entity?.id,
    label: entity?.question_en,
    index,
  }));

  const columns = [
    {
      fixed: "left",
      width: "60px",
      render: (_, entity, index) => (
        <>
          {formik.errors?.doctors?.[index] ? (
            <>
              {Object.keys(formik.errors?.doctors?.[index]).length > 0 && (
                <>
                  <div className="dropdown">
                    <button
                      style={{ height: "2rem", width: "2rem" }}
                      type="button"
                      className="btn btn-icon btn-danger rounded-circle"
                      id="navbarAppsDropdown"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      data-bs-dropdown-animation=""
                    >
                      <i className="bi-bug-fill"></i>
                      <span className="avatar-status avatar-md-status avatar-status-danger">
                        {Object.keys(formik.errors?.doctors?.[index]).length}
                      </span>
                    </button>
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              <span className="  text-success ms-2 h2">
                <i className="bi bi-check-circle-fill"></i>
              </span>
            </>
          )}
        </>
      ),
    },
    {
      title: <span className="required">First Name</span>,
      width,
      render: (_, __, index) => (
        <TableInput {...{ name: "firstName_en", formik, index }} />
      ),
    },
    {
      title: <span className="required">Last Name</span>,
      width,
      render: (_, __, index) => (
        <TableInput {...{ name: "lastName_en", formik, index }} />
      ),
    },
    {
      title: <span className="required">Address</span>,
      width,
      render: (_, __, index) => (
        <TableInput {...{ name: "address_en", formik, index }} />
      ),
    },
    // {
    //   title: <span className="required">City</span>,
    //   width,
    //   render: (_, __, index) => (
    //     <Cascader
    //       defaultValue={[
    //         formik.values?.doctors?.[index]?.countryId,
    //         formik.values?.doctors?.[index]?.cityId,
    //       ]}
    //       removeIcon={<></>}
    //       className={` ${
    //         formik.errors?.doctors?.[index]?.["cityId"] ? "input_error" : ""
    //       }`}
    //       displayRender={(labels) => labels[labels.length - 1]}
    //       expandTrigger="hover"
    //       options={countries}
    //       onChange={(e) => {
    //         formik.setFieldValue(`doctors[${index}].countryId`, e[0] || null);
    //         formik.setFieldValue(`doctors[${index}].cityId`, e[1] || null);
    //       }}
    //       placeholder="Please select"
    //     />
    //   ),
    // },
    {
      title: <span className="required">Country</span>,
      width: selectWidth,
      render: (_, __, index) => (
        <TableSelect
          {...{
            isMulti: false,
            name: "countryId",
            formik,
            index,
            options: countries,
          }}
        />
      ),
    },
    {
      title: <span className="required">City</span>,
      width: selectWidth,
      render: (_, __, index) => (
        <TableSelect
          {...{
            isMulti: false,
            name: "cityId",
            formik,
            index,
            options: cities.filter(
              ({ countryId }) =>
                countryId === formik.values?.doctors?.[index]?.countryId
            ),
          }}
        />
      ),
    },
    {
      title: <span className="required">zipCode</span>,
      width,
      render: (_, __, index) => (
        <TableInput {...{ name: "zipCode", formik, index }} />
      ),
    },

    {
      title: <span className="required">email</span>,
      width,
      render: (_, __, index) => (
        <TableInput {...{ name: "email", formik, index }} />
      ),
    },
    {
      title: <span className="required">phone</span>,
      width,
      render: (_, __, index) => (
        <TableInput {...{ name: "phone", formik, index }} />
      ),
    },
    {
      title: <>experiance</>,
      width,
      render: (_, __, index) => (
        <TableInput {...{ name: "experiance", formik, index }} />
      ),
    },
    {
      title: <>facebook</>,
      width,
      render: (_, __, index) => (
        <TableInput {...{ name: "facebook", formik, index }} />
      ),
    },
    {
      title: <>whatsApp</>,
      width,
      render: (_, __, index) => (
        <TableInput {...{ name: "whatsApp", formik, index }} />
      ),
    },
    {
      title: <>education </>,
      width,
      render: (_, __, index) => (
        <TableInput {...{ name: "education", formik, index }} />
      ),
    },
    {
      title: <>achievement</>,
      width,
      render: (_, __, index) => (
        <TableInput {...{ name: "achievement", formik, index }} />
      ),
    },
    {
      title: <>membership</>,
      width,
      render: (_, __, index) => (
        <TableInput {...{ name: "membership", formik, index }} />
      ),
    },
    {
      title: <>expertise</>,
      width,
      render: (_, __, index) => (
        <TableInput {...{ name: "expertise", formik, index }} />
      ),
    },
    {
      title: <>overview</>,
      width,
      render: (_, __, index) => (
        <TableInput {...{ name: "overview_en", formik, index }} />
      ),
    },
    {
      title: <>Service Languages</>,
      width: selectWidth,
      render: (_, __, index) => (
        <TableSelect
          {...{
            name: "serviceLanguageIds",
            formik,
            index,
            options: serviceLanguages,
          }}
        />
      ),
    },
    {
      title: <>Specialities</>,
      width: selectWidth,
      render: (_, __, index) => (
        <TableSelect
          {...{
            name: "specialityIds",
            formik,
            index,
            options: specialities,
          }}
        />
      ),
    },
    {
      title: <>Faq</>,
      width: selectWidth,
      render: (_, __, index) => (
        <TableSelect {...{ name: "faqIds", formik, index, options: faq }} />
        // <TableFaqSelect {...{ name: "faqIds", formik, index }} />
      ),
    },
    {
      fixed: "right",
      width: "60px",
      render: (_, entity, index) => (
        <button
          onClick={() => {
            let value = formik.values.doctors.filter((_, i) => i !== index);
            formik.setFieldValue("doctors", value);
          }}
          className="btn btn-white text-danger btn-icon btn-sm show"
        >
          <i className="bi-trash"></i>
        </button>
      ),
    },
  ];

  const AddRecord = () => {
    formik.setFieldValue("doctors", [...formik.values.doctors, DoctorRecord]);
  };

  return (
    <div>
      <button className="btn btn-theme mb-3" onClick={AddRecord}>
        Add Row
      </button>
      <div className="bulk-facility-main-div">
        <Table
          bordered
          size="small"
          scroll={{ y: "40vh", x: 1300 }}
          pagination={false}
          dataSource={formik?.values.doctors || []}
          columns={columns}
        />
      </div>
    </div>
  );
}

export default DoctorBulk;

let doctorKeys = [
  "firstName_en",
  "lastName_en",
  "address_en",
  "cityId",
  "countryId",
  "zipCode",
  "experiance",
  "email",
  "phone",
  "facebook",
  "whatsApp",
  "image",
  "education",
  "achievement",
  "membership",
  "expertise",
  "overview_en",
];

var DoctorRecord = {
  ...Object.fromEntries(doctorKeys.map((key) => [key, ""])),
  serviceLanguageIds: [],
  specialityIds: [],
  faqIds: [],
  isActive: true,
  isFeatured: true,
};

const TableInput = ({ formik, name = "", index }) => {
  return (
    <input
      name
      type="text"
      className={`form-control drawer_input ${
        formik.errors?.doctors?.[index]?.[name] && "input_error"
      } `}
      onChange={(e) =>
        formik.setFieldValue(`doctors[${index}].[${name}]`, e.target.value)
      }
      value={formik.values?.doctors?.[index]?.[name] || ""}
    />
  );
};

// const TableSelect = ({
//   formik,
//   name = "",
//   index,
//   options = [],
//   isMulti = true,
// }) => {
//   return (
//     <>
//       <Select
//         isMulti={isMulti}
//         name={name}
//         value={formik.values?.doctors?.[index]?.[name] || []}
//         options={options}
//         onChange={(e) => {
//           formik.setFieldValue(`doctors[${index}].[${name}]`, e);
//         }}
//         className={
//           " drawer_input form-control w-100 basic-multi-select multiSelectBox  " +
//           ` ${formik?.errors?.doctors?.[index]?.[name] ? "input_error" : ""}`
//         }
//         classNamePrefix="select"
//       />
//     </>
//   );
// };
const TableSelect = ({
  formik,
  name = "",
  index,
  options = [],
  isMulti = true,
  maxTagCount = 2,
}) => {
  return (
    <>
      <Select
        maxTagTextLength={10}
        maxTagCount={maxTagCount}
        value={formik.values?.doctors?.[index]?.[name] || []}
        mode={isMulti ? "multiple" : ""}
        style={{ width: "100%" }}
        onChange={(value) => {
          formik.setFieldValue(`doctors[${index}].[${name}]`, value);
        }}
        options={options}
      />
    </>
  );
};

// const TableFaqSelect = ({ formik, name = "", index }) => {
//   return (
//     <>
//       <FaqSelect
//         name
//         value={formik.values?.doctors?.[index]?.[name] || []}
//         onChange={(e) => formik.setFieldValue(`doctors[${index}].[${name}]`, e)}
//       />
//     </>
//   );
// };
