import { UploadOutlined } from "@ant-design/icons";
import { Button, Upload, notification } from "antd";
import axios from "axios";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import user_profile_logo from "assets/images/user-profile-logo.svg";
import ErrorMessage from "components/error-message/ErrorMessage";
import { authSlice } from "redux/Auth/authslice";
import * as uploadActions from "redux/upload/Actions";
import { ApiUrl } from "services/api";
import { ERROR_MESSAGE, SUCCESS_MESSAGE } from "utils/constants/contants";
import "./dashboard-profile.style.css";
import { getBase64 } from "utils/helpers/helper-functions";

const authAction = authSlice.actions;

function UpdateProfile() {
  const dispatch = useDispatch();
  const [image, setImage] = useState(null);
  const [previewImage, setPreviewImage] = useState("");
  const { authState, user } = useSelector(
    (state) => ({ authState: state.auth, user: state.auth?.user }),
    shallowEqual
  );

  const onChange = async (e) => {
    const file = e.file;
    setImage(e.file);
    file.preview = await getBase64(file.originFileObj);
    setPreviewImage(file.url || file.preview);
  };
  const removeImage = () => {
    setImage(null);
    setPreviewImage(null);
  };

  const ChangePasswordSchema = Yup.object().shape({
    name: Yup.string()
      .required("Name is required")
      .min(2, "Name must be greater than 2 characters"),
    phone: Yup.string().required("Phone  is required"),
  });

  const SubmitHandler = async (values) => {
    let UploadedImage = null;
    if (image) {
      const Images = await dispatch(uploadActions.upload([image]));
      UploadedImage = Images[0];
    }
    try {
      const { name, phone } = values;
      const updateProfileApi = ApiUrl.User.update;
      const response = await axios[updateProfileApi.method](
        updateProfileApi.url(user?.id),
        { name, phone, ...(UploadedImage && { image: UploadedImage }) },
        {
          headers: {
            authorization: `Bearer ${
              authState?.firebaseToken || "no token update profile "
            }`,
          },
        }
      );
      if (response?.status === 200) {
        const user = response.data;
        dispatch(authAction.updateUser({ user }));

        notification.success({
          description: SUCCESS_MESSAGE.UPDATE_PROFILE_SUCCESS,
        });
        removeImage();
      }
    } catch (error) {
      notification.error({
        description: error.message || ERROR_MESSAGE.UPDATE_PROFILE_ERROR,
      });
    }
  };

  //formik Handler
  const formik = useFormik({
    initialValues: {
      name: user?.name || "",
      phone: user?.phone || "",
    },
    validationSchema: ChangePasswordSchema,
    onSubmit: SubmitHandler,
  });
  useEffect(() => {}, []);
  return (
    <>
      <div id="profileUpdateSection" className="dashboard_profile_right_div_item_div">
        <h5> Change your Profile</h5>
        <hr />

        <div className="col-12 d-flex flex-column justify-content-center align-items-center">
          <img
            className="size_100 mt-3  align-self-center border_50"
            src={previewImage || (user?.image && user?.image !== " " && user?.image ) || user_profile_logo}
            alt={""}
          />

          <label className="text_11_400  mt-1 text-gray">Preview</label>
          <div className="mb-4 mt-2 d-flex">
            <Upload showUploadList={false} maxCount={1} onChange={onChange}>
              <Button icon={<UploadOutlined />}>Upload</Button>
            </Upload>
            {image && previewImage && (
              <Button
                onClick={removeImage}
                type="primary"
                danger
                style={{ marginLeft: "5px" }}
              >
                Delete{" "}
              </Button>
            )}
          </div>
        </div>

        <form onSubmit={formik.handleSubmit}>
          {/******************  **************/}
          <div className="d-flex dashboard_profile_right_div_input_box_div">
            <span> Email </span>
            <div className="w-100 pt-4" style={{ marginRight: "20px" }}>
              <input
                type="text"
                name="name"
                disabled={true}
                value={user?.email || ""}
                className={`form-control ${
                  formik.errors.name && formik.touched.name ? "input_error" : ""
                }`}
                placeholder="Enter Email"
              />
              
            </div>
          </div>
          {/******************  **************/}
          <div className="d-flex dashboard_profile_right_div_input_box_div">
            <span> Full Name </span>
            <div className="w-100 pt-4" style={{ marginRight: "20px" }}>
              <input
                type="text"
                name="name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
                className={`form-control ${
                  formik.errors.name && formik.touched.name ? "input_error" : ""
                }`}
                placeholder="Enter Full Name"
              />
              {formik.errors.name && formik.touched.name && (
                <ErrorMessage message={formik.errors.name} />
              )}
            </div>
          </div>
          {/******************   **************/}
          <div className="d-flex dashboard_profile_right_div_input_box_div">
            <span> Phone Number </span>
            <div className="w-100 pt-4" style={{ marginRight: "20px" }}>
              <input
                type="text"
                name="phone"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.phone}
                className={`form-control ${
                  formik.errors.phone && formik.touched.phone
                    ? "input_error"
                    : ""
                }`}
                placeholder="Enter Phone "
              />
              {formik.errors.phone && formik.touched.phone && (
                <ErrorMessage message={formik.errors.phone} />
              )}
            </div>
          </div>

          <div className="dashboard_profile_right_div_item_btn_div">
            <button type="submit" className="btn-theme">
              Save Changes{" "}
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

export default UpdateProfile;
