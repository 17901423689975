import { PlusOutlined } from "@ant-design/icons";
import { Drawer, Modal, Space, Spin, Steps, Upload, notification } from "antd";
import ErrorMessage from "components/error-message/ErrorMessage";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as doctorActions from "redux/doctor/Actions";
import * as facilityActions from "redux/facility/Actions";
import * as countryAction from "redux/country/Actions";
import * as actions from "redux/request/Actions";
import * as specialitiesActions from "redux/specialities/Actions";
import * as treatmentActions from "redux/treatment/Actions";
import * as uploadActions from "redux/upload/Actions";
import { getBase64 } from "utils/helpers/helper-functions";
import * as Yup from "yup";

function RequestsDrawer(props) {
  const {
    setDrawer,
    drawer,
    getPagination,
    editRecord,
    setEditRecord,
    view,
    setView,
  } = props;
  const { loading, speciality, doctor, treatment, facility,countries } = useSelector(
    (state) => ({
      loading: state.facility?.loading,
      speciality: state.speciality?.records,
      doctor: state.doctor?.records,
      treatment: state.treatment?.records,
      facility: state.facility?.records,
      countries: state.country?.records,
    }),
    shallowEqual
  );
  /********************* DEFAUL || INITAL DATA **********************/

  const {
    firstName = null,
    lastName = null,
    message = null,
    email = null,
    phone = null,
    status = null,
    specialityId = null,
    treatmentId = null,
    doctorId = null,
    facilityId = null,
    mediaUrls = [],
    id = null,
    country = null,

  } = editRecord || {};

  const isEditing = Boolean(editRecord);
  const isLoading = isEditing ? loading.update : loading.create;

  const title = `${view ? " View " : isEditing ? " Edit " : " Add "} Request`;
  const isDisabled = view || isEditing ? true : false;
  const dispatch = useDispatch();
  const pagination = getPagination();
  // const [uploadedImage, setImage] = useState(null);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState([]);
  const [statusDrawer, setStatusDrawer] = useState(false);

  
  const handleCancel = () => setPreviewOpen(false);
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };
  const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  const initialValues = {
    firstName: firstName || "",
    lastName: lastName || "",
    email: email || "",
    phone: phone || "",
    message: message || "",
    requestLogMessage: "",
    status: status || "",
    mediaUrls: mediaUrls || [],
    specialityId: specialityId || null,
    treatmentId: treatmentId || null,
    doctorId: doctorId || null,
    facilityId: facilityId || null,
    country: country || "",

  };

  const setEditDefaultValues = () => {
    formik.setValues({ ...initialValues });
    const media = editRecord?.media?.map(({ url }, index) => ({
      uid: index + 1,
      name: "Name",
      status: "done",
      url,
    }));
    setFileList(media || []);
  };

  const resetForm = () => {
    formik.resetForm();
    setFileList([]);
    // setImage(null);
  };

  const closeDrawer = () => setDrawer(false);

  const onDrawerChange = (e) => {
    if (!e) {
      formik.setFieldValue("facilityId", "");
      resetForm();
      setView(false);
      setEditRecord(null);
      dispatch(
        actions.loading({ update: false, create: false, remove: false })
      );
    }
  };

  const getMasterData = () => {
    const query = `limit=1000000&offset=0`;
    dispatch(specialitiesActions.getAll({ query }));
    dispatch(doctorActions.getAll({ query }));
    dispatch(treatmentActions.getAll({ query }));
    dispatch(facilityActions.getAll({ query }));
    dispatch(countryAction.getAll({ query }));

  };

  useEffect(() => {
    if (drawer) {
      isEditing && setEditDefaultValues();
      getMasterData();
    }
  },
  // eslint-disable-next-line
  [drawer]);

  //formik Handler

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    message: Yup.string().required("Message is required"),
    email: Yup.string().required("Email is required").email(),
    phone: Yup.string().required("Phone is required"),
    status: Yup.string()
      .required("Status is required")
      .notOneOf([status, null], "Select new status for update"),
    requestLogMessage: Yup.string(),
    treatmetId: Yup.string().notRequired().nullable(true),
    specialityId: Yup.string().notRequired().nullable(true),
    doctorId: Yup.string().notRequired().nullable(true),
    treatmentId: Yup.string().notRequired().nullable(true),
    mediaUrls: Yup.array().notRequired(),
    country: Yup.string().required("Country is required"),

  });

  const onSubmit = async (values) => {
    dispatch(actions.loading(isEditing ? { update: true } : { create: true }));

    let mediaUrls = fileList
      .filter((file) => !file?.originFileObj)
      .map(({ preview, thumbUrl, url }) => thumbUrl || preview || url);
    const NewImages = fileList.filter((file) => file?.originFileObj);
    if (NewImages?.length) {
      const Images = await dispatch(uploadActions.upload(NewImages));
      if (!Images?.length) {
        notification.success({ description: "Image Upload Failed" });
        return;
      }
      mediaUrls.push(...Images);
    }

    let payload = { ...values, mediaUrls };
    payload = Object.fromEntries(
      Object.entries(payload).filter(([_, value]) => value !== null)
    );

    const action = isEditing
      ? actions.update(id, payload, setDrawer, resetForm, pagination)
      : actions.create(payload, setDrawer, resetForm, pagination);
    return dispatch(action);
  };

  const formik = useFormik({ initialValues, validationSchema, onSubmit });

  return (
    <>
      <Drawer
        afterOpenChange={onDrawerChange}
        title={title}
        placement={"right"}
        width={"50%"}
        onClose={closeDrawer}
        open={drawer}
        extra={
          <Space>
            {(view || editRecord) && (
              <button
                type="button"
                disabled={isLoading}
                onClick={() => setStatusDrawer(true)}
                className={`btn-sm btn btn-theme  px-4`}
              >
                Status
              </button>
            )}
            {!view && (
              <>
                {!editRecord && (
                  <>
                    <button
                      disabled={isLoading}
                      onClick={resetForm}
                      className="btn-sm btn btn-light px-4 "
                      style={{ border: "1px solid grey" }}
                    >
                      Clear
                    </button>
                  </>
                )}
              
                <button
                  type="button"
                  disabled={isLoading}
                  onClick={formik.handleSubmit}
                  className={`btn-sm btn ${
                    isLoading ? "btn-light" : "btn-theme"
                  } px-4`}
                >
                  {isLoading ? <Spin /> : "save"}
                </button>
              </>
            )}
          </Space>
        }
      >
        <div className="drawer_main_div">
          <h2 className=" text-center">{title}</h2>

          <form onSubmit={formik.handleSubmit}>
            <div className="  my-2">
              <div className="row mx-3">
                {/* /***************** First Name *******************/}
                <div className="col">
                  <span className="pb-2 drawer_span required">First Name </span>
                  <div className="mb-2">
                    <input
                      disabled={isDisabled}
                      type="text"
                      placeholder="First Name"
                      name="firstName"
                      className={`form-control drawer_input ${
                        formik.errors.firstName && formik.touched.firstName
                          ? "input_error"
                          : ""
                      }`}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.firstName}
                    />
                    {formik.errors.firstName && formik.touched.firstName && (
                      <ErrorMessage message={formik.errors.firstName} />
                    )}
                  </div>
                  {/************************/}
                  <span className="pb-2 drawer_span required">Last Name </span>
                  <div className="mb-2">
                    <input
                      disabled={isDisabled}
                      type="text"
                      placeholder="Last Name"
                      name="lastName"
                      className={` form-control drawer_input ${
                        formik.errors.lastName && formik.touched.lastName
                          ? "input_error"
                          : ""
                      }`}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.lastName}
                    />
                    {formik.errors.lastName && formik.touched.lastName && (
                      <ErrorMessage message={formik.errors.lastName} />
                    )}
                  </div>

                  {/************************/}
                  <span className="pb-2 drawer_span required">Email </span>
                  <div className="mb-2">
                    <input
                      disabled={isDisabled}
                      type="text"
                      placeholder="Email"
                      name="email"
                      className={`form-control drawer_input ${
                        formik.errors.email && formik.touched.email
                          ? "input_error"
                          : ""
                      }`}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                    />
                    {formik.errors.email && formik.touched.email && (
                      <ErrorMessage message={formik.errors.email} />
                    )}
                  </div>
                  {/************************/}
                  <span className="pb-2 drawer_span required">Phone </span>
                  <div className="mb-2">
                    <input
                      disabled={isDisabled}
                      type="text"
                      placeholder="Phone"
                      name="phone"
                      className={`form-control drawer_input ${
                        formik.errors.phone && formik.touched.phone
                          ? "input_error"
                          : ""
                      }`}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.phone}
                    />
                    {formik.errors.phone && formik.touched.phone && (
                      <ErrorMessage message={formik.errors.phone} />
                    )}
                  </div>
                  {/************************/}
                  <div className="col ">
                    <span className="drawer_span">Country</span>
                    <select
                      disabled={view}
                      className="form-control drawer_input "
                      name="country"
                      onChange={(e) => {
                        formik.handleChange(e);
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.country}
                    >
                      <option value={null}> Select Country</option>
                      {countries?.map((entity, index) => (
                        <option value={entity.name_en} key={index}>

                          {entity.name_en}
                        </option>
                      ))}
                    </select>
                    {formik.errors.country && formik.touched.country && (
                      <ErrorMessage message={formik.errors.country} />
                    )}
                  </div>
                  {/************************/}

                  <span className="pb-2 drawer_span">Facility</span>
                  <select
                    disabled={isDisabled}
                    className="form-control drawer_input"
                    name="facilityId"
                    onChange={(e) => {
                      formik.handleChange(e);
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.facilityId}
                  >
                    <option value={null}> Select Facility</option>
                    {facility?.map((record, index) => (
                      <option key={index} value={record?.id}>
                        {" "}
                        {record?.name_en}
                      </option>
                    ))}
                  </select>
                  {formik.errors.facilityId && formik.touched.facilityId && (
                    <ErrorMessage message={formik.errors.facilityId} />
                  )}
                </div>
              </div>
              {/* /***************** Doctors *******************/}
              <div className="row mx-3">
                <div className="col mt-2">
                  <span className="pb-2 drawer_span">Doctor</span>
                  <select
                    defaultValue={null}
                    disabled={isDisabled}
                    className="form-control drawer_input"
                    name="doctorId"
                    onChange={(e) => {
                      formik.handleChange(e);
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.doctorId}
                  >
                    <option value={null}> Select Doctor</option>
                    {doctor?.map((record, index) => (
                      <option key={index} value={record?.id}>
                        {" "}
                        {record?.firstName_en + " " + record?.lastName_en}
                      </option>
                    ))}
                  </select>
                  {formik.errors.doctorId && formik.touched.doctorId && (
                    <ErrorMessage message={formik.errors.doctorId} />
                  )}
                </div>
              </div>
              {/* /***************** Specilities *******************/}
              <div className="row mx-3">
                <div className="col mt-2">
                  <span className="pb-2 drawer_span">speciality</span>
                  <select
                    disabled={isDisabled}
                    className="form-control drawer_input"
                    name="specialityId"
                    onChange={(e) => {
                      formik.handleChange(e);
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.specialityId}
                  >
                    <option value={null}> Select Speciality</option>
                    {speciality?.map((record, index) => (
                      <option key={index} value={record?.id}>
                        {" "}
                        {record?.name_en}
                      </option>
                    ))}
                  </select>
                  {formik.errors.specialityId &&
                    formik.touched.specialityId && (
                      <ErrorMessage message={formik.errors.specialityId} />
                    )}
                </div>
              </div>
              {/* /***************** Treatmets *******************/}
              <div className="row mx-3">
                <div className="col mt-2">
                  <span className="pb-2 drawer_span">Treatmet</span>
                  <select
                    disabled={isDisabled}
                    className="form-control drawer_input mb-2"
                    name="treatmentId"
                    onChange={(e) => {
                      formik.handleChange(e);
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.treatmentId}
                  >
                    <option value={null}> Select Treatment</option>
                    {treatment?.map((record, index) => (
                      <option key={index} value={record?.id}>
                        {" "}
                        {record?.name_en}
                      </option>
                    ))}
                  </select>
                  {formik.errors.treatmentId && formik.touched.treatmentId && (
                    <ErrorMessage message={formik.errors.treatmentId} />
                  )}
                  {/* /***************** Status *******************/}
                  <span className="pb-2 drawer_span mt-2">Status</span>
                  <select
                    disabled={view}
                    className="form-control drawer_input"
                    name="status"
                    onChange={(e) => {
                      formik.handleChange(e);
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.status}
                  >
                    <option value={null}> Select Status</option>
                    <option value={"scheduled"}> Scheduled</option>
                    <option value={"confirmed"}> Confirmed</option>
                    <option value={"cancelled"}> Cancelled</option>
                    <option value={"completed"}> Completed</option>
                    <option value={"rescheduled"}> Rescheduled</option>
                    <option value={"pending"}> Pending</option>
                    <option value={"no-show"}> NoShow</option>
                    <option value={"in-progress"}> InProgress</option>
                    <option value={"arrived"}> Arrived</option>
                    <option value={"pending-contact"}> PendingContact</option>
                    <option value={"patient-confirmed"}>
                      {" "}
                      PatientConfirmed
                    </option>
                    <option value={"patient-not-reachable"}>
                      {" "}
                      PatientNotReachable
                    </option>
                  </select>
                  {formik.errors.status && formik.touched.status && (
                    <ErrorMessage message={formik.errors.status} />
                  )}

                  {!view && editRecord && (
                    <>
                      <span className="pb-2 pt-2">Status Update Reason</span>
                      <textarea
                        placeholder="message"
                        name="requestLogMessage"
                        rows="2"
                        className={`form-control w-100 drawer_input ${
                          formik.errors.requestLogMessage &&
                          formik.touched.requestLogMessage
                            ? "input_error"
                            : ""
                        }`}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.requestLogMessage}
                      ></textarea>
                      {formik.errors.requestLogMessage &&
                        formik.touched.requestLogMessage && (
                          <ErrorMessage
                            message={formik.errors.requestLogMessage}
                          />
                        )}
                    </>
                  )}
                </div>
              </div>

              {/* /***************** Message *******************/}
              <div className="row mx-3">
                <div className="col my-2 ">
                  <span className="pb-2 drawer_span required">Message</span>
                  <textarea
                    disabled={isDisabled}
                    placeholder="message"
                    name="message"
                    rows="6"
                    className={`form-control w-100 drawer_input ${
                      formik.errors.message && formik.touched.message
                        ? "input_error"
                        : ""
                    }`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.message}
                  ></textarea>
                  {formik.errors.message && formik.touched.message && (
                    <ErrorMessage message={formik.errors.message} />
                  )}
                </div>
              </div>
              {/* /***************** Images *******************/}
              <div className="row mx-3">
                <div className="col mt-2 ">
                  <span className="pb-2 drawer_span">Upload Documents</span>
                  <div
                    className="d-flex align-items-center text-center"
                    style={{ border: "dotted 3px  grey" }}
                  >
                    <Upload
                      disabled={isDisabled}
                      // action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
                      listType="picture-card"
                      fileList={fileList}
                      onPreview={handlePreview}
                      onChange={handleChange}
                      className="mt-2"
                    >
                      {fileList.length >= 10 ? null : uploadButton}
                    </Upload>
                  </div>
                  <Modal
                    open={previewOpen}
                    title={previewTitle}
                    footer={null}
                    onCancel={handleCancel}
                  >
                    <img
                      alt="example"
                      style={{
                        width: "100%",
                      }}
                      src={previewImage}
                    />
                  </Modal>
                </div>
              </div>
            </div>
          </form>
        </div>

        <Drawer
          title="Status"
          width={"35%"}
          closable={false}
          onClose={() => setStatusDrawer(false)}
          open={statusDrawer}
        >
          <div className="drawer_main_div text-center">
            <h2>Status Log</h2>
            <div className="px-4">
              {/* ********************************** */}

              <Steps
                progressDot
                current={editRecord?.requestLog?.length || 0}
                direction="vertical"
                items={
                  editRecord?.requestLog.map((log) => {
                    return {
                      title: log?.updatedStatus,
                      description: (
                        <>
                          <p>{log?.message}</p>
                          <p>{new Date(log?.createdAt).toDateString()}</p>
                        </>
                      ),
                    };
                  })
                }
              />

              {/* ********************************** */}
            </div>
          </div>
        </Drawer>
      </Drawer>
    </>
  );
}

export default RequestsDrawer;
