import { Drawer, Space, Spin, Upload, notification,Image } from "antd";
import ErrorMessage from "components/error-message/ErrorMessage";
import { LanguageSegment } from "components/language-segment/LanguageSegment";
import { useFormik } from "formik";
import { useEffect,useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "redux/specialities/Actions";
import * as Yup from "yup";
import { FALLBACK_IMAGE_URL } from "utils/constants/contants";
import { getBase64 } from "utils/helpers/helper-functions";
import * as uploadActions from "redux/upload/Actions";

function SpecialityDrawer(props) {
  const { Segment, language } = LanguageSegment();

  const {
    setDrawer,
    drawer,
    getPagination,
    editRecord,
    setEditRecord,
    view,
    setView,
  } = props;

  const { loading } = useSelector(
    (state) => ({
      loading: state.speciality?.loading,
    }),
    shallowEqual
  );
  const {
    name_en = "",
    name_ar = "",
    name_de = "",
    name_ru = "",
    image =editRecord?.image || null,
    id = null,
    isFeatured = false,
    displayOnMainSlider=false,
  } = editRecord || {};

  const isEditing = Boolean(editRecord);
  const isLoading = isEditing ? loading.update : loading.create;
  const [uploadedImage, setImage] = useState(null);
  const [previewImage, setPreviewImage] = useState("");
  
  useEffect(() => {
    setPreviewImage(isEditing ? editRecord?.image : "");
  },
  // eslint-disable-next-line
  [editRecord]);
  
  const onChange = async (e) => {
    const file = e.file;
    setImage(e.file);
    file.preview = await getBase64(file.originFileObj);
    setPreviewImage(file.url || file.preview);
    formik.setFieldValue("image", file.url || file.preview);
  };
  const removeImage = () => {
    setImage(null);
    setPreviewImage(null);
    formik.setFieldValue("image", "");
  };
  

  const title = `${
    view ? "View" : isEditing ? " Edit " : " Add "
  } specialities`;
  const dispatch = useDispatch();
  const pagination = getPagination();

  const setEditDefaultValues = () => {
    formik.setValues({ ...initialValues });
  };


  const resetForm = () => {
    formik.resetForm();
  };

  const closeDrawer = () => setDrawer(false);

  const onDrawerChange = (e) => {
    if (!e) {
      view && setView(false);
      editRecord && setEditRecord(null);
      resetForm();
      dispatch(
        actions.loading({ update: false, create: false, remove: false })
      );
    }
  };

  useEffect(() => {
    drawer && isEditing && setEditDefaultValues();
  }, 
  // eslint-disable-next-line
  [drawer]);

  //formik Handler
  const initialValues = {
    name_en: name_en || "",
    name_ar: name_ar || "",
    name_ru: name_ru || "",
    name_de: name_de || "",
    isFeatured: isFeatured || false,
    displayOnMainSlider:displayOnMainSlider || false,
    image: image || "",
  };

  const validationSchema = Yup.object().shape({
    name_en: Yup.string().required("Name (English) is required"),
    name_ar: Yup.string().notRequired(),
    name_ru: Yup.string().notRequired(),
    name_de: Yup.string().notRequired(),
    isFeatured: Yup.boolean().notRequired(),
    displayOnMainSlider:Yup.boolean().notRequired(),
    image: Yup.string().notRequired(),

  });

  const onSubmit = async (values) => {
    dispatch(actions.loading(isEditing ? { update: true } : { create: true }));
    if (uploadedImage) {
      const uploadedImages = await dispatch(uploadActions.upload([uploadedImage]));
      if (!uploadedImages?.length) {
        notification.error({ description: "Image Upload Failed" });
        return;
      }
      values.image = uploadedImages[0] || "";
      }
    const action = isEditing
      ? actions.update(id, values, setDrawer, resetForm, pagination)
      : actions.create(values, setDrawer, resetForm, pagination);
    return dispatch(action);
  };

  const formik = useFormik({ initialValues, validationSchema, onSubmit });

  return (
    <>
      <Drawer
        afterOpenChange={onDrawerChange}
        title={title}
        placement={"right"}
        width={"70%"}
        onClose={closeDrawer}
        open={drawer}
        extra={
          <Space>
            {!view && (
              <>
                {!editRecord && (
                  <>
                    <button
                      disabled={isLoading}
                      onClick={resetForm}
                      className="btn-sm btn btn-light px-4 "
                      style={{ border: "1px solid grey" }}
                    >
                      Clear
                    </button>
                  </>
                )}
                <button
                  type="button"
                  disabled={isLoading}
                  onClick={formik.handleSubmit}
                  className={`btn-sm btn ${
                    isLoading ? "btn-light" : "btn-theme"
                  } px-4`}
                >
                  {isLoading ? <Spin /> : "save"}
                </button>
              </>
            )}
          </Space>
        }
      >
        <>
          <div
            className="language-segmented   px-3  mb-2 "
            style={{
              padding: "20px 0",
              margin: 0,
              background: "#fff",
              border: "1px solid #dbdbdb",
              boxShadow: "0px 4px 31px rgba(0, 0, 0, 0.14)",
              borderRadius: "10px",
            }}
          >
            {Segment}
          </div>

          <div className="drawer_main_div">
            <h2 className=" text-center">{title}</h2>
            <div className="container-fluid">
              <div className="row">
                <form onSubmit={formik.handleSubmit}>
                  {/* /********** INPUT ********/}
                  {language === "en" && (
                    <>
                      {" "}
                      <div className="my-2">
                        <span className="drawer_span required">
                          Name{" "}
                          <span className="form-label-secondary">
                            (English)
                          </span>
                        </span>
                        <input
                          disabled={view}
                          type="text"
                          name="name_en"
                          className={`form-control ${
                            formik.errors.name_en && formik.touched.name_en
                              ? "input_error"
                              : ""
                          }`}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.name_en}
                          placeholder="Enter speciality name in (English)"
                        />
                        {formik.errors.name_en && formik.touched.name_en && (
                          <ErrorMessage message={formik.errors.name_en} />
                        )}
                      </div>
                    </>
                  )}
                  {/* /********** INPUT ********/}
                  {language === "ar" && (
                    <>
                      {" "}
                      <div className="my-2">
                        <span className="drawer_span">
                          Name{" "}
                          <span className="form-label-secondary">(Arabic)</span>
                        </span>
                        <input
                          disabled={view}
                          type="text"
                          name="name_ar"
                          className={`form-control ${
                            formik.errors.name_ar && formik.touched.name_ar
                              ? "input_error"
                              : ""
                          }`}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.name_ar}
                          placeholder="Enter speciality name in (Arabic)"
                        />
                        {formik.errors.name_ar && formik.touched.name_ar && (
                          <ErrorMessage message={formik.errors.name_ar} />
                        )}
                      </div>
                    </>
                  )}
                  {/* /********** INPUT ********/}
                  {language === "ru" && (
                    <>
                      {" "}
                      <div className="my-2">
                        <span className="drawer_span">
                          Name{" "}
                          <span className="form-label-secondary">
                            (Russian)
                          </span>
                        </span>
                        <input
                          disabled={view}
                          type="text"
                          name="name_ru"
                          className={`form-control ${
                            formik.errors.name_ru && formik.touched.name_ru
                              ? "input_error"
                              : ""
                          }`}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.name_ru}
                          placeholder="Enter speciality name in (Russian)"
                        />
                        {formik.errors.name_ru && formik.touched.name_ru && (
                          <ErrorMessage message={formik.errors.name_ru} />
                        )}
                      </div>
                    </>
                  )}
                  {language === "de" && (
                    <>
                      {" "}
                      <div className="my-2">
                        <span className="drawer_span">
                          Name{" "}
                          <span className="form-label-secondary">(German)</span>
                        </span>
                        <input
                          disabled={view}
                          type="text"
                          name="name_de"
                          className={`form-control ${
                            formik.errors.name_de && formik.touched.name_de
                              ? "input_error"
                              : ""
                          }`}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.name_de}
                          placeholder="Enter speciality name in (German)"
                        />
                        {formik.errors.name_de && formik.touched.name_de && (
                          <ErrorMessage message={formik.errors.name_de} />
                        )}
                      </div>
                    </>
                  )}
                  {language === "uk" && (
                    <>
                      {" "}
                      <div className="my-2">
                        <span className="drawer_span">
                          Name{" "}
                          <span className="form-label-secondary">(Ukrainian)</span>
                        </span>
                        <input
                          disabled={view}
                          type="text"
                          name="name_uk"
                          className={`form-control ${
                            formik.errors.name_uk && formik.touched.name_uk
                              ? "input_error"
                              : ""
                          }`}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.name_uk}
                          placeholder="Enter speciality name in (German)"
                        />
                        {formik.errors.name_uk && formik.touched.name_uk && (
                          <ErrorMessage message={formik.errors.name_uk} />
                        )}
                      </div>
                    </>
                  )}
                  {/* /********** INPUT ********/}
                  <div className="d-flex">

                  <div className="col-md-6 col-sm-12 form-check form-switch my-2">
                    <input
                      disabled={view}
                      name="isFeatured"
                      type="checkbox"
                      role="switch"
                      checked={formik.values.isFeatured}
                      className={` drawer_input form-check-input drawer_input ${
                        formik.errors.isFeatured && formik.touched.isFeatured
                          ? "input_error"
                          : ""
                      }`}
                      onChange={(e) => {
                        formik.setFieldValue("isFeatured", e.target.checked);
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.isFeatured}
                    />
                    <span className="drawer_span_no-margin">
                    Display on Navbar?
                    </span>
                  </div>
                  {formik.errors.isFeatured && formik.touched.isFeatured && (
                    <ErrorMessage message={formik.errors.isFeatured} />
                  )}

                   {/* /********** INPUT ********/}
                   <div className="col-md-6 col-sm-12 form-check form-switch my-2">
                    <input
                      disabled={view}
                      name="displayOnMainSlider"
                      type="checkbox"
                      role="switch"
                      checked={formik.values.displayOnMainSlider}
                      className={` drawer_input form-check-input drawer_input ${
                        formik.errors.displayOnMainSlider && formik.touched.displayOnMainSlider
                          ? "input_error"
                          : ""
                      }`}
                      onChange={(e) => {
                        formik.setFieldValue("displayOnMainSlider", e.target.checked);
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.displayOnMainSlider}
                    />
                    <span className="drawer_span_no-margin">
                    Display On Main Slider?
                    </span>
                  </div>
                  {formik.errors.displayOnMainSlider && formik.touched.displayOnMainSlider && (
                    <ErrorMessage message={formik.errors.displayOnMainSlider} />
                  )}
                  </div>
                  <div className=" m-auto my-4 text-center w-50">
                  <Image
                    className="drawer_input"
                    preview={previewImage ? true : false}
                    width={150}
                    height={150}
                    src={previewImage || FALLBACK_IMAGE_URL}
                    fallback={FALLBACK_IMAGE_URL}
                  />

                  <div className="mb-4 mt-2 text-center ">
                    {!view && (
                      <>
                        {" "}
                        <Upload
                          showUploadList={false}
                          maxCount={1}
                          disabled={isLoading || view}
                          onChange={onChange}
                        >
                          <button
                            type="button"
                            disabled={isLoading || view}
                            className="btn-theme btn py-2 px-4"
                          >
                            Upload
                          </button>
                        </Upload>
                        <button
                          type="button"
                          style={{
                            background: previewImage ? "#f30301" : "grey",
                          }}
                          className="btn py-2 px-4 mx-2 text-white "
                          disabled={isLoading || view || !previewImage}
                          onClick={removeImage}
                        >
                          Delete
                        </button>
                      </>
                    )}
                  </div>
                </div>
                </form>
              </div>

            </div>
          </div>
        </>
      </Drawer>
    </>
  );
}

export default SpecialityDrawer;
