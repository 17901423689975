import { notification } from "antd";
import { authSlice } from "./Auth/authslice";
import { firebaseLogout, getIdToken } from "../firebase/firebase";

const regex = new RegExp("network error", "i");

const errorMessages = {
  409: "Duplicate Record",
  404: "Endpoint not found",
  400: "Validation Failed",
  500: "Internal Server Error",
  403: "Forbidden Request",
  401: "Unauthorized",
  0: "Backend is down...! ",
};

export default function SetupAxios(axios, store) {
  // const auth = store.getState()?.auth;
  // const token = auth?.firebaseToken;
  // // const token = localStorage.getItem("token");
  // console.log("token=>", token);
  const authAction = authSlice.actions;

  const logout = async () => {
    try {
      localStorage.removeItem("token");
      store.dispatch(authAction.logout({}));
      await firebaseLogout();
    } catch (error) {}
  };

  const ResponseHandler = (response) => {
    // console.log("AXIOS | RESPONCE : ", response);
    if (!response) return;
    const status = response?.status;
    const error = errorMessages[status];
    // console.log("status :", status);
    if (error) {
      notification.error({ description: `Error ${status}: ${error}` });
      return response;
    }
    if (response.data) {
      return response;
    }
  };

  const ErrorHandler = async (e) => {
    if (!e) return;
    // console.log("AXIOS | ERROR : ", e);

    const response = e?.response;
    let status = response?.status;

    if (response?.status === 401) {
      // console.log("logout is calling...");
      notification.error({ description: `Session Expired. login again` });
      return logout();
    }

    // console.log("status :", status);
    if (regex.test(e)) {
      status = 0;
    }
    const error =
      errorMessages[status] ||
      e?.response?.data?.message ||
      e?.response?.message ||
      e?.message;
    notification.error({ description: `${error}` });
  };

  /******************* REQUEST ****************/
  axios.interceptors.request.use(
    async (config) => {
      const firebaseToken = await getIdToken();
      // console.log("token | ==>", firebaseToken);
      if (!firebaseToken) {
        logout();
      }
      // console.log("token", getIdToken);
      // const firebaseToken = store.getState()?.auth?.firebaseToken;

      // config.headers.Authorization = `Bearer ${firebaseToken}`;
      config.headers.authorization = `Bearer ${firebaseToken}`;
      return config;
    },
    (err) => {
      return err;
    }
  );

  /******************* RESPONSE ****************/
  axios.interceptors.response.use(ResponseHandler, ErrorHandler);
}
