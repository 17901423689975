import React, { useState } from "react";
import { createQueryParams } from "utils/helpers/helper-functions";
import TreatmentsDrawer from "./treatment.drawer";
import TreatmentsTable from "./treatment.table";
import { treatmentRelations as relations } from "services/relations";

function Treatment() {
  const [drawer, setDrawer] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [view, setView] = useState(false);
  const [edit, setEdit] = useState(false);
  const [remove, setRemove] = useState(false);

  const getPagination = () => {
    const limit = pageSize;
    let offset = (pageNumber - 1) * pageSize;
    offset = offset > 1 ? offset : 0;
    const text = searchText.trim() || null;
    const query = createQueryParams(limit, offset, text, relations);
    return { limit, offset, text, relations, query };
  };
  const commonProps = {
    edit,
    setEdit,
    remove,
    setRemove,
    view,
    setView,
    pageSize,
    setPageSize,
    pageNumber,
    setPageNumber,
    searchText,
    setSearchText,
    getPagination,
    drawer,
    setDrawer,
  };

  return (
    <div>
      <div className="py-4 mt-3 table_main_div d-flex justify-content-between">
        <h1 className="mx-5 mt-2">Treatments</h1>
        <button className="btn mx-5 btn-theme" onClick={() => setDrawer(true)}>
          Add New Treatment
        </button>
      </div>
      <TreatmentsTable {...commonProps} />
      <TreatmentsDrawer {...commonProps} />
    </div>
  );
}

export default Treatment;
