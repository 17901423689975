
import NoDataIcon from "components/animation-icons/no-data-icon";
// import { DepartmentsSelect } from "./common/departments.select";
import { shallowEqual, useSelector } from "react-redux";
import TranslationNotAvailable from "components/translation-not-available/TranslationNotAvailable";
import { LanguageSegment } from "components/language-segment/LanguageSegment";
import FacilityDepartmentDrawer from "app/dashboard/common/facility-department/facility-department.drawer";
import { useState } from "react";
import { createQueryParams } from "utils/helpers/helper-functions";

function FacilityDepartments({ formik, view }) {
  const { Segment, language } = LanguageSegment();  
  const [facilityDepartmentDrawer, setFacilityDepartmentDrawer] = useState(false);
  const [editRecord, setEditRecord] = useState(null);

  const handleEdit = (newRecord, id) => {
    newRecord.id = id;
    setEditRecord(newRecord);
    setFacilityDepartmentDrawer(false); // Close the drawer after editing
    formik.setFieldValue("departmentIds", formik.values.departmentIds.map(dep => {
      if (dep.value === newRecord.id) {
        return { ...dep, label: newRecord.name_en };
      }
      return dep;
    }));
  };

  const handleCreate = (newRecord) => {
    const exists = formik.values.departmentIds.some(dep => dep.value === newRecord.id);
    if (!exists) {
      formik.setFieldValue("departmentIds", [
        ...formik.values.departmentIds,
        { value: newRecord.id, label: newRecord.name_en }
      ]);
    }
  };

  const { entity } = useSelector(
    (state) => ({ entity: state.facilityDepartment?.records }),
    shallowEqual
  );

  const departmentValues = formik.values.departmentIds.map(
    (department) => department.value
  );
  // Filter entities that match the department values
  const matchedEntities = entity.filter((e) => departmentValues.includes(e.id));

  const getfacilityDepartmentPagination = () => {
    const limit = 100000;
    let offset = 0;
    const text = "";
    // const relations = ["speciality"];
    const relations = [];
    const query = createQueryParams(limit, offset, text, relations);
    return { limit, offset, text, relations, query };
  };

  return (
    <>
      <div className="language-segmented drawer_area_main_div mx-3 px-4">
        {Segment}
      </div>
      <div className="language-segmented drawer_area_main_div w-auto mx-3 px-4 mt-4">
        <div className=" float-end " style={{ marginLeft: "0.6rem" }}>
          <span
            onClick={() => setFacilityDepartmentDrawer(!facilityDepartmentDrawer)}
            className="btn btn-primary btn-theme"
            data-bs-toggle="modal"
            data-bs-target="#newProjectModal"
          >
            <i className="bi-plus me-1"></i> Add Department
          </span>
        </div>
      </div>

      <FacilityDepartmentDrawer
        setDrawer={setFacilityDepartmentDrawer}
        drawer={facilityDepartmentDrawer}
        getPagination={getfacilityDepartmentPagination}
        editRecord={editRecord}
        setEditRecord={setEditRecord}
        onEdit={handleEdit}
        onCreate={handleCreate} // Pass the callback function
      />

      {matchedEntities?.length ? (
        <>
          <div className="content container-fluid my-3 px-3">
            <div className="row justify-content-lg-center">
              <div className="col-lg-12 ">
                <div className="tab-content" id="profileTeamsTabContent">
                  <div
                    className="tab-pane fade active show"
                    id="list"
                    role="tabpanel"
                    aria-labelledby="list-tab"
                  >
                    <div className="row row-cols-1 ">
                      {matchedEntities.map(({ index }, entityIndex) => (
                        <div className="col mb-3 " key={entityIndex}>
                          <div className="card card-body">
                            <div className="row align-items-md-center">
                              <div className="col-3 col-md-auto order-md-last text-end">
                                <div className="dropdown">
                                  <button
                                    disabled={view}
                                    type="button"
                                    className="btn btn-ghost-secondary btn-icon btn-sm rounded-circle"
                                    id="teamsListDropdown1"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    <i className="bi-three-dots-vertical"></i>
                                  </button>

                                  <div
                                    className="dropdown-menu dropdown-menu-sm dropdown-menu-end"
                                    aria-labelledby="teamsListDropdown1"
                                  >
                                    <div
                                      className="dropdown-item text-danger"
                                      onClick={() =>
                                        formik.setFieldValue(
                                          "departmentIds",
                                          formik.values.departmentIds.filter(
                                            (item, itemIndex) =>
                                              entityIndex !== itemIndex
                                          )
                                        )
                                      }
                                    >
                                      Delete
                                    </div>
                                    <div
                                      className="dropdown-item text-danger"
                                      onClick={() => {
                                        setEditRecord(matchedEntities[entityIndex]);
                                        setFacilityDepartmentDrawer(!facilityDepartmentDrawer);
                                      }}
                                    >
                                      Edit
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-sm mb-2 mb-sm-0 ">
                                <p>
                                  {matchedEntities[entityIndex][`name_${language}`] || <TranslationNotAvailable />}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="px-4 drawer_area_main_div mx-3 my-2">
            <NoDataIcon />
          </div>
        </>
      )}
    </>
  );
}

export default FacilityDepartments;
