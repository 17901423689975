import React from "react";
import NO_DATA_IMAGE from "assets/images/no-data.svg";

const NoData = () => {
  return (
    <div class="card-body  text-center">
      <img
        class="avatar avatar-xxl mb-3"
        src={NO_DATA_IMAGE}
        alt="Description"
        data-hs-theme-appearance="default"
      />
      <p class="card-text">No data to show</p>
    </div>
  );
};

export default NoData;
