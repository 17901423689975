import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { message } from "antd";
import { useDispatch } from "react-redux";
import * as uploadActions from "redux/upload/Actions";

export default function RichEditor(props) {
  const { value, setValue, disabled } = props;
  const dispatch = useDispatch();

  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          loader.file.then((file) => {
            dispatch(uploadActions.upload([file]))
              .then((res) => {
                if (res[0]) {
                  message.success("Image uploaded successfully");
                }
                resolve({
                  default: res[0] || null,
                });
              })
              .catch((err) => {
                resolve({
                  default: null,
                });
              });
          });
        });
      },
    };
  }
  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }
  return (
    <div className="App">
      <CKEditor
        disabled={disabled}
        config={{
          fontSize: {
            options: [9, 11, 13, "default", 17, 19, 21],
          },

          extraPlugins: [uploadPlugin],
        }}
        data={value}
        editor={ClassicEditor}
        onChange={(event, editor) => {
          // console.log({ event, editor, data });
          const data = editor.getData();
          setValue(data);
        }}
      />
    </div>
  );
}
